import React, { useEffect, useMemo } from "react";
import { Input } from "antd";
import { observer } from "mobx-react-lite";
import Flatpickr from "react-flatpickr";
import * as dayjs from "dayjs";
import styled from "styled-components";
import "flatpickr/dist/themes/airbnb.css";
import { useDebouncedCallback } from "use-debounce";

import { ANALYTICS_SINGLE_TRACK_LABEL, appStore } from "../../../../stores/appStore";
import { generateTestId } from "../../../../util_code/testUtil";
import { BooleanInput } from "../../../../components/v2/Inputs/BooleanInput";
import { Label } from "../../../../components/v2/Text/Label";
import { TextField } from "../../../../components/v2/Inputs/TextField";
import { BaseText } from "../../../../components/v2/Text/BaseText";
import { useIsMobile } from "../../../../hooks/isMobile";

export const SingleWizardStep3 = observer(() => {
  const { currentPoster, logAnalyticsEvent } = appStore;

  const isMobile = useIsMobile();

  const datePlaceHolder = useMemo(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const day = currentDate.getDate();
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} 12:00`;
  }, []);

  const {
    setCustomDatetime,
    customDescription,
    setCustomDescription,
    enableCustomDescription,
    hideDate,
    hideTime,
    hideDescription,
  } = currentPoster;

  const debounceDescription = useDebouncedCallback((value) => {
    setCustomDescription(value);
  }, 300);

  useEffect(() => {
    enableCustomDescription(); // Switch to create your own description if unable to fetch album description from Wiki

    logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
      category: "interaction",
      action: "unable_to_auto_generate_description",
    });
  }, []);

  return (
    <div>
      <div>
        <TextField
          showCount
          fullWidth
          maxLength={33}
          minRows={2}
          multiline
          defaultValue={customDescription}
          onChange={(event) => debounceDescription(event.target.value)}
          placeholder="Add your own description here"
          style={{ resize: "none", fontSize: isMobile ? "16px" : "2rem" }}
          data-explorator_test_id={generateTestId(
            "single-track",
            "step-3-description-input"
          )}
        />
        <DescriptionCharacterCount>{`${customDescription.length}/1000`}</DescriptionCharacterCount>
      </div>
      <Label>Show Description</Label>
      <BooleanInput
        optionB={{ value: false, label: "Show", testId: generateTestId("single-track-wizard", "step-3-show-description") }}
        optionA={{ value: true, label: "Hide", testId: generateTestId("single-track-wizard", "step-3-hide-description") }}
        value={hideDescription}
        onChange={(value) => {
          currentPoster.setterHideDescription = value;
        }}
      />

      <StyledLabel>Next, Select Date & Time Input (lower left)</StyledLabel>
      <DatePickerWrapper>
        <StyledFlatpickr
          placeholder={datePlaceHolder}
          value={datePlaceHolder}
          data-enable-time
          style={{
            padding: 16,
          }}
          onChange={([date]) => {
            setCustomDatetime(dayjs(date).format("MMMM D, YYYY // h:mm a"));
          }}
          data-explorator_test_id={generateTestId(
            "single-track",
            "step-3-date-input"
          )}
        />
      </DatePickerWrapper>
      <StyledLabel>Show Date</StyledLabel>
      <BooleanInput
        optionB={{ value: false, label: "Show", testId: generateTestId("single-track-wizard", "step-3-show-date") }}
        optionA={{ value: true, label: "Hide", testId: generateTestId("single-track-wizard", "step-3-hide-date") }}
        value={hideDate}
        onChange={(value) => {
          currentPoster.setterHideDate = value;
        }}
      />

      <StyledLabel>Show Time</StyledLabel>
      <BooleanInput
        optionB={{ value: false, label: "Show", testId: generateTestId("single-track-wizard", "step-3-show-time") }}
        optionA={{ value: true, label: "Hide", testId: generateTestId("single-track-wizard", "step-3-hide-time") }}
        value={hideTime}
        onChange={(value) => {
          currentPoster.setterHideTime = value;
        }}
      />
      <br />
    </div>
  );
});

const StyledLabel = styled(Label)`
  && {
    margin-top: 1rem;
  }
`;

const StyledFlatpickr = styled(Flatpickr)`
  && {
    width: 100%;
    border: none;
    
    background-color: ${({ theme }) => theme.color.gray};
    padding: 0.5rem 1.4rem;
    font-size: ${({ theme }) => theme.fontSize.input};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    margin: 0.5rem 0;
  }
`;

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 100%;
`;

const DescriptionCharacterCount = styled(BaseText)`
  && {
    text-align: right;
  }
`;
