import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { appStore } from "../../../../stores/appStore";
import { HiddenTestingInput } from "../../../../components/WizardComponents/HiddenTestingInput";
import { generateTestId } from "../../../../util_code/testUtil";
import { FrameChoicePricingTypes, PosterSizeOptionsLabelsArray } from "../../../../stores/posterStore";
import { useIsMobile } from "../../../../hooks/isMobile";
import { PosterSizeInputV2 } from "../../../../components/v2/Inputs/PosterSizeInput";
import { PosterCostText } from "../../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { PosterFrameInputV2 } from "../../../../components/v2/Inputs/PosterFrameInput";

export const PosterSizeAndFrameWizardV2 = observer(() => {
  const {
    currentPoster
  } = appStore;

  const {
    posterSize, setPosterSize,
    frameChoice, setFrameChoice
  } = currentPoster;

  const frameChoices = useMemo(() => {
    return FrameChoicePricingTypes.map(frameChoicePricingType => {
      const label = `${frameChoicePricingType.label}`;
      return { label, value: frameChoicePricingType.name, image: frameChoicePricingType.image };
    });
    // { label: "Small", value: "small", image: WhiteFrame }, { label: "Medium", value: "medium", image: BlackFrame }, { label: "Large", value: "large", image: WoodFrame }
  }, [posterSize]);

  return (
    <div>
      <HiddenTestingInput />
      <PosterSizeInputV2 options={PosterSizeOptionsLabelsArray} onChange={(value) => setPosterSize(value)} value={posterSize} />
      <PosterFrameInputV2 value={frameChoice} onChange={(value) => setFrameChoice(value)} options={frameChoices} />
      <PosterCostText>
        Price:
        {" "}
        $
        {currentPoster.price}
        {" "}
        <span>plus tax + shipping</span>
      </PosterCostText>
    </div>
  );
});

export const StepFourTextIntro = styled.p`
  font-size: 2.1rem;
  margin-top: 1.8rem;
  letter-spacing: 0.09rem;
  text-align: center;
  letter-spacing: 0.1rem;

  & > span {
    font-weight: bold;
    font-size: 2rem;

  }
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const PosterSizeTitle = styled.p`
  font-size: 2.1rem;
  text-align: center;
  margin-top: 4rem;
`;

export const ChoiceWrapper = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 6rem;
`;

export const FrameChoiceWrapper = styled.div`
  margin-bottom: 5rem;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
