import styled from "styled-components";
import { BaseText } from "../Text/BaseText";
import { SubTitle } from "../Text/SubTitle";

export const WizardStepperContainer = styled.div`
  padding: 1rem 2rem;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  margin: auto 0;
  margin-left: auto;
  max-width: 45rem;
  width: 30vw; // TODO maybe make this defined for different screen widths
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  @media ${({ theme }) => theme.devicesMax.md} {
    height: fit-content;
    width: 95%;
    margin: auto;
    max-width: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 1.2rem;
  }
`;

export const StepperContainer = styled.div`
    flex-grow: 3;
`;

export const ColorPickerContainer = styled.div`
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 8.5px;
  border: solid thin ${({ theme }) => theme.color.gray};
  ${({ $active, theme }) => $active ? `border-color: ${theme.color.black}` : "filter: opacity(50%)"};
  cursor: pointer;

`;

export const ColorPickerContent = styled.div`
  height: 90%;
  width: 90%;
  margin: auto;
  margin-left: 5%;
  margin-top: 5%;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  display: flex; 
  justify-content: center;
`;

export const ColorPickerText = styled(BaseText)`
  && {
    font-size: 1rem;
    margin: auto;
    color: ${({ theme, $color }) => $color || theme.color.white};
  }
`;

export const PosterCostText = styled(SubTitle)`
  && {
    font-weight: bolder;
    text-align: center;
    font-size: 2rem;
    margin: 4rem 0;
    && span {
      font-weight: normal;
      font-size: 1.75rem;
    }
  }
`;
