import React from "react";
import { FormControl, RadioGroup as MUIRadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import styled from "styled-components";
import { InputText } from "../Text/InputText";

const Container = styled.div`

`;

const StyledInputText = styled(InputText)`
    && {
        font-weight: bold;
    }
`;

const RadioOptionsContainer = styled.div`

`;

const RadioOptionContainer = styled.div`
    display: flex;
    height: 5rem;
    justify-content: center;
    width: fit-content;
    margin-bottom: 1rem;
`;

const RadioOptionImageContainer = styled.div`
  border: solid thin white;
  border-color: ${({ $active, theme }) => $active ? theme.color.black : theme.color.gray};
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  border-radius: 8.5px;
  padding: 0.25rem;
`;

const RadioOptionImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
`;

const RadioOptionLabel = styled(InputText)`
  && {
    margin: auto;
    height: fit-content;
    display: block;
  }
`;

/*
   options[] TODO look into if it's bad I use the order it's given and not a index system
        label: text
        value: any
        key: text
        image: string
    title
*/
export const RadioGroupImages = (props) => {
  const { options, label, className, value, onChange } = props;
  return (
    <Container className={className}>
      {label && <StyledInputText>{label}</StyledInputText>}
      <RadioOptionsContainer>
        {
          options.map(option => (
            <RadioOptionContainer>
              <RadioOptionImageContainer $active={value === option.value} onClick={() => onChange(option.value)}>
                <RadioOptionImage src={option.image} />
              </RadioOptionImageContainer>
              <RadioOptionLabel>{option.label}</RadioOptionLabel>
            </RadioOptionContainer>
          ))
        }
      </RadioOptionsContainer>
    </Container>
  );
};
