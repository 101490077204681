import styled from "styled-components";

export const StyledSpotifySearchInput = styled.input`
  padding: 1rem;
  border: none;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.color.gray};
  text-align: left;
  width: 100%;
  font-size: 1.7rem;
  font-weight: 600;
  color: #aaa;

  &::placeholder {
    color: #aaa;
    letter-spacing: 0.05rem;
  }

  &:focus {
    outline: none;
  }
`;

export const StyledSpotifySearchResults = styled.div`
    max-height: 30vh;
    overflow-y: auto;
    width: 100%;
    margin: 0 auto;
    margin-top: 0.5rem;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    background-color: ${({ theme }) => theme.color.gray};

    @media ${({ theme }) => theme.devicesMax.sm} {
    margin: 0 auto;
    width: 90%;
    };

    &.active{
    background-color: #FFFFFF;
    }
`;
