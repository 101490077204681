import React, { useLayoutEffect, useState } from "react";

export const useIsMobile = () => {
  const [isMobileValue, setIsMobileValue] = useState(true);
  useLayoutEffect(() => {
    function updateIsMobile() {
      setIsMobileValue(window.innerWidth <= 768);
    }
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return isMobileValue;
};
