import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import hexToRgba from "hex-to-rgba";
import SuperQuery from "@themgoncalves/super-query";

import { appStore } from "../../stores/appStore";

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: ${({ $pageColor }) => $pageColor};
`;

const Content = styled.div`
    margin: auto;
`;

const MessageAndImageBorderRadius = "1rem";

const MessageTextContainer = styled.div`
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    width: 55%;
    border-radius: ${MessageAndImageBorderRadius};
    margin-right: 3%;
    display: flex;
    justify-content: center;
`;

const AlbumImage = styled.img`
    width: 40%;
    height: height;
    border-radius: ${MessageAndImageBorderRadius};
`;

const PageTitle = styled.h1`
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-bottom: 2rem;
`;

const Message = styled.p`
  font-size: 2rem;
  text-align: center;
  margin-top: 0.5rem;
  width: 90%;
  margin: auto;
`;

export const QRCodeMessagePage = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();
  const [posterColor, setPosterColor] = useState();
  const [posterColorRaw, setPosterColorRaw] = useState();
  const [pageColor, setPageColor] = useState();
  const [albumImage, setAlbumImage] = useState();

  const { throwErrorMessage, backendFunctionUrl } = appStore;

  useEffect(() => {
    if (backendFunctionUrl.length <= 0) return;
    const currentURL = window.location.href;
    const urlObj = new URL(currentURL);
    const params = new URLSearchParams(urlObj.search);
    const posterImageId = params.get("poster_image_id");
    if (posterImageId) {
      getMessage(posterImageId);
    } else {
      setLoading(false);
    }
  }, [backendFunctionUrl]);

  const getMessage = async (posterImageId) => {
    try {
      let { data } = await axios.post(`${backendFunctionUrl}/get_order_message_data`, {
        poster_image_id: posterImageId,
      });
      if (data.error) throw data.error;
      setMessage(data.message);
      setPosterColor(hexToRgba(data.posterColor, "0.4"));
      setPageColor(hexToRgba(data.posterColor, "0.1"));
      setPosterColorRaw(data.posterColor);
      if (data.image) setAlbumImage(data.image);
      setLoading(false);
    } catch (err) {
      if (typeof err === "string") throwErrorMessage(err);
      else throwErrorMessage("Failed to get message, please ensure you did not alter URL.");
    }
  };

  return (
    <PageContainer $pageColor={pageColor}>
      <Content>
        <PageTitle>Here is Your Special Message!</PageTitle>
        <MessageContainer>
          <MessageTextContainer $borderColor={posterColorRaw} $backgroundColor={posterColor}>
            <Message $fontColor={posterColorRaw}>{message}</Message>
          </MessageTextContainer>
          {albumImage && <AlbumImage src={albumImage} />}
        </MessageContainer>
      </Content>
    </PageContainer>
  );
};

const MessageContainer = styled.div`
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    width: 80rem;
    ${SuperQuery().maxWidth.sm.css`
        width: 80%;
        display: block;
    `};
`;
