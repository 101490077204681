import React from "react";
import styled from "styled-components";
import { BaseText } from "./BaseText";

const StyledParagraph = styled(BaseText)`
`;

export const Paragraph = (props) => {
  const { children } = props;
  return (
    <StyledParagraph level="p" {...props}>
      {children}
    </StyledParagraph>
  );
};
