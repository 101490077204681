import React from "react";
import styled from "styled-components";
import Input from "@mui/material/Input";

const StyledTextField = styled(Input)`
    && {
      background-color: ${({ theme }) => theme.color.gray};
      padding: 0.5rem 1.4rem;
      font-size: ${({ theme }) => theme.fontSize.input};
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      @media ${({ theme }) => theme.devicesMax.md} {
        font-size: 1.5rem;
      }
    }
`;

export const TextField = (props) => {
  const { className, inputRef } = props;
  return (
    <StyledTextField inputRef={inputRef} type="string" fullWidth disableUnderline className={className} {...props} variant="filled" />
  );
};
