/* eslint-disable no-await-in-loop */
import axios from "axios";

export const generateTestId = (sectionOfApp, feature) => {
  return `${sectionOfApp}-${feature}`;
};

const numberOfTimes = 1;

const backendFunctionUrl = "http://localhost:5001/wavroom-staging/us-central1";

// const backendFunctionUrl = "https://us-central1-wavroom-staging.cloudfunctions.net";

export const stressTest = async () => {
  console.log("STARTING:");
  const startTime = new Date().getTime();
  try {
    let count = 0;
    while (count < numberOfTimes) {
      const currentTime = new Date().getTime();
      console.log("TIME DEF:", (currentTime - startTime) / 1000);
      if (currentTime - startTime > 1000 * 30) break;
      console.log("RUNNING: ", count);
      count++;
      const responses = await Promise.all(Array(600).fill(0).map(async (_, index) => {
        console.log("RUNNING_SUB_COUNT", index);
        const res = await axios.post(
          `${backendFunctionUrl}/get_single_track_waveform_v2`,
          {
            track_id: "19YKaevk2bce4odJkP5L22",
          }
        );
        if (res.status !== 200) throw "";
        return { error: false };
      }));
      const currentTime2 = new Date().getTime();
      console.log("TIME DEF2:", (currentTime2 - startTime) / 1000);
      if (responses.find(response => response.error)) {
        break;
      }
    }
  } catch (err) {
    console.log("ERROR:");
    console.log(err);
  }
};
