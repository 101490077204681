import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";
import { PreviewButtonSize } from "../../components/PreviewPoster/PreviewPoster";

export const EditorWrapper = styled.div`
  font-size: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 10rem);

  ${SuperQuery().maxWidth.md.css`
    grid-template-columns: 1fr;
    height: auto;
  `}
`;

export const AlbumPosterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;

  ${SuperQuery().maxWidth.md.css`
    padding: 3em 0;
    background: linear-gradient(180deg, #777 50%, #E5E4E2 50%);
  `}

  ${SuperQuery().maxWidth.sm.css`
    width: 100vw;
    padding: 0;
    box-sizing: border-box;
    height: 65vh;
    padding-top: ${PreviewButtonSize};
  `}
`;

export const Poster = styled.div`
  background: ${({ theme }) => theme.body};
  transition: background 0.3s ease-in-out;
  border: ${({ frameColor }) => `14px solid ${frameColor}`};
  aspect-ratio: ${({ frameSize }) => {
    if (frameSize === "16 X 20") return "16/20";
    if (frameSize === "18 X 24") return "18/24";
    if (frameSize === "24 X 36") return "24/36";
    return "16/20";
  }};
  margin: auto;

  width: ${({ theme }) => theme.POSTER_WIDTH_RAW_V2}px;
  font-size: 3rem;
  // TODO add shadow below poster instead with div or something
  // box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);
  // -webkit-box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);
  // -moz-box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);

  ${SuperQuery().maxWidth.md.css`
    top: 100%;
  `}

  ${SuperQuery().maxWidth.sm.css`
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  `}
`;

export const RenderingShadow = styled.div`
  z-index: 5;
  height: ${(props) => props.posterHeight}px;
  width: ${(props) => props.posterWidth}px;
  background-color: rgba(0, 0, 0, 0.45);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${SuperQuery().maxWidth.sm.css`
    transform: translate(-50%, -50%) scale(0.8);
  `}
`;

export const StepsSection = styled.div`
  background: #e5e4e2;
  overflow-y: auto;
  height: fit-content;
  max-height: 100%;

  ${SuperQuery().maxWidth.md.css`
    overflow-y: unset;
    max-height: auto;
  `}

  ${SuperQuery().maxWidth.sm.css`
    overflow-y: unset;
    padding: 0 0.5rem;
    max-height: auto;
`}
`;
