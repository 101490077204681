import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

import { Button } from "../OtherComponents/Button";
import backArrowSVG from "../../../images/arrow-ios-back.svg";
import { appStore } from "../../../stores/appStore";
import { useIsMobile } from "../../../hooks/isMobile";
import { cartStore } from "../../../stores/cartStore";
import { checkIfCanGoToNextStep, wizardStore } from "../../../stores/wizardStore";
import { generateTestId } from "../../../util_code/testUtil";
import { InputText } from "../Text/InputText";

// TODO remove logic for price display as we don't use it anymore. Or maybe we should keep it incase we wanna restore that functionality
export const StepNavigationBarV2 = observer(() => {
  const [heightOfStepNavigationBarStyled, setHeightOfStepNavigationBarStyled] = useState(0);
  const [visitedForthStep, setVisitedForthStep] = useState(false);

  const isMobile = useIsMobile();

  const { currentPoster, throwErrorMessage } = appStore;

  const {
    posterSize,
    frameChoice,
    price
  } = currentPoster;

  const {
    addPosterToCart
  } = cartStore;

  const {
    step,
    nextStepOnClick,
    previousStepOnClick,
    maxStepReached
  } = wizardStore;

  const history = useHistory();

  const addToCart = () => {
    const canGoToNextStepError = checkIfCanGoToNextStep(step);
    if (canGoToNextStepError) {
      throwErrorMessage(canGoToNextStepError);
      return;
    }
    addPosterToCart(currentPoster);
    history.push("/cart"); // TODO maybe have JSON of links to make it easier to change when a link changes
  };

  const stepNavRef = useRef();

  useEffect(() => {
    if (!stepNavRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (stepNavRef.current && stepNavRef.current.offsetHeight !== heightOfStepNavigationBarStyled) {
        setHeightOfStepNavigationBarStyled(stepNavRef.current.offsetHeight);
      }
    });

    resizeObserver.observe(stepNavRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const priceToUse = useMemo(() => {
    if (maxStepReached !== 4) {
      return undefined;
    }
    return price;
  }, [price, maxStepReached]);

  const { showNextStep, showAddToCart, showPreviousStep } = useMemo(() => {
    // We want to show price once they have selected an option. TODO dealt with using priceToUse find best solution
    if (step === 4 && !visitedForthStep) setVisitedForthStep(true);

    switch (step) {
    case 1:
      return {
        showNextStep: true,
        showPreviousStep: false,
        showAddToCart: false
      };
    case 2:
      return {
        showNextStep: true,
        showPreviousStep: true,
        showAddToCart: false
      };
    case 3:
      return {
        showNextStep: true,
        showPreviousStep: true,
        showAddToCart: false
      };
    case 4:
      return {
        showNextStep: false,
        showPreviousStep: true,
        showAddToCart: true
      };
    default:
      return {
        showNextStep: true,
        showPreviousStep: true,
        showAddToCart: false
      };
    }
  }, [step, frameChoice, posterSize]);

  if (isMobile && showAddToCart) {
    return (
      <>
        <Spacer $height={heightOfStepNavigationBarStyled} />
        <NextStepAndAddToCartButton $isMobile $showingPreviousStep={showPreviousStep} onClick={addToCart} data-explorator_test_id={generateTestId("step-nav-bar", "next-step-or-add-to-cart")}>
          Add to cart
        </NextStepAndAddToCartButton>
      </>
    );
  }
  if (isMobile) return <></>;
  return (
    <>
      <Spacer $height={heightOfStepNavigationBarStyled} />
      <StepNavigationBarStyled ref={stepNavRef} $centerButton={!showPreviousStep && !priceToUse}>
        {showPreviousStep && (
          <PreviousStepButton variant="outlined" $priceIsDisplayed={(priceToUse !== undefined && isMobile)} data-explorator_test_id={generateTestId("step-nav-bar", "back-button")} onClick={() => previousStepOnClick()}>
            <PreviousButtonText>Back</PreviousButtonText>
          </PreviousStepButton>
        )}
        {(showNextStep || showAddToCart) && (
          <NextStepAndAddToCartButton $showingPreviousStep={showPreviousStep} onClick={showAddToCart ? addToCart : () => nextStepOnClick()} data-explorator_test_id={generateTestId("step-nav-bar", "next-step-or-add-to-cart")}>
            {showAddToCart ? "Add to cart" : "Next"}
          </NextStepAndAddToCartButton>
        )}
      </StepNavigationBarStyled>
    </>
  );
});

const Spacer = styled.div`
  height: ${({ $height }) => $height}px;
  margin-top: 1rem;
`;

const StepNavigationBarStyled = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const marginBetweenButtons = "0rem";

const SharedButtonStyle = css`
  width: 47%;
  padding: 1rem 0;
`;

const PreviousStepButton = styled(Button)`
  && {
    ${SharedButtonStyle};
    margin-left: 0;
    margin-right: ${marginBetweenButtons};
  }
`;

const PreviousButtonText = styled(InputText)`
  && {
    color: black;
  }
`;

const NextStepAndAddToCartButton = styled(Button)`
    && {
        ${SharedButtonStyle};
        border: solid transparent 1px; // because size difference with pervious button
        margin-right: 0;
        margin-left: ${({ $showingPreviousStep }) => $showingPreviousStep ? marginBetweenButtons : "auto"};
        ${({ $isMobile }) => $isMobile && `
          margin: auto;
          width: 100%;
          margin-top: 2rem;
        `};
    }
`;
