import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { appStore } from "../../../stores/appStore";
import { Title } from "../Text/Title";

export const SideBar = observer(() => {
  const {
    isSidebarOpen,
  } = appStore;

  if (!isSidebarOpen) return (<></>);

  return (
    <SidebarContentContainer>
      <StyledLink>
        <LinkText>Store</LinkText>
      </StyledLink>
      <StyledLink>
        <LinkText>Cart</LinkText>
      </StyledLink>
    </SidebarContentContainer>
  );
});

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const LinkText = styled(Title)`
    && {
        color: ${({ theme }) => theme.color.white};
        text-align: center;
        font-size: 3rem;
        margin: 3rem 0;
    }
`;

export const SidebarContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.black};
  position: absolute;
  left: 0;
  z-index: 10000;
`;

export const CloseButton = styled.img`
  height: 4rem;
  margin-right: 3rem;
  margin-top: 3rem;
`;

export const SidebarContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReturnToStoreButton = styled.a`
  margin-top: 3rem;
  font-size: 1.9rem;
  font-family: AvenirNextLTPro-Bold;
  color: black;
  text-decoration: none;
`;
