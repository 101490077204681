import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { SideBar } from "../Navgation/SideBar";
import Header from "../Navgation/Header";

export const HeaderHeight = "10rem";

export const PageWrapper = observer(({ children }) => {
  return (
    <div>
      <Header />
      <SideBar />
      <LayoutWrapper>{children}</LayoutWrapper>
    </div>
  );
});

export const LayoutWrapper = styled.div``;
