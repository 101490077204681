import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";

import {
  PosterContent, PosterDescriptionContainer,
  PosterTracksContainer, PosterDescriptionHeader,
  PosterDescriptionText, PosterTracksWrapper,
  PosterPreviewContainer
} from "./AlbumPosterEditorStyles";
import CanvasWaveForm from "../CanvasWaveForm";
import { Poster } from "../../pages/Editor/EditorStyles";
import BlackStamp from "../../images/WR-logomark-black.png";
import WhiteStamp from "../../images/WR-logomark-white.png";
import LogoLoadingSpinner from "../LogoLoadingSpinner/LogoLoadingSpinner";
import { appStore } from "../../stores/appStore";
import { AutoRescaleContainer } from "../AutoRescaleContainer";
import { getPosterImageId } from "../../hooks/getPosterImageId";
import { generateTestId } from "../../util_code/testUtil";

const QRCodeSize = 30;

const QRCodeContainer1 = styled.div`
  position: absolute;
  bottom: 1rem;
  right: calc(50% - ${QRCodeSize / 2}px);
  margin: auto;
`; // If we go with this option, super long descriptions will overlap

const QRCodeContainer2 = styled.div`
  position: absolute;
  bottom: 0rem;
  right: calc(2rem + 24px + 1rem); // last addition (1rem) is space between logo and QRCode
  margin: auto;
`;

const QRCodeContainer3 = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem; // last addition (1rem) is space between logo and QRCode
  margin: auto;
`;

const QRCodeContainerTestSmall = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 5rem; // last addition (1rem) is space between logo and QRCode
  margin: auto;
`;

const AlbumPosterEditor = observer(({
  addWatermark,
  posterOnUpdate,
  currentPoster,
  albumContainerRef,
  useLocalState
}) => {
  const posterRef = useRef(null);

  const {
    headlessMode,
    frontEndUrl,
  } = appStore;

  const {
    albumTitle,
    description,
    posterColor = "#eae8e6",
    loading,
    alignment,
    customDescription,
    frameChoice,
    posterSize,
    theme,
    trackTheme,
    trackLevels = [],
    isAutoGeneratedDescriptionEnabled,
    albumTitleFontSize,
    songMessage
  } = currentPoster || {};
  const albumTitleRef = useRef(null);
  const [albumTitleHeight, setAlbumTitleHeight] = useState();
  const posterImageId = getPosterImageId(headlessMode);
  const [ranPosterUpdate, setRanPosterUpdate] = useState(false);

  useEffect(() => {
    if (!albumTitleRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (albumTitleRef.current && albumTitleRef.current.offsetHeight && albumTitleRef.current.offsetHeight !== albumTitleHeight) {
        const lineHeight = parseFloat(getComputedStyle(albumTitleRef.current).getPropertyValue("line-height"));
        const numberOfLines = (albumTitleRef.current.offsetHeight / lineHeight);
        if (albumTitleRef.current.offsetHeight > lineHeight) {
          setAlbumTitleHeight((albumTitleRef.current.offsetHeight / numberOfLines));
        }
      }
    });

    resizeObserver.observe(albumTitleRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const renderFrameColor = () => {
    if (frameChoice === "Black Gallery Frame") {
      return "#000";
    } if (frameChoice === "White Gallery Frame") {
      return "#F8F8F8";
    } if (frameChoice === "Natural Gallery Frame") {
      return "#e6cfaa";
    }
    return undefined;
  };

  // Generate poster image if in headless mode
  useEffect(() => {
    if (ranPosterUpdate) return;
    if (posterOnUpdate && trackLevels.length > 0 && headlessMode) {
      setRanPosterUpdate(true);
      posterOnUpdate(posterRef.current);
    }
  }, [posterOnUpdate, trackLevels, headlessMode]);

  const wrappingTrackTitle = (trackLevel, numberOfTracks) => {
    if (numberOfTracks > 15) {
      // 3 columns layout
      return trackLevel.name.length > 25;
    }
    // 2 columns layout
    return trackLevel.name.length > 35;
  };

  const getPosterTitleFont = (titleLength) => {
    if (titleLength < 23) {
      return {
        fontSize: translateFontSize(23),
        lineHeight: "30px",
        lineHeightNumber: 30
      };
    } if (titleLength < 40) {
      return {
        fontSize: translateFontSize(20),
        lineHeight: "19px",
        lineHeightNumber: 19
      };
    } if (titleLength < 60) {
      return {
        fontSize: translateFontSize(15),
        lineHeight: "19px",
        lineHeightNumber: 19
      };
    }
    return {
      fontSize: translateFontSize(11),
      lineHeight: "19px",
      lineHeightNumber: 19
    };
  };

  const translateFontSize = (fontSize) => {
    if (albumTitleFontSize === "small") return `${Math.round(fontSize * 0.75)}px`;
    if (albumTitleFontSize === "medium") return `${Math.round(fontSize)}px`;
    return `${Math.round(fontSize * 1.25)}px`;
  };

  return (
    <PosterPreviewContainer data-explorator_test_id={generateTestId("multi-song-display", "container")}>
      <AutoRescaleContainer addWatermark={addWatermark} useLocalState={useLocalState} containerRef={albumContainerRef} headlessMode={headlessMode} frameSize={posterSize}>
        <Poster data-explorator_test_id={generateTestId("multi-track-poster", "poster-container")} ref={posterRef} frameColor={!headlessMode && renderFrameColor()} frameSize={posterSize}>
          <PosterContent>
            <PosterTracksWrapper>
              <PosterTracksContainer
                data-explorator_test_id={generateTestId("multi-track-poster", "poster-tracks-container")}
                notActive={loading}
                postercolor={posterColor}
                threebythree={trackLevels.length > 15 ? "threebythree" : ""}
                trackTheme={trackTheme}
              >
                {
                  loading
                && (
                  <LogoLoadingSpinner />
                )
                }

                {!loading && trackLevels.map((trackLevel) => (
                  <div key={trackLevel.trackId} className="wave-wrapper">
                    <CanvasWaveForm key={trackLevel.trackId} waveFormData={trackLevel.levels} highResolution />
                    <div
                      className="track-info-container"
                      style={{ justifyContent: alignment === "start" ? "space-between" : "center" }}
                      data-explorator_test_id={generateTestId("multi-track-poster", "track-text-container")}
                    >
                      <span data-explorator_test_id={generateTestId("multi-track-poster", "track-name")} className={`track-name ${!wrappingTrackTitle(trackLevel, trackLevels.length) ? "short-title" : ""}`}>
                        {trackLevel.name.length >= 100 ? `${trackLevel.name.slice(0, 100)}...` : trackLevel.name}
                      </span>
                      {" "}
                    &nbsp;
                      <span className="duration">
                        {trackLevel.durationMinute}
                        {" "}
                      </span>
                    </div>
                  </div>
                ))}
              </PosterTracksContainer>
            </PosterTracksWrapper>

            <PosterDescriptionContainer alignment={alignment}>
              {
                albumTitle
              && (
                <PosterDescriptionHeader
                  longTitle={albumTitle.length >= 21}
                  style={getPosterTitleFont(albumTitle.length)}
                  ref={albumTitleRef}
                  $heightOfElement={albumTitleHeight}
                  data-explorator_test_id={generateTestId("multi-track-poster", "poster-title")}
                >
                  {albumTitle.slice(0, 80)}
                </PosterDescriptionHeader>
              )
              }

              <PosterDescriptionText
                longTitle={albumTitle ? albumTitle.length >= 21 : false}
                style={{ textAlign: alignment === "start" ? "start" : "center" }}
                data-explorator_test_id={generateTestId("multi-track-poster", "poster-description")}
              >
                {isAutoGeneratedDescriptionEnabled() ? description : customDescription}
              </PosterDescriptionText>
              <img className="wavroom-stamp" src={theme === "light" ? BlackStamp : WhiteStamp} alt="wavroom-stamp" />
              {
                (songMessage !== undefined)
                && (
                  <>
                    <QRCodeContainer1>
                      <QRCodeSVG imageSettings={{ src: theme === "light" ? BlackStamp : WhiteStamp, height: QRCodeSize / 4, width: QRCodeSize / 4, excavate: true, }} size={QRCodeSize} value={`${frontEndUrl}/message?poster_image_id=${posterImageId}`} />
                    </QRCodeContainer1>
                    <QRCodeContainer2>
                      <QRCodeSVG fgColor={posterColor} size={QRCodeSize} value={`${frontEndUrl}/message?poster_image_id=${posterImageId}`} />
                    </QRCodeContainer2>
                    <QRCodeContainer3>
                      <QRCodeSVG size={QRCodeSize} value={`${frontEndUrl}/message?poster_image_id=${posterImageId}`} />
                    </QRCodeContainer3>
                    <QRCodeContainerTestSmall>
                      <QRCodeSVG size={10} value={`${frontEndUrl}/message?poster_image_id=${posterImageId}`} />
                    </QRCodeContainerTestSmall>
                  </>
                )
              }
            </PosterDescriptionContainer>
          </PosterContent>
        </Poster>
      </AutoRescaleContainer>
    </PosterPreviewContainer>
  );
});

export default AlbumPosterEditor;
