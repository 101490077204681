import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { PageWrapper } from "../../../components/v2/OtherComponents/PageWrapper";
import { useIsMobile } from "../../../hooks/isMobile";
import albumTrackImage from "../../../images/album-image.png";
import singleTrackImage from "../../../images/single-song-image.png";
import { generateTestId } from "../../../util_code/testUtil";
import { Button } from "../../../components/v2/OtherComponents/Button";
import { SubTitle } from "../../../components/v2/Text/SubTitle";

const SelectionContent = ({ previewImageSrc, to, text, testId }) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <StyledLink
      to={to}
      onMouseLeave={() => setOnHover(false)}
      onMouseOver={() => setOnHover(true)}
      data-explorator_test_id={generateTestId("home-page", `go-to-${testId}`)}
    >
      <PreviewImageContainer>
        <PreviewImage $onHover={onHover} src={previewImageSrc} />
      </PreviewImageContainer>
      <TextAndLinkContainer explorator-test-id="test">
        <TextAndLinkContent>
          <SelectionContentText>
            {text}
          </SelectionContentText>
          <Button>Create</Button>
        </TextAndLinkContent>
      </TextAndLinkContainer>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  && {
    width: calc(47% - 2rem);
    padding: 2rem;
    aspect-ratio: 8/10;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.largeBorderRadius};

    margin: auto 0;
    text-decoration: none;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.devicesMax.md} {
      width: calc(100% - 4rem);
      display: flex;
      flex-direction: column;
      margin: 0;
      /* margin-bottom: 2.5rem; */
      height: calc(45vh - ${({ theme }) => theme.navBarHeightMobile + 2}rem);
      box-sizing:content-box;
    }
  }
`;

const PreviewImageContainerHeight = 85;

const PreviewImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: ${PreviewImageContainerHeight}%;
  border-radius: ${({ theme }) => theme.largeBorderRadius};

  @media ${({ theme }) => theme.devicesMax.md} {
      box-sizing: content-box;
      width: auto;
      height: ${PreviewImageContainerHeight}%;
  };
  /* @media ${({ theme }) => theme.devicesMin.md} {
    aspect-ratio: 10/12;
  }; */
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.largeBorderRadius};

  transition: transform 0.5s ease;
  object-fit: cover;
  object-position: center;
  display: block;
  ${({ $onHover }) => $onHover && "transform: scale(1.1);"}
`;

const TextAndLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${100 - PreviewImageContainerHeight}%;
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.devicesMin.sm} and ${({ theme }) => theme.devicesMax.md} {
    width: 50%;
    flex-grow: 1;
    box-sizing: content-box;
    margin: 0rem;
    padding: 0;
  }
`;

const TextAndLinkContent = styled.div`
  margin: auto;
  width: 95%;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.devicesMax.md} {
    width:100%;
  }
`;

const SelectionContentText = styled(SubTitle)`
  && {
    color: white;
    font-size: 1.9rem;
    text-align: center;
    color: black;
    margin: auto 0;
    font-weight: 500;
    @media ${({ theme }) => theme.devicesMax.md} { 
      font-size: 1.7rem;
    }
  }
`;

export const DesignV2 = () => {
  const isMobile = useIsMobile();

  return (
    <PageWrapper>
      <DesignWrapper>
        <ContentContainer $isMobile={isMobile}>
          <SelectionContent
            previewImageSrc={singleTrackImage}
            text="Single-Song Poster"
            testId="single-track"
            to="editor/single"
            isMobile={isMobile}
          />
          <SelectionContent
            previewImageSrc={albumTrackImage}
            text="Multi-Song Poster"
            testId="multi-track"
            to="editor/multi"
            isMobile={isMobile}
          />
        </ContentContainer>
      </DesignWrapper>
    </PageWrapper>
  );
};

const DesignWrapper = styled.div`
  background: ${({ theme }) => theme.color.gray};
  height: calc(100vh - ${({ theme }) => theme.navBarHeight}rem);
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.devicesMax.md} {
      height: fit-content;
      min-height: calc(100vh - ${({ theme }) => theme.navBarHeightMobile}rem);
  };
`;

const ContentContainer = styled.div`
  width: 90rem;
  max-width: 80vw;
  height: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${({ theme }) => theme.devicesMax.md} {
    flex-direction: column;
    flex-wrap: none;
    justify-content: space-evenly;
    min-height: calc(100vh - ${({ theme }) => theme.navBarHeightMobile}rem);
  };
`;
