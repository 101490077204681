import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { PosterColorV2 } from "./SingleWizardPosterColor";
import { PosterSizeV2 } from "./SingleWizardPosterSize";
import { PosterCostText } from "../../../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { appStore } from "../../../../../stores/appStore";
import { HiddenTestingInput } from "../../../../../components/WizardComponents/HiddenTestingInput";

export const SingleWizardStep4 = observer(() => {
  const { currentPoster } = appStore;
  return (
    <div>
      <HiddenTestingInput />
      <PosterColorV2 />
      <PosterSizeV2 />
      <StyledPosterCostText>
        Price:
        {" "}
        $
        {currentPoster.price}
        {" "}
        <span>plus tax + shipping</span>
      </StyledPosterCostText>
    </div>
  );
});

const StyledPosterCostText = styled(PosterCostText)`
  && {
    margin-bottom: 1rem;
  }
`;
