import React, { useMemo } from "react";
import styled from "styled-components";

import minus from "./minus.svg";
import plus from "./plus.svg";
import { InputText } from "../Text/InputText";
import { Button } from "../OtherComponents/Button";
import { generateTestId } from "../../../util_code/testUtil";

const PlusMinusSelectorHeight = "4.5rem";

const Container = styled.div`
    display: flex;
`;

const PlusMinusButton = styled(Button)`
  && {
    @media ${({ theme }) => theme.devicesMax.md} {
      padding: 0 1rem;
      min-width: 0;
      min-height: 0;
      width: ${PlusMinusSelectorHeight};
      height: ${PlusMinusSelectorHeight};
    }
  }
`;

const PlusMinusIcon = styled.img`
    width: 2rem;
    height: auto;
    filter: invert(100%);
`;

const ValueDisplayContainerWidth = "218px"; // 21.75rem

const ValueDisplayContainer = styled.div`
    background-color: ${({ theme }) => theme.color.gray};
    width: ${ValueDisplayContainerWidth};
    height: ${PlusMinusSelectorHeight};
    padding: 1rem 0;
    margin: 0 1rem;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
`;

const ValueDisplayText = styled(InputText)`
  && {
    width: 100%;
    min-width: 100%;
    text-align: center;
    margin: auto;
    transition: 0.85s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: translate(${({ $translate }) => $translate});
  }
`;

/*
    options[] TODO look into if it's bad I use the order it's given and not a index system
        label: text
        value: any
        key: text
*/
export const PlusMinusSelector = ({ className, options, value, onChange = () => {}, increaseTestId, decreaseTestId }) => {
  const indexToDisplay = useMemo(() => {
    let keyToUse = value;
    if (!keyToUse) {
      keyToUse = options[1].key;
      onChange(keyToUse);
    }
    let optionIndexToUse = 1;
    options.forEach((option, index) => {
      if (option.key === keyToUse) optionIndexToUse = index;
    });
    return optionIndexToUse;
  }, [value]);

  const checkValidOptionAndGetCurrentOptionIndex = () => {
    if (!value) {
      onChange(options[0].key);
      return;
    }
    let currentOptionIndex = 0;
    options.forEach((option, index) => {
      if (option.key === value) currentOptionIndex = index;
    });
    // eslint-disable-next-line
    return currentOptionIndex;
  };

  const onPlus = () => {
    const currentOptionIndex = checkValidOptionAndGetCurrentOptionIndex();
    if (currentOptionIndex === undefined) return;
    if (currentOptionIndex === options.length - 1) return;
    onChange(options[currentOptionIndex + 1].key);
  };

  const onMinus = () => {
    const currentOptionIndex = checkValidOptionAndGetCurrentOptionIndex();
    if (currentOptionIndex === undefined) return;
    if (currentOptionIndex === 0) return;
    onChange(options[currentOptionIndex - 1].key);
  };

  return (
    <Container className={className}>
      <PlusMinusButton data-explorator_test_id={decreaseTestId} onClick={onMinus} variant="contained" color="dark">
        <PlusMinusIcon src={minus} />
      </PlusMinusButton>
      <ValueDisplayContainer>
        {
          options.map(option => (
            <ValueDisplayText $translate={`-${indexToDisplay * 100}%`}>
              {option.label}
            </ValueDisplayText>
          ))
        }
      </ValueDisplayContainer>
      <PlusMinusButton data-explorator_test_id={increaseTestId} onClick={onPlus} variant="contained" color="dark">
        <PlusMinusIcon src={plus} />
      </PlusMinusButton>
    </Container>
  );
};
