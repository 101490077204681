const processAlbumRawData = (rawAlbumObject) => {
  return {
    id: rawAlbumObject.id,
    artist: rawAlbumObject.artists[0].name,
    title: rawAlbumObject.name,
    albumUrl: getSmallestAlbumImage(rawAlbumObject).url,
    total_tracks: rawAlbumObject.total_tracks,
  };
};

const getSmallestAlbumImage = (rawAlbumObject) => rawAlbumObject.images.reduce((smallest, image) => {
  if (image.height < smallest.height) return image;
  return smallest;
}, rawAlbumObject.images[0]) || { url: "" };

export { processAlbumRawData };
