import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "react-medium-image-zoom/dist/styles.css";
import styled, { css } from "styled-components";
import IconButton from "@mui/material/IconButton";

import MinusIcon from "../../../images/minus.svg";
import PlusIcon from "../../../images/plus.svg";
import TrashIcon from "../../../images/trash.svg";

import { cartStore } from "../../../stores/cartStore";
import { BaseText } from "../../../components/v2/Text/BaseText";
import { SubTitle } from "../../../components/v2/Text/SubTitle";
import { frameChoiceToLabel } from "../../../util_code/displayUtility";
import { generateTestId } from "../../../util_code/testUtil";
import { useIsMobile } from "../../../hooks/isMobile";
import { SingleSongPosterEditor } from "../../../components/SingleSongPosterEditor";
import AlbumPosterEditor from "../../../components/AlbumPosterEditor/AlbumPosterEditor";
import { CartItemModal } from "./CartItemModal";

export const CartItemV2 = observer(({ cartItem }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const cartAlbumContainerRef = useRef();

  const {
    poster,
  } = cartItem;

  const {
    album,
    albumTitle,
    isSingleTrackPoster
  } = poster;

  const {
    removePosterFromCart,
    incrementQuantity,
    decrementQuantity,
  } = cartStore;

  const isMobile = useIsMobile();

  const onIncrement = () => {
    incrementQuantity(cartItem.poster);
  };

  const onDecrement = () => {
    decrementQuantity(cartItem.poster);
  };

  const deleteItemFromCart = () => {
    removePosterFromCart(cartItem.poster.uid);
  };

  if (isMobile) {
    return (
      <>
        <CartItemModal poster={poster} open={modalOpen} handleClose={() => setModalOpen(false)} />
        <CartItemsContentContainer>
          <PosterPreviewContainerCart onClick={() => setModalOpen(true)} ref={cartAlbumContainerRef}>
            {isSingleTrackPoster ? <SingleSongPosterEditor currentPoster={poster} albumContainerRef={cartAlbumContainerRef} /> : (
              <AlbumPosterEditor
                albumContainerRef={cartAlbumContainerRef}
                trackLevels={poster.trackLevels}
                currentPoster={poster}
              />
            )}
          </PosterPreviewContainerCart>
          <MobilePosterInformationAndActions>
            <MobilePosterInfoAndDeleteIcon>
              <ItemImageDescriptionWrapper>
                <PosterTitleText>{albumTitle || album.title || album.name}</PosterTitleText>
                <PosterFrameAndSizeOptionText>{`${cartItem.poster.posterSize} / ${frameChoiceToLabel(cartItem.poster.frameChoice)}`}</PosterFrameAndSizeOptionText>
              </ItemImageDescriptionWrapper>
              <TrashIconButton
                data-explorator_test_id={generateTestId("cart-page", "cate-item-remove-button")}
                onClick={() => {
                  deleteItemFromCart();
                }}
              >
                <TrashIconImage src={TrashIcon} />
              </TrashIconButton>
            </MobilePosterInfoAndDeleteIcon>
            <MobilePosterNumberOfItemsAndPrice>
              <PosterTotalPriceText>
                $
                {(cartItem.poster.price * cartItem.quantity).toFixed(2) }
              </PosterTotalPriceText>
              <IncreaseDecreaseNumberOfPostersContainer>
                <PlusMinusIconButton onClick={onDecrement}>
                  <PlusMinusIconImage src={MinusIcon} />
                </PlusMinusIconButton>
                <PosterQuantityText>{cartItem.quantity}</PosterQuantityText>
                <PlusMinusIconButton onClick={onIncrement}>
                  <PlusMinusIconImage src={PlusIcon} />
                </PlusMinusIconButton>
              </IncreaseDecreaseNumberOfPostersContainer>
            </MobilePosterNumberOfItemsAndPrice>
          </MobilePosterInformationAndActions>
        </CartItemsContentContainer>
      </>
    );
  }

  return (
    <>
      <CartItemModal poster={poster} open={modalOpen} handleClose={() => setModalOpen(false)} />
      <CartItemsContentContainer>
        <PosterImageAndTitleContainer>
          <PosterPreviewContainerCart onClick={() => setModalOpen(true)} ref={cartAlbumContainerRef}>
            {isSingleTrackPoster ? <SingleSongPosterEditor currentPoster={poster} albumContainerRef={cartAlbumContainerRef} /> : (
              <AlbumPosterEditor
                albumContainerRef={cartAlbumContainerRef}
                trackLevels={poster.trackLevels}
                currentPoster={poster}
              />
            )}
          </PosterPreviewContainerCart>
          <ItemImageDescriptionWrapper>
            <PosterTitleText>{albumTitle || album.title || album.name}</PosterTitleText>
            <PosterFrameAndSizeOptionText>{`${cartItem.poster.posterSize} / ${frameChoiceToLabel(cartItem.poster.frameChoice)}`}</PosterFrameAndSizeOptionText>
          </ItemImageDescriptionWrapper>
        </PosterImageAndTitleContainer>

        <IncreaseDecreaseNumberOfPostersContainer>
          <PlusMinusIconButton onClick={onDecrement}>
            <PlusMinusIconImage src={MinusIcon} />
          </PlusMinusIconButton>
          <PosterQuantityText>{cartItem.quantity}</PosterQuantityText>
          <PlusMinusIconButton onClick={onIncrement}>
            <PlusMinusIconImage src={PlusIcon} />
          </PlusMinusIconButton>
        </IncreaseDecreaseNumberOfPostersContainer>

        <TrashIconButton
          data-explorator_test_id={generateTestId("cart-page", "cate-item-remove-button")}
          onClick={() => {
            deleteItemFromCart();
          }}
        >
          <TrashIconImage src={TrashIcon} />
        </TrashIconButton>

        <PosterTotalPriceText>
          $
          {(cartItem.poster.price * cartItem.quantity).toFixed(2)}
        </PosterTotalPriceText>

      </CartItemsContentContainer>
    </>

  );
});

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const PosterPreviewContainerCartWidth = "10rem";

const PosterPreviewContainerCart = styled.div`
  width: ${PosterPreviewContainerCartWidth};
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MobilePosterInformationAndActions = styled.div`
  flex-grow: 2;
  padding: 0;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100% - ${PosterPreviewContainerCartWidth});
`;

const MobilePosterInfoAndDeleteIconMobilePosterNumberOfItemsAndPriceSharedStyle = css`
  display: flex;
  justify-content: space-between;
`;

const MobilePosterInfoAndDeleteIcon = styled.div`
  ${MobilePosterInfoAndDeleteIconMobilePosterNumberOfItemsAndPriceSharedStyle};
`;

const MobilePosterNumberOfItemsAndPrice = styled.div`
  ${MobilePosterInfoAndDeleteIconMobilePosterNumberOfItemsAndPriceSharedStyle};
`;

const TrashIconButton = styled(IconButton)`
  && {
    height: fit-content;
    margin: auto 0;
    @media ${({ theme }) => theme.devicesMax.md} {
      margin-bottom: auto;
      margin-top: 0;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

const TrashIconImage = styled.img`
  height: 1.6rem;
  width: 1.6rem;
`;

const PosterImageAndTitleContainer = styled.div`
  display: flex;
`;

const PlusMinusIconImage = styled.img`
  height: auto;
`;

const PlusMinusIconButton = styled(IconButton)`
  && {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    margin: auto 1.25rem;
    @media ${({ theme }) => theme.devicesMax.md} {
      margin: auto 0.3rem;
    }
  }
`;

const PosterQuantityText = styled(BaseText)`
  && {
    margin: 0 0.9rem;
    margin-bottom: 0.1rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const IncreaseDecreaseNumberOfPostersContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: fit-content;
  margin: auto 0;
`;

const CartItemsContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ItemImage = styled.img`
  height: auto;
  width: 15rem;
  border: thin solid ${({ theme }) => theme.color.black};
  @media ${({ theme }) => theme.devicesMax.md} {
    width: 10rem;
  }
`;

const ItemImageDescriptionWrapper = styled.div`
  margin: auto;
  margin-left: 3rem;
  width: fit-content;
  @media ${({ theme }) => theme.devicesMax.md} {
    margin: 0;
  }
`;

const SharedPosterTitleTextSizeAndOptionText = css`
  text-align: left;
  font-weight: 600;
`;

const PosterTitleText = styled(SubTitle)`
  && {
    font-size: 1.8rem;
    ${SharedPosterTitleTextSizeAndOptionText};
  }
`;

const PosterFrameAndSizeOptionText = styled(BaseText)`
  && {
    ${SharedPosterTitleTextSizeAndOptionText};
    font-size: 1.25rem;
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

const PosterTotalPriceText = styled(SubTitle)`
  && {
    font-weight: 600;
    font-size: 1.6rem;
    margin: auto 0;
    min-width: 8%;
    text-align: right;
  }
`;
