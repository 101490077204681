import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { notificationStore } from "../../../stores/notficationStore";
import ErrorIcon from "./error_icon.svg";
import { BaseText } from "../Text/BaseText";
import { generateTestId } from "../../../util_code/testUtil";

export const NotificationDisplay = observer(() => {
  const { notificationText } = notificationStore;
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationTimeout, setShowNotificationTimeout] = useState();

  useEffect(() => {
    if (!notificationText) return;
    if (showNotificationTimeout) clearTimeout(showNotificationTimeout);
    setShowNotification(true);
    setShowNotificationTimeout(setTimeout(() => {
      setShowNotification(false);
      notificationStore.clearNotification();
    }, 4500));
    return () => clearTimeout(showNotificationTimeout);
  }, [notificationText]);

  if (!showNotification) return <></>;
  return (
    <NotificationDisplayStyled data-explorator_test_id={generateTestId("error-message", "error-message")}>
      <NotificationIcon src={ErrorIcon} />
      <NotificationText>{notificationText}</NotificationText>
    </NotificationDisplayStyled>
  );
});

const NotificationDisplayStyled = styled.div`
    width: 25vw;
    left: 40vw;
    height: 10vh;
    top: 45vh;
    position: absolute;
    background-color: white;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    z-index: 90000;
    border: thin #E7E7E7 solid;
    @media ${({ theme }) => theme.devicesMax.md} {
      width: 85vw;
      left: 7.5vw;
    }
`;

const NotificationIcon = styled.img`
    height: 2rem;
    width: 2rem;
    margin: auto 0;
`;

const NotificationText = styled(BaseText)`
    && {
        margin: auto 0;
        text-align: center;
        font-size: 1.25rem;
    }
`;
