import React, { useMemo } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";
import { observer } from "mobx-react-lite";
import WavroomLogo from "../../../images/WR-loading-spinner.png";
import { appStore } from "../../../stores/appStore";
import { cartStore } from "../../../stores/cartStore";
import { useIsMobile } from "../../../hooks/isMobile";
import { generateTestId } from "../../../util_code/testUtil";
import { SubTitle } from "../Text/SubTitle";
import hamBurgerIcon from "../../../images/hamBurgerLogo.svg";
import { Title } from "../Text/Title";
import closeIcon from "../../../images/closeIcon.svg";

const Header = observer(() => {
  const { toggleSidebar, isSidebarOpen } = appStore;

  const location = useLocation();
  const isMobile = useIsMobile();

  const CheckIfURLStartsWith = (value) => {
    const url = window.location.pathname;
    return url.startsWith(`/${value}`);
  };

  const isOnEditor = useMemo(() => {
    return CheckIfURLStartsWith("editor");
  }, [location]);

  const isOnCart = useMemo(() => {
    return CheckIfURLStartsWith("cart");
  }, [location]);

  const onToggleSideBar = () => {
    toggleSidebar();
  };

  const HeaderMiddleDisplay = () => {
    if (isOnEditor) {
      return (
        <>steps</>
      );
    }
    if (isOnCart) {
      return (
        <PageTitle>Cart</PageTitle>
      );
    }
    return (<PageTitle>Custom Designer</PageTitle>);
  };

  const ActionDisplay = () => {
    if (isSidebarOpen) return <CloseIcon onClick={onToggleSideBar} src={closeIcon} />;
    if (isMobile) return (<StyledHamBurgerIcon onClick={onToggleSideBar} src={hamBurgerIcon} />);
    return (
      <HeaderStoreAndCartContainer>
        <SubTitleATag href="https://thewavroom.com/">
          <SubTitleLinksText>Store</SubTitleLinksText>
        </SubTitleATag>
        <SubTitleLinks to="/cart">
          <SubTitleLinksText>Cart</SubTitleLinksText>
        </SubTitleLinks>
      </HeaderStoreAndCartContainer>
    );
  };

  return (
    <TopNav>
      <NavLogoLink data-explorator_test_id={generateTestId("header", "icon-go-home-button")} to="/">
        <NavLogo src={WavroomLogo} alt="wavroom-logo" />
      </NavLogoLink>
      {HeaderMiddleDisplay()}
      {ActionDisplay()}
    </TopNav>
  );
});

export default Header;

const SubTitleLinks = styled(Link)`
    && {
      margin: auto 0;
      text-decoration: none;
    }
`;

const SubTitleATag = styled.a`
  text-decoration: none;
  margin: auto 0;
`;

const SubTitleLinksText = styled(SubTitle)`
    && {
        font-size: 2rem;
        color: ${({ theme }) => theme.color.white};
    }
`;

const PageTitle = styled(Title)`
    && {
        cursor: pointer;
        font-size: 3rem;
        color: ${({ theme }) => theme.color.white};
        margin: auto;
        text-align: center;
        ${SuperQuery().maxWidth.sm.css`
            font-size: 1.5rem;
            white-space: nowrap;
        `};
    }
`;

const HeaderStoreAndCartContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 15rem;
    margin-left: auto;
    margin-right: 3rem;
`;

const NavSpaceBetweenEdgeOfScreenAndNavContent = "2rem";

const CloseIcon = styled.img`
    width: 3rem;
    height: auto;
    margin: auto;
    padding-top: 5px;
    margin-left: auto;
    margin-right: ${NavSpaceBetweenEdgeOfScreenAndNavContent};
`;

const StyledHamBurgerIcon = styled.img`
    width: 3rem;
    height: auto;
    margin: auto;
    margin-left: auto;
    margin-right: ${NavSpaceBetweenEdgeOfScreenAndNavContent};
`;

export const TopNav = styled.nav`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 2rem;
  padding: 1.5rem;
  color: #000;
  background: black;
  height: ${({ theme }) => theme.navBarHeight}rem;

  & > a {
    text-decoration: none;
    color: #000;
  };

  ${SuperQuery().maxWidth.sm.css`
    height: ${({ theme }) => theme.navBarHeightMobile}rem;
  `};

  ${SuperQuery().maxWidth.sm.css`
    padding: 0rem;
    font-size: 1.3rem;
  `};
`;

const NavLogoLink = styled(Link)`
  && {
    height: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const NavLogo = styled.img`
  height: 7rem;
  margin: auto 0;
  margin-left: 1rem;

  ${SuperQuery().maxWidth.sm.css`
    height: 5.5rem;
    margin: auto;
    margin-left: ${NavSpaceBetweenEdgeOfScreenAndNavContent};
  `}
`;

export const NavTexts = styled.ul`
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 0;
  gap: 2rem;
  color: white;
  font-family: AvenirNextLTPro-Bold;

  ${SuperQuery().maxWidth.sm.css`
    font-size: 1.3rem;
    white-space: nowrap;
  `}
`;

export const ActionButtons = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0;

  & > span {
    font-size: 4rem;
    padding: 0 1rem;

    & > a {
      font-size: 4rem;
      color: #fff;
    }
  }
`;

export const HamburgerIconContainer = styled.span`

`;

export const ReturnToStoreButton = styled.a`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  border: 2px solid #fff;
  padding: 0 0.5em;
  transform: skewX(-15deg);
  font-size: 2.4rem;
  padding-top: 0.3rem;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: white;
  }

  span {
    transform: skewX(15deg);
  }

  ${SuperQuery().maxWidth.sm.css`
    display: none;
  `}
`;
