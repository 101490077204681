import React, { useState } from "react";
import * as htmlToImage from "html-to-image";
import { useDebouncedCallback } from "use-debounce";
import { observer } from "mobx-react-lite";
import { EditorWrapper, AlbumPosterWrapper } from "./EditorStyles";
import AlbumPosterEditor from "../../components/AlbumPosterEditor/AlbumPosterEditor";
import { appStore } from "../../stores/appStore";
import { SingleSongPosterEditor } from "../../components/SingleSongPosterEditor";

const HeadlessEditor = observer(() => {
  const [previewImage, setPreviewImage] = useState();
  const {
    currentPoster,
  } = appStore;

  const {
    posterSize,
    isSingleTrackPoster
  } = currentPoster;

  const generateImage = useDebouncedCallback(async (posterNode) => {
    try {
      // get the screenshot of the image
      const filter = (inputNode) => inputNode.tagName !== "i";
      const canvasSize = isSingleTrackPoster ? calculateSingleTrackPosterCanvasSize() : calculateMultiTrackPosterCanvasSize();
      const dataUrl = await htmlToImage.toJpeg(posterNode, {
        filter,
        canvasWidth: canvasSize.width,
        canvasHeight: canvasSize.height,
        cacheBust: true,
      });

      setPreviewImage(dataUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("oops, something went wrong!", error);
    }
  }, 1000);

  const calculateSingleTrackPosterCanvasSize = () => {
    let canvasWidth = 0;
    let canvasHeight = 0;

    switch (posterSize) {
    case "8.5 X 11":
      canvasHeight = 1700;
      canvasWidth = 2200;
      break;
    case "11 X 14":
      canvasHeight = 2200;
      canvasWidth = 2800;
      break;
    case "16 X 20":
      canvasHeight = 3200;
      canvasWidth = 4000;
      break;
    case "18 X 24":
      canvasHeight = 3600;
      canvasWidth = 4800;
      break;
    case "24 X 36":
      canvasHeight = 4800;
      canvasWidth = 7200;
      break;
    default:
      break;
    }

    return {
      width: canvasWidth,
      height: canvasHeight,
    };
  };

  const calculateMultiTrackPosterCanvasSize = () => {
    if (posterSize === "18 X 24") return { width: 3600, height: 4800 };
    if (posterSize === "24 X 36") return { width: 4800, height: 7200 };
    return { width: 3200, height: 4000 }; // "16 X 20" as default
  };

  return (
    <>
      <link rel="stylesheet" type="text/css" href={{ stylePath: "headless.css" }} />
      <EditorWrapper>
        <AlbumPosterWrapper>
          {
            isSingleTrackPoster
              ? <SingleSongPosterEditor currentPoster={currentPoster} posterOnUpdate={(node) => generateImage(node)} />
              : <AlbumPosterEditor currentPoster={currentPoster} posterOnUpdate={(node) => generateImage(node)} />
          }
        </AlbumPosterWrapper>
      </EditorWrapper>
      {
        previewImage
        && (
          <>
            <span id="poster-image-data-string">
              {previewImage}
            </span>
            {/* Enable the code below for debugging */}
            {/* <img
              src={previewImage}
              alt="preview"
              style={{ width: "500px", height: "auto" }}
            /> */}
          </>
        )
      }
    </>
  );
});

export default HeadlessEditor;
