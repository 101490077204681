import React, { useEffect, useRef, useMemo } from "react";
import "./canvasWaveform.css";
import { observer } from "mobx-react-lite";
import { appStore } from "../stores/appStore";

const waveFormStyleAttributes = {
  highResolutionWidthSingleTrack: 2000,
  highResolutionWidthMultiTrack: 500,
};

const CanvasWaveForm = observer(({ waveFormData, highResolution = false }) => {
  // Toggle this for debugging
  const forceToRenderInSVG = false;

  const canvasRef = useRef(null);
  const svgRef = useRef(null);
  const {
    headlessMode,
    currentPoster: {
      trackLevels,
      soundWaveTheme,
      posterSize,
      isSingleTrackPoster
    }
  } = appStore;

  const renderInSvgFormat = useMemo(
    () => (headlessMode === true && (posterSize === "24 X 36" || isSingleTrackPoster === true)) || forceToRenderInSVG === true, [headlessMode, posterSize]
  );

  // Ref 1: https://medium.com/swlh/creating-waveforms-out-of-spotify-tracks-b22030dd442b
  // Ref 2: https://gist.github.com/leemartin/ba839f48a353da1c9eadfa1309b85bc5
  useEffect(() => {
    if (waveFormData) {
      if (renderInSvgFormat) {
        renderWaveformSVG();
      } else {
        renderWaveform();
      }
    }
  }, [waveFormData, soundWaveTheme, headlessMode]);

  const renderWaveform = () => {
    let drawingWidth = 0;
    const canvas = canvasRef.current;

    const { height, width } = canvas.parentNode.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;
    drawingWidth = width;

    const context = canvas.getContext("2d");

    context.clearRect(0, 0, canvas.width, canvas.height);

    if (highResolution) {
      if (isSingleTrackPoster) {
        context.width = waveFormStyleAttributes.highResolutionWidthSingleTrack;
        canvas.width = waveFormStyleAttributes.highResolutionWidthSingleTrack;
        drawingWidth = waveFormStyleAttributes.highResolutionWidthSingleTrack;
      } else {
        context.width = waveFormStyleAttributes.highResolutionWidthMultiTrack;
        canvas.width = waveFormStyleAttributes.highResolutionWidthMultiTrack;
        drawingWidth = waveFormStyleAttributes.highResolutionWidthMultiTrack;
      }
    }

    for (let x = 0; x < drawingWidth; x++) {
      const i = Math.ceil(waveFormData.length * (x / drawingWidth));
      const lineHeight = Math.round(waveFormData[i] * height) / 2;

      context.fillStyle = soundWaveTheme === "light" ? "#fff" : "#000";
      context.fillRect(x, height / 2 - lineHeight + 1, 1, lineHeight);
      context.fillRect(x, height / 2, 1, lineHeight);
    }
  };

  const renderWaveformSVG = () => {
    let drawingWidth = 0;
    const svg = svgRef.current;

    const { height, width } = svg.parentNode.getBoundingClientRect();
    svg.setAttribute("width", width);
    svg.setAttribute("height", height);
    drawingWidth = width;

    if (highResolution) {
      if (isSingleTrackPoster) {
        svg.setAttribute("width", waveFormStyleAttributes.highResolutionWidthSingleTrack);
        drawingWidth = waveFormStyleAttributes.highResolutionWidthSingleTrack;
      } else {
        svg.setAttribute("width", waveFormStyleAttributes.highResolutionWidthMultiTrack);
        drawingWidth = waveFormStyleAttributes.highResolutionWidthMultiTrack;
      }
    }

    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }

    const rects = [];
    const lineWidth = 1;

    for (let x = 0; x < drawingWidth; x++) {
      const i = Math.ceil(waveFormData.length * (x / (drawingWidth)));
      const lineHeight = Math.round(waveFormData[i] * height);

      const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
      rect.setAttribute("x", x * lineWidth);
      rect.setAttribute("y", (height / 2) - (lineHeight / 2));
      rect.setAttribute("width", lineWidth);
      rect.setAttribute("height", lineHeight);
      rect.setAttribute("fill", soundWaveTheme === "light" ? "#fff" : "#000");
      rects.push(rect);
    }

    rects.forEach(rect => svg.appendChild(rect));
  };

  return (
    <>
      {
        renderInSvgFormat
          ? <svg className={trackLevels.length > 15 ? "three-by-three" : "two-by-two"} ref={svgRef} />
          : <canvas className={trackLevels.length > 15 ? "three-by-three" : "two-by-two"} ref={canvasRef} />
      }
    </>
  );
});

export default CanvasWaveForm;
