import React, { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../stores/appStore";
import { wizardStore } from "../../../stores/wizardStore";
import { SearchWizardV2 } from "./MultiTrackWizardSteps/SearchWizard/SearchWizard";
import { TitleDescriptionWizardV2 } from "./MultiTrackWizardSteps/DescriptionWizard";
import { ColorAndTextOptionsWizardV2 } from "./MultiTrackWizardSteps/ColorAndTextOptionsWizard/ColorAndTextOptionsWizard";
import { PosterSizeAndFrameWizardV2 } from "./MultiTrackWizardSteps/PosterSizeAndFrameWizard";
import { StepTitleDisplayV2 } from "../../../components/v2/EditorSharedComponents/StepTitleDisplay";
import { StepperContainer, WizardStepperContainer } from "../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { StepNavigationBarV2 } from "../../../components/v2/EditorSharedComponents/StepNavigationBar";

export const MultiWizardStepperV2 = observer(({ editorStepsSectionRef }) => {
  const {
    logAnalyticsEvent
  } = appStore;

  const {
    step,
    search,
    searchOnUpdate,
  } = wizardStore;

  useEffect(
    useDebouncedCallback(async () => {
      await searchOnUpdate();
      return () => { };
    }, 300),
    [search]
  );

  const renderWizard = () => {
    switch (step) {
    case 1:
      return (
        <SearchWizardV2 />
      );
    case 2:
      logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep2",
      });
      return (
        <TitleDescriptionWizardV2 />
      );
    case 3:
      logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep3",
      });
      return (
        <ColorAndTextOptionsWizardV2 />
      );
    case 4:
      logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep4",
      });
      return <PosterSizeAndFrameWizardV2 />;
    default:
      return null;
    }
  };

  return (
    <WizardStepperContainer ref={editorStepsSectionRef || undefined}>
      <StepTitleDisplayV2 />
      <StepperContainer>
        <StepperContent>
          {renderWizard()}
        </StepperContent>
      </StepperContainer>
      <StepNavigationBarV2 />
    </WizardStepperContainer>
  );
});

const StepperContent = styled.div``;
