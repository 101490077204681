import React, { useRef } from "react";
import styled from "styled-components";
import { Modal } from "@mui/material";

import { SingleSongPosterEditor } from "../../../components/SingleSongPosterEditor";
import AlbumPosterEditor from "../../../components/AlbumPosterEditor/AlbumPosterEditor";

export const CartItemModal = ({ poster, open, handleClose }) => {
  const cartItemAlbumContainerRef = useRef();

  const {
    isSingleTrackPoster,
    trackLevels
  } = poster;

  const onHandleClose = () => {
    handleClose();
  };

  return (
    <StyledModal open={open} onClose={onHandleClose}>
      <CartItemModalContent onClick={onHandleClose} ref={cartItemAlbumContainerRef}>
        {isSingleTrackPoster ? <SingleSongPosterEditor useLocalState currentPoster={poster} albumContainerRef={cartItemAlbumContainerRef} /> : (
          <AlbumPosterEditor
            albumContainerRef={cartItemAlbumContainerRef}
            useLocalState
            trackLevels={trackLevels}
            currentPoster={poster}
          />
        )}
      </CartItemModalContent>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)``;

const CartItemModalContent = styled.div`
    height: 60vh;
    margin: auto;
    margin-top: 20vh;
    margin-bottom: 20vh;
`;
