import React from "react";
import { FormControl, RadioGroup as MUIRadioGroup, FormControlLabel, Radio, FormLabel } from "@mui/material";
import styled from "styled-components";

import { InputText } from "../Text/InputText";

const StyledInputText = styled(InputText)`
  && {
    margin: auto 0;
  }
`;

/*
   options[] TODO look into if it's bad I use the order it's given and not a index system
        label: text
        value: any
        key: text
    title
*/
export const RadioGroup = (props) => {
  const { options, title, className, ...restOfProps } = props;
  return (
    <FormControl className={className}>
      <FormLabel>{title}</FormLabel>
      <MUIRadioGroup {...restOfProps}>
        {
          options.map(option => (<FormControlLabel value={option.value} control={<Radio />} label={<StyledInputText>{option.label}</StyledInputText>} />))
        }
      </MUIRadioGroup>
    </FormControl>

  );
};
