import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../stores/appStore";
import { PageWrapper } from "../OtherComponents/PageWrapper";
import AlbumPosterEditor from "../../AlbumPosterEditor/AlbumPosterEditor";
import {
  PreviewButtonAbsoluteContainer,
  PreviewButtonSize,
  PreviewPoster,
} from "../../PreviewPoster/PreviewPoster";
import { PreviewModal } from "../../PreviewPoster/PreviewModal";
import { generateTestId } from "../../../util_code/testUtil";
import { SingleSongPosterEditor } from "../../SingleSongPosterEditor";
import { EditorStepsSection } from "./EditorStepsSection";

export const EditorV2 = observer(({ singleTrackEditor }) => {
  const { currentPoster, analytics, logAnalyticsEvent } = appStore;
  const { albumId, song } = currentPoster;

  const albumContainerRef = useRef();

  useEffect(() => {
    if (analytics) {
      // TODO figure out how to translate this analytics.send({ hitType: "pageview", page: "/editor" });
      logAnalyticsEvent(singleTrackEditor ? "single_track" : ANALYTICS_MULTI_TRACK_LABEL, {
        category: "pageLanding",
        action: "initialLanding",
      });
    }
  }, [analytics]);

  useEffect(() => {
    return () => {
      appStore.clearPosterSelections();
    };
  }, []);

  return (
    <>
      <PageWrapper>
        <EditorWrapper>
          {singleTrackEditor ? (
            <AlbumPosterWrapper
              data-explorator_test_id={generateTestId(
                "single-song-display",
                "wrapper"
              )}
              ref={albumContainerRef}
            >
              <SingleSongPosterEditor currentPoster={currentPoster} albumContainerRef={albumContainerRef} />
              {song && (
                <PreviewButtonAbsoluteContainer>
                  <PreviewPoster />
                </PreviewButtonAbsoluteContainer>
              )}
            </AlbumPosterWrapper>
          ) : (
            <AlbumPosterWrapper ref={albumContainerRef}>
              <AlbumPosterEditor
                albumContainerRef={albumContainerRef}
                trackLevels={currentPoster.trackLevels}
                currentPoster={currentPoster}
              />
              {albumId && (
                <PreviewButtonAbsoluteContainer>
                  <PreviewPoster />
                </PreviewButtonAbsoluteContainer>
              )}
            </AlbumPosterWrapper>
          )}
          <EditorStepsSection singleTrackEditor={singleTrackEditor} />
          <PreviewModal />
        </EditorWrapper>
      </PageWrapper>
    </>
  );
});

export const EditorWrapper = styled.div`
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - ${({ theme }) => theme.navBarHeight}rem);
  background-color: ${({ theme }) => theme.color.gray};
  padding: 1rem;
  overflow: hidden;
  @media ${({ theme }) => theme.devicesMax.md} {
    height: calc(100vh - ${({ theme }) => theme.navBarHeightMobile}rem);
    padding: 0;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const AlbumPosterWrapper = styled.div`
  width: ${({ theme }) => theme.ALBUM_POSTER_WRAPPER_WIDTH_PERCENTAGE}%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media ${({ theme }) => theme.devicesMax.md} {
    padding: 3rem 0;
    flex-grow: 2;
    min-height: 0;
  }
  @media ${({ theme }) => theme.devicesMax.sm} {
    width: 100vw;
    padding: 0;
    box-sizing: border-box;
    /* height: 65vh; */
    /* padding-bottom: ${PreviewButtonSize}; */
  }
`;

export const Poster = styled.div`
  background: ${({ theme }) => theme.body};
  transition: background 0.3s ease-in-out;
  border: ${({ frameColor }) => `14px solid ${frameColor}`};
  aspect-ratio: ${({ frameSize }) => {
    if (frameSize === "16 X 20") return "16/20";
    if (frameSize === "18 X 24") return "18/24";
    if (frameSize === "24 X 36") return "24/36";
    return "16/20";
  }};

  width: 400px;
  font-size: 3rem;
  box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);
  -webkit-box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);
  -moz-box-shadow: 2px 2px 43px 8px rgba(0, 0, 0, 0.89);

  @media ${({ theme }) => theme.devicesMax.md} {
    top: 100%;
  }
  @media ${({ theme }) => theme.devicesMax.sm} {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const RenderingShadow = styled.div`
  z-index: 5;
  height: ${(props) => props.posterHeight}px;
  width: ${(props) => props.posterWidth}px;
  background-color: rgba(0, 0, 0, 0.45);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${({ theme }) => theme.devicesMax.sm} {
    transform: translate(-50%, -50%) scale(0.8);
  }
`;
