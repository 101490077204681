import React from "react";
import styled from "styled-components";
import { BaseText } from "./BaseText";

const StyledSubTitle = styled(BaseText)`
`;

export const SubTitle = (props) => {
  const { children } = props;
  return (
    <StyledSubTitle level="h3" {...props}>
      {children}
    </StyledSubTitle>
  );
};
