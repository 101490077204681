import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";

export const PosterPreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  ${SuperQuery().maxWidth.sm.css`
    max-width: 100vw;
`};
`;

export const PosterContent = styled.div`
  height: 100%;
  padding: 3% 2%;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const HardwoodBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
`;

export const HardwoodBackgroundWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const PosterTracksWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-top: 56px;
  position: relative;
`;

export const PosterTracksContainer = styled.div`
  height: 100%;
  background: ${({ postercolor }) => postercolor};
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: background-color .1s ease-in-out;

  & > .wave-wrapper {
    height: 100%;
    max-height: 100px;
    canvas, svg {
      height: 100%;
    }
  }
`;

export const PosterHeaderTextContainer = styled.div`
  bottom: -${({ $artistFontHeight }) => $artistFontHeight === undefined ? 0 : $artistFontHeight}px;
  right: -3px;
  position: absolute;
  width: 100%;
  min-width: 60%;
`;

export const PosterHeaderContainer = styled.div`
  height: 0;
  position: relative;
`;

export const PosterFooterContainer = styled.div`
  background: transparent;
  left: 26px;
  right: 26px;
  margin-top: -16px; // PosterDescriptionText line-height + padding
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  * {
    font-family: AvenirNextLTPro-Bold;
  }

  & > div {
    width: 50%;
    &:last-child {
      text-align: right;
    }
  }
`;

export const PosterDescriptionText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 0px;
  // font-family: AvenirNextLTPro-Bold; // TODO Need a middle ground between font weights
`;

export const PosterSongTitle = styled.div`
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: AvenirNextLTPro-Bold;
  text-align: right;
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 90%; // This is because text does not perfectly fit to the height of a div.
`;

export const PosterArtistName = styled.div`
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: 700;
  color: ${({ $color }) => $color};
  height: fit-content;
  text-align: right;
  font-weight: bolder;
  font-family: AvenirNextLTPro-Bold;
  line-height: 90%; // This is because text does not perfectly fit to the height of a div.
  transition: color .1s ease-in-out;
  width: 100%;
`;

const durationHeight = "1.5rem";

export const PosterDurationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.9px solid #000;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  padding-left: 1px;
  padding-right: 1px;
  height: ${durationHeight};
  line-height: ${durationHeight};
  font-size: 11px;
  font-family: ${({ $fontFamily }) => $fontFamily};
  font-weight: ${({ $fontWeightForTest }) => $fontWeightForTest};
`;

export const PosterDuration = styled.span`
  font-size: 11px;
  height: ${durationHeight};
  box-sizing: border-box;
  font-size-adjust: 0.5;
  margin-top: ${({ $offsetValue }) => $offsetValue}px;
`;

export const HorizontalPoster = styled.div`
  height: ${({ theme }) => theme.POSTER_WIDTH_RAW_V2}px;
  aspect-ratio: ${({ frameSize }) => {
    switch (frameSize) {
    case "8.5 X 11":
      return "11/8.5";
    case "11 X 14":
      return "14/11";
    case "16 X 20":
      return "20/16";
    case "18 X 24":
      return "24/18";
    default:
      return "11/8.5";
    }
  }};

  background: ${({ theme }) => theme.body};
  transition: background 0.3s ease-in-out;
  border: ${({ frameColor }) => `14px solid ${frameColor}`};

  font-size: 3rem;

  ${SuperQuery().maxWidth.md.css`
    top: 100%;
  `}
`;
