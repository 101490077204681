import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { generateTestId } from "../../../util_code/testUtil";

export const SpotifySearchResultV2 = observer(({ result, openSearch, onSelectResult, isSingleTrackPoster }) => {
  const { title, artist, image } = result;

  return (
    <ResultContainer data-explorator_test_id={generateTestId(`${isSingleTrackPoster ? "single" : "multi"}-track-wizard`, `spotify-result-${title}`)} onClick={() => openSearch ? openSearch() : onSelectResult(result)}>
      <ResultImage img={image} />
      <ResultContainerText>
        <p>{title}</p>
        <p>{artist}</p>
      </ResultContainerText>
    </ResultContainer>
  );
});

const ResultContainer = styled.div`
  padding: .8rem 1rem;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.gray};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
`;

const ResultImage = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background: #777;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ResultContainerText = styled.div`
  font-size: 2.5rem;
  letter-spacing: 0.05rem;
  max-width: 80%;

  @media ${({ theme }) => theme.devicesMax.sm} {
    max-width: 70%;
  };

  & > p {
    margin: 0;
  }

  & > p:nth-child(1) {
    font-weight: 600;
  }

  & > p:nth-child(2) {
    font-weight: normal;
    color: #777;
  }
`;
