import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";

export const PosterPreviewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
`;

export const PosterContent = styled.div`
  display: grid;
  grid-template-rows: 4fr 1fr;
  grid-template-columns: 1fr;
  height: 100%;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const PosterTracksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PosterTracksContainer = styled.div`
  ${({ notActive }) => notActive
    && `
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
  `}

  margin-top: 25px;
  height: 100%;
  width: calc(100% - 45px);
  background: ${({ postercolor }) => postercolor};
  transition: background-color .1s ease-in-out;
  padding: 28px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(103px, 1fr));
  justify-items: center;
  align-items: start;
  grid-column-gap: 30px;

  ${({ threebythree }) => threebythree
    && `
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    grid-column-gap: 20px;
  `}

  & > .wave-wrapper {
    font-size: 1px;
    justify-items: center;
    margin: 0;
    width: 100%;

    & > div {
      padding: 1px 5px;
      display: flex;
      flex-direction: row;

      margin: 0;
      color: ${({ theme }) => theme.body};
    }

    & > .track-info-container {
      width: 100%;
      display: flex;
      flex-direction: row;

      .track-name {
        color: ${({ trackTheme }) => trackTheme === "light" ? "#fff" : "#000"};
        font-size: 5px;
        text-transform: uppercase;
        font-family: "NexaDemo-Bold";
        max-width: 105px;
        margin-right: 3px;

        &.short-title {
          word-break: normal;
          white-space: nowrap;
        }
      }

      .duration {
        color: ${({ trackTheme }) => trackTheme === "light" ? "#fff" : "#000"};
        max-width: 20%;
        font-size: 5px;
        font-family: "Nexa W01 Book";
        width: 10%;
        max-width: 15px;
      }
    }
  }

  & > .wave-wrapper:nth-child(odd):last-child {
    align-items: center;
  }
`;

export const PosterDescriptionContainer = styled.div`
  background: transparent;
  padding: 28px 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${(props) => (props.alignment === "start" ? "start" : "center")};
  .wavroom-stamp {
    width: 24px;
    height: 30px;
  }
`;

export const PosterDescriptionHeader = styled.p`
  font-weight: bold;
  margin: 0;
  font-style: italic;
  text-transform: uppercase;
  font-family: "Nexa W01 Bold Italic";
  position: absolute;
  white-space: nowrap;
  color: ${({ theme }) => theme.text};
  ${({ $heightOfElement }) => $heightOfElement ? `
    top: -${$heightOfElement / 2}px;
  ` : `
    top: -16px;
  `}
  ${({ longTitle }) => longTitle
    && `
    white-space: break-spaces;
    width: 75%;
  `}
`;

export const PosterDescriptionText = styled.p`
  margin: 0;
  font-size: 4px;
  font-family: "Nexa W01 Book";
  white-space: break-spaces;

  & > .start {
    text-align: start;
  }

  & > .center {
    text-align: center;
  }

  color: ${({ theme }) => theme.text};

  & + img {
    height: 4rem;
    position: absolute;
    right: 2rem;
    bottom: 0;
    width: fit-content;
    margin-left: auto;
    display: block;

    @media (max-width: 768px) {
      height: 3rem;
      bottom: 0rem;
    }
  }

  ${({ longTitle }) => longTitle
    && `
    margin-top: 6px;
  `}
`;
