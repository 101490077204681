import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  width: 6rem;
  height: 6rem;
  left: 0.9rem;
  top: 0.9rem;
`;

export const SpinnerContainer = styled.div`
  width: 8rem;
  height: 8rem;
  filter: invert(100%);
  -webkit-filter: invert(100%);
  ${(renderImageSpinner) => renderImageSpinner
    && `
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Logo = styled.img`
  width: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
