import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";

export const WavroomButton = observer(
  ({ className, onClick, children, testId }) => {
    return (
      <>
        <WavroomButtonStyled
          onClick={onClick}
          className={className}
          data-explorator_test_id={testId}
        >
          {typeof children === "string" ? (
            <WavroomButtonText>{children}</WavroomButtonText>
          ) : (
            <>{ children }</>
          )}
        </WavroomButtonStyled>
      </>
    );
  }
);

const WavroomButtonText = styled.p`
  margin: auto;
  font-size: 1.7rem;
  font-weight: bolder;
  text-transform: uppercase;
`;

const WavroomButtonStyled = styled.div`
  width: 20rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  border: solid white 2px;
  cursor: pointer;
  ${SuperQuery().maxWidth.sm.css`
    width: 40%;
  `};
  background-color: white;
  color: black;
`;
