import React from "react";
import styled from "styled-components";
import MUIButton from "@mui/material/Button";
import { InputText } from "../Text/InputText";

const StyledButton = styled(MUIButton)`
    && {
        border-radius: ${({ theme }) => theme.defaultBorderRadius};
        text-transform: capitalize;
    }
`;

const StyledInputText = styled(InputText)`
  && {
    color: white;
    text-transform: capitalize;
  }
`;

export const Button = (props) => {
  const { children } = props;
  return (
    <StyledButton color="dark" variant="contained" {...props}>
      {typeof children === "string"
        ? <StyledInputText>{children}</StyledInputText>
        : children }
    </StyledButton>
  );
};
