import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { Label } from "../../../../../components/v2/Text/Label";
import { useIsMobile } from "../../../../../hooks/isMobile";
import { RadioGroup } from "../../../../../components/v2/Inputs/RadioGroup";
import { Dropdown } from "../../../../../components/v2/Inputs/Dropdown";
import { RadioGroupImages } from "../../../../../components/v2/Inputs/RadioGroupImages";
import { SinglePosterFrameChoicePricingTypes, SinglePosterSizeOptions } from "../../../../../stores/posterStore";
import { appStore } from "../../../../../stores/appStore";
import { PosterSizeInputV2 } from "../../../../../components/v2/Inputs/PosterSizeInput";
import { PosterFrameInputV2 } from "../../../../../components/v2/Inputs/PosterFrameInput";

export const PosterSizeV2 = observer(() => {
  const { currentPoster } = appStore;
  const { posterSize, setPosterSize, setFrameChoice, frameChoice } = currentPoster;
  const isMobile = useIsMobile();

  useEffect(() => {
    setFrameChoice(SinglePosterFrameChoicePricingTypes[0].name);
  }, []);

  const frameChoices = useMemo(() => {
    return SinglePosterFrameChoicePricingTypes.map(frameChoicePricingType => {
      const label = `${frameChoicePricingType.name}`;
      return { label, value: frameChoicePricingType.name, image: frameChoicePricingType.image };
    });
    // { label: "Small", value: "small", image: WhiteFrame }, { label: "Medium", value: "medium", image: BlackFrame }, { label: "Large", value: "large", image: WoodFrame }
  }, [posterSize]);

  return (
    <>
      <PosterSizeInputV2 isSingleTrack onChange={(value) => setPosterSize(value)} value={posterSize} options={SinglePosterSizeOptions} />
      <PosterFrameInputV2 value={frameChoice} onChange={(value) => setFrameChoice(value)} options={frameChoices} />
      {/* {!isMobile
        ? <StyledRadioGroup value={posterSize} onChange={(event) => setPosterSize(event.target.value)} options={SinglePosterSizeOptions.map(posterSizeOption => ({ label: posterSizeOption.name, value: posterSizeOption.size, key: posterSizeOption.size }))} />
        : <StyledDropdown value={posterSize} onChange={(event) => setPosterSize(event.target.value)} options={SinglePosterSizeOptions.map(posterSizeOption => ({ label: posterSizeOption.name, value: posterSizeOption.size, key: posterSizeOption.size }))} /> } */}
      {/* {!isMobile
        ? <RadioGroupImages value={frameChoice || {}} onChange={setNewFrameChoice} options={frameChoices} />
        : <StyledDropdown value={frameChoice || {}} onChange={setNewFrameChoice} options={frameChoices} />} */}
    </>
  );
});

const PosterSizeLabel = styled(Label)`
    && {
        margin-top: 2rem;
    }
`;

const StyledRadioGroup = styled(RadioGroup)`
    && {
        margin-bottom: 2rem;
    }
`;

const StyledDropdown = styled(Dropdown)`
  && {
    width: 100%;
    min-width: 0 !important;
  }
`;

const FrameLabel = styled(Label)`
    && {
        /* margin-top: 2rem; */
        margin-bottom: 1.5rem;
    }
`;
