import React, { useMemo } from "react";
import styled from "styled-components";
import { Label } from "../Text/Label";
import PosterScreenShot from "./PosterScreenShot.png";
import { useIsMobile } from "../../../hooks/isMobile";
import { generateTestId } from "../../../util_code/testUtil";

// options = { aspectRatio: string, value: any, label: string }[]
export const PosterSizeInputV2 = ({ options, value, onChange, isSingleTrack }) => {
  const isMobile = useIsMobile();

  const numberOfEntriesPerRow = useMemo(() => {
    if (!isSingleTrack) {
      return options.length;
    }
    return isMobile ? 2 : options.length;
  }, [isSingleTrack, isMobile]);

  const PosterSizeLabel = useMemo(() => {
    const selectedOption = options.find(option => option.value === value);
    if (selectedOption) return selectedOption.value;
    return "";
  }, [value, options]);

  return (
    <>
      <StyledLabel>
        Poster Size:
        {" "}
        <span>{PosterSizeLabel}</span>
      </StyledLabel>
      <StyledPosterSizeInputV2>
        <PosterSizeInputV2Container>
          {
            options.map(option => (
              <PosterSizeOption data-explorator_test_id={generateTestId("general-wizard", `${option.value}-size-button`)} $numberOfOptionsPerRow={numberOfEntriesPerRow} onClick={() => onChange(option.value)}>
                <PosterSizeContent $active={value === option.value}>
                  <PosterSizeOptionText $isSingleTrack={isSingleTrack}>{option.label}</PosterSizeOptionText>
                </PosterSizeContent>
              </PosterSizeOption>
            ))
          }
        </PosterSizeInputV2Container>
      </StyledPosterSizeInputV2>
    </>

  );
};

const StyledLabel = styled(Label)`
  && {
    font-weight: normal;
    && span {
      font-weight: bolder;
      font-size: inherit;
    }
  }
`;

const PosterSizeInputV2Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;
`;

const StyledPosterSizeInputV2 = styled.div`
  margin-bottom: 2rem;
`;

const PosterSizeOption = styled.div`
    width: ${({ $numberOfOptionsPerRow }) => (100 / $numberOfOptionsPerRow) - 2}%;
    margin-top: auto;
    cursor: pointer;
`;

const PosterSizeContent = styled.div`
  width: 10rem;
  padding: calc(${({ $active }) => $active ? "0.4rem - 1px" : "0.4rem"}) 0;
  border: 1px solid ${({ theme }) => theme.color.gray};
  ${({ $active, theme }) => $active && `border: 2px solid ${theme.color.black};`};
  margin: auto;
  max-width: 100%;
`;

const PosterSizeOptionExample = styled.div`
    width: ${({ $width }) => $width};
    height: auto;
    margin: auto;
    margin-top: auto;
    margin-bottom: 0;
    display: block;
    border: solid 2px transparent;
    ${({ $active }) => $active && "border-color: black"};
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
    position: relative;
    background-color: transparent;
`;

const PosterSizeOptionExampleImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const PosterSizeOptionText = styled(Label)`
    && {
        margin: auto;
        text-align: center;
        /* color: white; */
        font-size: 1.5rem;
        ${({ $isSingleTrack }) => $isSingleTrack && "font-size: 1.25rem"};
    }
`;

const PosterSizeOptionExampleContent = styled.div`
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    position: absolute;
    display: flex;
    justify-content: center;
`;
