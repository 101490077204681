import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../../stores/appStore";
import { generateTestId } from "../../../../util_code/testUtil";
import { BooleanInput } from "../../../../components/v2/Inputs/BooleanInput";
import { TextField } from "../../../../components/v2/Inputs/TextField";
import { Label } from "../../../../components/v2/Text/Label";
import { BaseText } from "../../../../components/v2/Text/BaseText";
import { AUTO_GENERATED_ENUM, CUSTOM_ENUM } from "../../../../stores/posterStore";

export const TitleDescriptionWizardV2 = observer(() => {
  const { currentPoster, logAnalyticsEvent } = appStore;

  const {
    description,
    customDescription,
    descriptionType,
    isCustomDescriptionEnabled,
    isAutoGeneratedDescriptionEnabled,
    enableAutoGeneratedDescription,
    enableCustomDescription,
    setCustomDescription,
  } = currentPoster;

  useEffect(() => {
    // TODO make this an error?
    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "unable_to_auto_generate_description",
    });
  }, []);

  useEffect(() => {
    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: isAutoGeneratedDescriptionEnabled()
        ? "switch_to_auto_generated_description"
        : "switch_to_custom_description",
    });
  }, [descriptionType]);

  const onChange = (e) => {
    if (e.target.value.length > 1000) return; // TODO maybe add warning, idk if it's necessary
    setCustomDescription(e.target.value);
  };

  const onBooleanInputChanged = (newValue) => {
    if (newValue === AUTO_GENERATED_ENUM) {
      enableAutoGeneratedDescription();
    } else {
      enableCustomDescription();
    }
  };

  return (
    <div>
      <Label>Album Description</Label>
      <StyledBooleanInput onChange={onBooleanInputChanged} value={descriptionType} optionA={{ label: "Create my own", value: CUSTOM_ENUM, testId: generateTestId("multi-track-wizard", "custom-description-button") }} optionB={{ label: "Auto-generate", value: AUTO_GENERATED_ENUM, testId: generateTestId("multi-track-wizard", "auto-description-button") }} />
      <StyledTextField data-explorator_test_id={generateTestId("multi-track-wizard", "poster-poster-description")} maxLength={1000} multiline minRows={4} disabled={isAutoGeneratedDescriptionEnabled()} onChange={onChange} value={isAutoGeneratedDescriptionEnabled() ? description : customDescription} />
      {!isAutoGeneratedDescriptionEnabled() && <CustomDescriptionCharacterCount>{`${customDescription.length}/1000`}</CustomDescriptionCharacterCount>}
    </div>
  );
});

const CustomDescriptionCharacterCount = styled(BaseText)`
  && {
    text-align: right;
  }
`;

const StyledBooleanInput = styled(BooleanInput)`
    margin: auto;
`;

const StyledTextField = styled(TextField)`
    && {
        margin-top: 1rem;
    }
`;
