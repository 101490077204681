import React from "react";
import styled from "styled-components";
import { BaseText } from "./BaseText";

const StyledLabel = styled(BaseText)`
  && {
    font-size: ${({ theme }) => theme.fontSize.input};
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
`;

export const Label = (props) => {
  const { children } = props;
  return (
    <StyledLabel level="p" {...props}>
      {children}
    </StyledLabel>
  );
};
