import { makeAutoObservable } from "mobx";

export class NotificationStore {
  constructor() {
    // cartItems will be an array of objects in the following format:
    /**
     * { poster: <PosterStore>, quantity: <number> }
     */
    this.notificationText = undefined;
    this.notificationType = "error";

    makeAutoObservable(this, {}, { autoBind: true });
  }

  showError(text) {
    this.notificationText = text;
    this.notificationType = "error";
  }

  clearNotification() {
    this.notificationText = undefined;
    this.notificationType = undefined;
  }
}

export const notificationStore = new NotificationStore();
