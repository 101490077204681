import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { POSTER_PARAMETERS, appStore } from "./appStore";

const filterPosterObjBeforeOrderPlacement = ({ poster, quantity }) => {
  const filteredPosterObj = {};

  POSTER_PARAMETERS.forEach((posterParameter) => {
    if (poster[posterParameter.key]) filteredPosterObj[posterParameter.key] = poster[posterParameter.key];
  });

  filteredPosterObj.isTesting = !!poster.isTesting;

  if (poster.isSingleTrackPoster) {
    filteredPosterObj.isSingleTrackPoster = true;
    filteredPosterObj.total_tracks = 1;
    filteredPosterObj.artist = poster.song.artists[0].name;
    filteredPosterObj.title = poster.song.name;
    filteredPosterObj.id = poster.song.id;
  } else {
    filteredPosterObj.isSingleTrackPoster = false;
    filteredPosterObj.title = poster.albumTitle || poster.album.title;
    filteredPosterObj.id = poster.album.id;
    filteredPosterObj.artist = poster.album.artist;
    filteredPosterObj.description = poster.isAutoGeneratedDescriptionEnabled() ? poster.description : poster.customDescription;
  }
  return { ...filteredPosterObj, quantity };
};

export class CartStore {
  constructor() {
    // cartItems will be an array of objects in the following format:
    /**
     * { poster: <PosterStore>, quantity: <number> }
     */
    this.cartItems = [];
    this.loading = false;
    this.termsAndConditionChecked = false;
    this.totalPrice = 0;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setTermsAndConditionChecked(checked) {
    this.termsAndConditionChecked = checked;
  }

  updateTotalPrice() {
    this.totalPrice = 0;
    this.cartItems.forEach((cartItem) => {
      this.totalPrice += cartItem.poster.price * cartItem.quantity;
    });
  }

  addPosterToCart(posterStoreObj) {
    const existingCartItem = this.cartItems.find(
      (cartItem) => cartItem.poster.id === posterStoreObj.uid
    );

    if (existingCartItem) {
      existingCartItem.quantity++;
    } else {
      this.cartItems.push({ poster: posterStoreObj, quantity: 1 });
    }
    this.updateTotalPrice();
    appStore.clearPosterSelections();
  }

  removePosterFromCart(posterUID) {
    const existingCartItem = this.cartItems.find(
      (cartItem) => cartItem.poster.uid === posterUID
    );

    if (existingCartItem.quantity === 1) {
      this.cartItems = this.cartItems.filter(
        (cartItem) => cartItem.poster.uid !== posterUID
      );
    } else {
      existingCartItem.quantity--;
    }
    this.updateTotalPrice();
  }

  incrementQuantity(posterStoreObj) {
    const existingCartItem = this.cartItems.find(
      (cartItem) => cartItem.poster.id === posterStoreObj.id
    );

    existingCartItem.quantity++;
    this.updateTotalPrice();
  }

  decrementQuantity(posterStoreObj) {
    const existingCartItem = this.cartItems.find(
      (cartItem) => cartItem.poster.id === posterStoreObj.id
    );

    if (existingCartItem.quantity === 1) {
      this.cartItems = this.cartItems.filter(
        (cartItem) => cartItem.poster.id !== posterStoreObj.id
      );
    } else {
      existingCartItem.quantity--;
    }
    this.updateTotalPrice();
  }

  async submitOrder() {
    const {
      backendFunctionUrl,
      throwErrorMessage,
      frontEndUrl,
    } = appStore;

    this.loading = true;

    try {
      const orderPlacementCartItems = [];

      this.cartItems.forEach((cartItem) => {
        orderPlacementCartItems.push(filterPosterObjBeforeOrderPlacement(cartItem));
      });

      const orderPlacementResponse = await axios.post(`${backendFunctionUrl}/place_order`, orderPlacementCartItems);

      // Only continue to shopify on production and staging
      if (frontEndUrl === "https://custom.thewavroom.com" || frontEndUrl === "https://wavroom-staging.web.app") {
        // Replace Shopify default URL to official URL. Only work on production app
        window.location = orderPlacementResponse.data.shopifyCheckoutUrl.replace("memory-motel.myshopify.com", "thewavroom.com");
      } else {
        window.location = "/";
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throwErrorMessage("Sorry, we are unable to process your order at the moment. Please contact admin or try again later.");
    }

    runInAction(() => {
      this.loading = false;
    });
  }
}

export const cartStore = new CartStore();
