/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ColorPickerContainer, ColorPickerContent, ColorPickerText } from "../../../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { appStore } from "../../../../../stores/appStore";
import { Label } from "../../../../../components/v2/Text/Label";
import { generateTestId } from "../../../../../util_code/testUtil";

const colorOptions = ["#b39e92", "#939bb3", "#9fb392", "#a79cb5", "#b59dae", "#c3c3c3"];

export const PosterColorV2 = observer(() => {
  const { currentPoster } = appStore;
  const { setArtistFontColor, artistFontColor } = currentPoster;

  const colorSelectionOnClick = (colorCode) => {
    setArtistFontColor(colorCode);
  };

  return (
    <>
      <Label>Colorway</Label>
      <ColorPickersContainer>
        {colorOptions.map((option => (
          <ColorPickerOption>
            <StyledColorPickerContainer data-explorator_test_id={generateTestId("single-track-wizard", `color-button-${option}`)} $active={option === artistFontColor} onClick={() => colorSelectionOnClick(option)}>
              <StyledColorPickerContent $backgroundColor={option} />
            </StyledColorPickerContainer>
          </ColorPickerOption>
        )))}
      </ColorPickersContainer>
    </>
  );
});

const StyledColorPickerContent = styled(ColorPickerContent)`
`;

const ColorPickersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    row-gap: 1rem;
    width: 90%;
    margin: auto;
    margin-bottom: 1.5rem;
`;

const StyledColorPickerContainer = styled(ColorPickerContainer)`
  && {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    padding: 0;
    margin: auto;
  }
`;

const ColorPickerOption = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  margin: auto;
  /* margin: 0 1rem; */
`;
