import { FrameChoicePricingTypes } from "../stores/posterStore";

export const getValueToDisplay = (value, valueType, hideValue) => {
  if (hideValue) return "\u200B"; // return invisible character to maintain spacing
  if (value) return value;
  switch (valueType) {
  case "song-name":
    return "Name of Song";
  case "artist":
    return "Name of Artist";
  case "lon":
    return "38.49300";
  case "lat":
    return "-122.58930";
  case "location":
    return "Parliament Sq, London SW1, UK";
  case "duration":
    return "2:43";
  case "description":
    return "John and Sally's First Dance";
  case "date":
    return "March 8, 2023 // 12:00 pm";
  default:
    return "";
  }
};

export const frameChoiceToLabel = (frameChoice) => {
  const frameChoiceValue = FrameChoicePricingTypes.find(frameChoiceEle => frameChoice === frameChoiceEle.name);
  if (frameChoiceValue) return frameChoiceValue.label;
  return "error";
};
