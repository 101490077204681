import React from "react";
import styled from "styled-components";
import { BaseText } from "./BaseText";

const StyledTitle = styled(BaseText)`
    && {
        font-size: 2.25rem;
        font-weight: bolder;
    }
`;

export const Title = (props) => {
  const { children } = props;
  return (
    <StyledTitle level="h1" {...props}>
      {children}
    </StyledTitle>
  );
};
