import React, { useMemo } from "react";
import styled from "styled-components";
import { Label } from "../Text/Label";
import { generateTestId } from "../../../util_code/testUtil";

// options = { aspectRatio: string, value: any, label: string }[]
export const PosterFrameInputV2 = ({ options, value, onChange }) => {
  const PosterFrameLabel = useMemo(() => {
    const selectedOption = options.find(option => option.value === value);
    if (selectedOption) return selectedOption.value;
    return "";
  }, [value, options]);

  return (
    <>
      <FrameLabel>
        Frame:
        {" "}
        <span>{PosterFrameLabel}</span>
      </FrameLabel>
      <StyledPosterSizeInputV2>
        <PosterSizeInputV2Container>
          {
            options.map(option => (
              <PosterSizeOption data-explorator_test_id={generateTestId("general-wizard", `${option.value}-frame-button`)} $numberOfOptions={options.length} onClick={() => onChange(option.value)} $active={value === option.value}>
                <PosterSizeOptionExample $active={value === option.value} src={option.image} />
              </PosterSizeOption>
            ))
          }
        </PosterSizeInputV2Container>
        {/* <DividerLine />
        <PosterSizeOptionText>{options.find(option => option.value === value).label}</PosterSizeOptionText> */}
      </StyledPosterSizeInputV2>
    </>
  );
};

const DividerLine = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 1rem;
  background-color: black;
`;

const PosterSizeInputV2Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledPosterSizeInputV2 = styled.div`

`;

const PosterSizeOption = styled.div`
    width: ${({ $numberOfOptions }) => 100 / $numberOfOptions}%;
    margin-top: auto;
    ${({ $active }) => !$active && "opacity: 0.75;"};
    cursor: pointer;
`;

const PosterSizeOptionExample = styled.img`
    margin: auto;
    margin-top: auto;
    margin-bottom: 0;
    display: block;
    object-fit: cover;
    border: solid 2px transparent;
    width: 95%;
    ${({ $active }) => $active && "border-color: black"};
`;

const PosterSizeOptionText = styled(Label)`
    && {
        margin-top: 0.5rem;
        text-align: center;
    }
`;

const FrameLabel = styled(Label)`
    && {
        margin-bottom: 1.5rem;
        @media ${({ theme }) => theme.devicesMax.sm} { 
          margin-top: 2rem;
        }
        font-weight: normal;
        && span {
          font-weight: bolder;
          font-size: inherit;
        }
    }
`;
