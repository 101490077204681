import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { getValueToDisplay } from "../../util_code/displayUtility";
import { appStore } from "../../stores/appStore";

import characterOne from "./DurationCharacters/1.svg";
import character2 from "./DurationCharacters/2.svg";
import character3 from "./DurationCharacters/3.svg";
import character4 from "./DurationCharacters/4.svg";
import character5 from "./DurationCharacters/5.svg";
import character6 from "./DurationCharacters/6.svg";
import character7 from "./DurationCharacters/7.svg";
import character8 from "./DurationCharacters/8.svg";
import character9 from "./DurationCharacters/9.svg";
import character0 from "./DurationCharacters/0.svg";
import characterColon from "./DurationCharacters/colon.svg";

const displayDurationCharacter = (character) => {
  switch (character) {
  case "0":
    return character0;
  case "1":
    return characterOne;
  case "2":
    return character2;
  case "3":
    return character3;
  case "4":
    return character4;
  case "5":
    return character5;
  case "6":
    return character6;
  case "7":
    return character7;
  case "8":
    return character8;
  case "9":
    return character9;
  case ":":
    return characterColon;
  default:
    return characterColon;
  }
};

const getDurationCharacterStyle = (character, headlessMode) => {
  if (headlessMode) {
    if (character === ":") return { marginRight: "-2.8px", marginLeft: "-2.8px" };
    if (character === "1") return { marginRight: "-1.5px", marginLeft: "-1.5px" };
    return { marginRight: "-0.5px", marginLeft: "-0.5px" };
  }
  if (character === ":") return { marginRight: "-2.5px", marginLeft: "-2.5px" };
  if (character === "1") return { marginRight: "-1px", marginLeft: "-1px" };
  return { marginRight: "-0.7px", marginLeft: "-0.7px" };
};

export const SingleSongDurationSVG = observer(() => {
  const { currentPoster, headlessMode } = appStore;
  const {
    songDuration,
  } = currentPoster;
  const durationContainerRef = useRef(null);

  if (!songDuration) return <></>;
  return (
    <PosterDurationContainer ref={durationContainerRef}>
      {Array.from(getValueToDisplay(songDuration, "duration")).map((char) => <StyledDurationCharacter style={getDurationCharacterStyle(char, headlessMode)} src={displayDurationCharacter(char)} />)}
    </PosterDurationContainer>
  );
});

const durationHeight = "15px";

const PosterDurationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.9px solid #000;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  padding-left: 1px;
  padding-right: 1px;
  height: ${durationHeight};
`;

const StyledDurationCharacter = styled.img`
    margin: auto 0px;
    height: 75%;
    box-sizing: content-box;
    aspect-ratio: 1/1;
`;
