/* eslint-disable no-await-in-loop */
import axios from "axios";
import { appStore } from "../stores/appStore";
import { getRandomInt, sleep } from "./misc";

const retryAttempts = 4;

export const getSingleTrackWaveformV2 = async (trackId) => {
  const { backendFunctionUrl } = appStore;
  for (let attempt = 0; attempt < retryAttempts; attempt++) {
    try {
      const getWaveFormRes = await axios.post(`${backendFunctionUrl}/get_single_track_waveform_v2`, {
        track_id: trackId,
      });
      if (getWaveFormRes.data.trackLevels) return getWaveFormRes;
    } catch (err) {
      console.log("Failed to get single track attempt: ", attempt);
    }
    await sleep(250 + getRandomInt(0, 500));
  }
  console.log("Failed to get single track from all attempts");
  return { error: true, data: {} };
};
