import React from "react";
import { SpinnerContainer, Logo, StyledSpinner } from "./LogoLoadingSpinnerStyles";
import SpinnerImage from "../../images/WR-loading-spinner.png";

const LogoLoadingSpinner = () => (
  <SpinnerContainer>
    <StyledSpinner variant="light" animation="border" />
    <Logo src={SpinnerImage} alt="wavroom logo" />
  </SpinnerContainer>
);

export default LogoLoadingSpinner;
