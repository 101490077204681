import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";

import { checkIfCanGoToNextStep, wizardStore } from "../../../stores/wizardStore";
import { Paragraph } from "../Text/Paragraph";
import { useIsMobile } from "../../../hooks/isMobile";
import GoBackIconSrc from "../../../images/WeirdSideArrow.svg";
import CartIconSrc from "../../../images/shopping-cart.svg";
import { appStore } from "../../../stores/appStore";
import { Label } from "../Text/Label";
import { cartStore } from "../../../stores/cartStore";
import { generateTestId } from "../../../util_code/testUtil";

const steps = [
  {
    step: 1,
    title: "Choose an Album or Artist"
  },
  {
    step: 2,
    title: "Create Album Description"
  },
  {
    step: 3,
    title: "Customize Poster"
  },
  {
    step: 4,
    title: "Select Size and Frame"
  },
];

export const StepTitleDisplayV2 = observer(() => {
  const {
    step,
    nextStepOnClick,
    previousStepOnClick,
  } = wizardStore;

  const { currentPoster, throwErrorMessage } = appStore;

  const { addPosterToCart } = cartStore;

  const isMobile = useIsMobile();

  const titleText = useMemo(() => {
    const stepToDisplay = steps.find(stepEle => stepEle.step === step);
    if (stepToDisplay) return stepToDisplay.title;
    return "error";
  }, []);

  const nextStepDisabled = checkIfCanGoToNextStep(step);

  const nextStepText = useMemo(() => {
    if (step === 1 && !nextStepDisabled) return "Next";
    if (step === 4) return "Cart";
    return undefined;
  }, [step, nextStepDisabled]);

  const history = useHistory();

  const addToCart = () => {
    const canGoToNextStepError = checkIfCanGoToNextStep(step);
    if (canGoToNextStepError) {
      throwErrorMessage(canGoToNextStepError);
      return;
    }
    addPosterToCart(currentPoster);
    history.push("/cart");
  };

  const onNextStepClicked = () => {
    if (step === 4) {
      addToCart();
      return;
    }
    nextStepOnClick();
  };

  return (
    <StepTitleContent>
      {
        isMobile && <DragButton />
      }
      <StepTitleTextContent>
        {
          (isMobile && step > 1)
            ? <ChangeStepButtonNew onClick={() => previousStepOnClick()}><ArrowIcon $backwardsArrow src={GoBackIconSrc} /></ChangeStepButtonNew>
            : <div />
        }
        <StyledStepTitle data-explorator_test_id={generateTestId("general-wizard", `title-step-${step}`)}>
          {step}
          .
          {" "}
          {titleText}
        </StyledStepTitle>
        {isMobile ? (
          <>
            <ChangeStepButtonNew onClick={() => onNextStepClicked()} $disabled={nextStepDisabled}>
              {nextStepText ? <StyledLabel $disabled={nextStepDisabled}>{nextStepText}</StyledLabel> : <></>}
              {step === 4 ? <CartIcon src={CartIconSrc} $disabled={nextStepDisabled} /> : <ArrowIcon $disabled={nextStepDisabled} src={GoBackIconSrc} />}
            </ChangeStepButtonNew>
          </>
        ) : <div />}
      </StepTitleTextContent>
    </StepTitleContent>

  );
});

const DragButton = styled.div`
  width: 7rem;
  height: 0.75rem;
  background-color: #F1F1F1;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-top: 0rem;
  margin-bottom: 1.3rem;
`;

const StyledLabel = styled(Label)`
  && {
    margin: auto;
    margin-bottom: 0.2rem;
    margin-right: 1rem;
    ${({ $disabled }) => !$disabled && "color: white"};
  }
`;

const ChangeStepButtonNew = styled.div`
  display: flex;
  justify-space: between;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  height: fit-content;
  margin: auto 0;
  ${({ $disabled, theme }) => $disabled ? `
    border: solid ${theme.color.gray} thin;
    background-color: transparent;
  `
    : `
    border: solid ${theme.color.black} thin;
    background-color: ${theme.color.black};
  `}
`;

const ChangeStepIconSharedStyle = css`
  width: auto;
  ${({ $backwardsArrow }) => $backwardsArrow && "transform: scaleX(-1)"};
  ${({ $disabled }) => $disabled ? "opacity: 0.2" : "filter: invert(1)"};
`;

const ArrowIcon = styled.img`
  height: 2rem;
  margin: auto;
  ${ChangeStepIconSharedStyle};
`;

const CartIcon = styled.img`
  height: 2rem;
  margin: auto;
  ${ChangeStepIconSharedStyle};
`;

const StepTitleContent = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  @media ${({ theme }) => theme.devicesMax.md} {
    cursor: pointer;
  }
`;

const StepTitleTextContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.devicesMax.md} {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledStepTitle = styled(Paragraph)`
    && {
        text-align: center;
        color: ${({ theme }) => theme.color.darkGray};
        font-size: 16px;
        font-weight: 500;
        margin: auto 0;
    }
`;
