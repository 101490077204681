import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import queryString from "query-string";

import {
  PosterTracksContainer,
  PosterTracksWrapper,
  PosterPreviewContainer,
  PosterContent,
  HardwoodBackgroundWrapper,
  HorizontalPoster,
  PosterArtistName,
  PosterSongTitle,
  PosterHeaderContainer,
  PosterFooterContainer,
  PosterDescriptionText,
  PosterHeaderTextContainer,
} from "./SingleSongPosterEditorStyles";
import LogoLoadingSpinner from "../LogoLoadingSpinner/LogoLoadingSpinner";
import CanvasWaveForm from "../CanvasWaveForm";
import { appStore } from "../../stores/appStore";
import { AutoRescaleContainer } from "../AutoRescaleContainer";
import { getValueToDisplay } from "../../util_code/displayUtility";
import { SingleSongDurationSVG } from "./SingeSongDurationSVG";
import { getPosterImageId } from "../../hooks/getPosterImageId";
import { generateTestId } from "../../util_code/testUtil";

const QRCodeContainer = styled.div`
  position: absolute;
  top: 10px;
  left: calc(32px + 1.5%);
`;

// TODO conside storing sepertly as that would be a cleaner solution
const getDateTimeDisplay = (value, hideDate, hideTime) => {
  const valueWithDefaultIfNeeded = getValueToDisplay(value, "date");
  if (hideDate && hideTime) return "\u200B";
  if (!hideDate && !hideTime) return valueWithDefaultIfNeeded;

  if (hideDate) return valueWithDefaultIfNeeded.split("//")[1];
  return valueWithDefaultIfNeeded.split("//")[0];
};

export const SingleSongPosterEditor = observer(({ addWatermark, posterOnUpdate, albumContainerRef, currentPoster, useLocalState }) => {
  const posterRef = useRef();
  const artistTextRef = useRef();
  const [artistFontHeight, setArtistFontHeight] = useState(0);

  const { headlessMode, frontEndUrl } = appStore;
  const posterImageId = getPosterImageId(headlessMode);

  const {
    song: selectedSong,
    loading,
    customDescription,
    frameChoice,
    customDatetime,
    customLocation,
    customLatLng,
    posterSize,
    artistFontColor = "#b19d91",
    artistFontSize,
    titleFontSize,
    loadingSoundWave,
    hideLocation,
    hideDate, // TODO
    hideTime, // TODO
    hideDescription,
    songTrackLevel,
    songMessage,
    artist,
    songName,
  } = currentPoster;

  const [ranPosterUpdate, setRanPosterUpdate] = useState(false);

  const renderFrameColor = () => {
    switch (frameChoice) {
    case "Black Gallery Frame":
      return "#000";
    case "White Gallery Frame":
      return "#F8F8F8";
    case "Natural Gallery Frame":
      return "#e6cfaa";
    default:
      return undefined;
    }
  };

  // Generate poster image if in headless mode
  useEffect(() => {
    if (ranPosterUpdate) return;
    if (posterOnUpdate && songTrackLevel && headlessMode) {
      setRanPosterUpdate(true);
      posterOnUpdate(posterRef.current);
    }
  }, [posterOnUpdate, songTrackLevel, headlessMode]);

  const changeArtistFontHeight = (newSize) => {
    setArtistFontHeight(newSize);
  };

  useEffect(() => {
    if (!artistTextRef.current) return {};
    const resizeObserver = new ResizeObserver(() => {
      if (artistTextRef.current && artistFontHeight !== artistTextRef.current.clientHeight) changeArtistFontHeight(artistTextRef.current.clientHeight);
    });
    resizeObserver.observe(artistTextRef.current);
    return () => {
      resizeObserver.disconnect();
    }; // clean up
  }, []);

  return (
    <PosterPreviewContainer data-explorator_test_id={generateTestId("single-song-display", "container")} style={{ height: "100%" }}>
      <HardwoodBackgroundWrapper>
        <AutoRescaleContainer addWatermark={addWatermark} useLocalState={useLocalState} containerRef={albumContainerRef} frameSize={posterSize} isSingleTrack>
          <HorizontalPoster
            ref={posterRef}
            frameColor={!headlessMode && renderFrameColor()}
            frameSize={posterSize}
            data-explorator_test_id={generateTestId("single-song-poster", "poster-frame")}
          >
            <PosterContent>
              <PosterTracksWrapper>
                {songMessage !== undefined && (
                  <QRCodeContainer>
                    <QRCodeSVG size={40} value={`${frontEndUrl}/message?poster_image_id=${posterImageId}`} />
                  </QRCodeContainer>
                )}
                <PosterHeaderContainer>
                  <PosterHeaderTextContainer
                    $artistFontHeight={artistFontHeight / 2 + 2}
                  >
                    <PosterSongTitle data-explorator_test_id={generateTestId("single-song-poster", "single-song-song-name-text")} $fontSize={titleFontSize}>
                      {getValueToDisplay(songName, "song-name")}
                    </PosterSongTitle>
                    <PosterArtistName
                      $fontSize={artistFontSize}
                      ref={artistTextRef}
                      $color={artistFontColor}
                      data-explorator_test_id={generateTestId("single-song-poster", "single-song-song-artist-text")}
                    >
                      {getValueToDisplay(artist, "artist")}
                    </PosterArtistName>
                    <SingleSongDurationSVG />
                  </PosterHeaderTextContainer>
                </PosterHeaderContainer>
                <PosterTracksContainer data-explorator_test_id={generateTestId("single-song-poster", "poster-color-background")} postercolor={`${artistFontColor}33`}>
                  {(loading || loadingSoundWave) && <LogoLoadingSpinner />}
                  {!(loading || loadingSoundWave) && (
                    <div className="wave-wrapper">
                      <CanvasWaveForm
                        waveFormData={songTrackLevel || fakeTrackLevels1}
                        highResolution
                      />
                    </div>
                  )}
                </PosterTracksContainer>
                <PosterFooterContainer data-test-id="description-date-time-container">
                  <div>
                    <PosterDescriptionText data-explorator_test_id={generateTestId("single-track-poster", "description-text")}>
                      {getValueToDisplay(customDescription, "description", hideDescription).substring(0, 33)}
                    </PosterDescriptionText>
                    <PosterDescriptionText data-explorator_test_id={generateTestId("single-track-poster", "date-text")}>
                      {getDateTimeDisplay(customDatetime, hideDate, hideTime)}
                    </PosterDescriptionText>
                  </div>
                  <div>
                    <PosterDescriptionText data-explorator_test_id={generateTestId("single-track-poster", "location-coordinates")}>
                      {getValueToDisplay(customLatLng && `${customLatLng.lat}, `, "lat", hideLocation)}
                      {getValueToDisplay(customLatLng && customLatLng.lng, "lon", hideLocation)}
                    </PosterDescriptionText>
                    <PosterDescriptionText data-explorator_test_id={generateTestId("single-track-poster", "location-city-province-country")}>
                      {getValueToDisplay(customLocation, "location", hideLocation)}
                    </PosterDescriptionText>
                  </div>
                </PosterFooterContainer>
              </PosterTracksWrapper>
            </PosterContent>
          </HorizontalPoster>
        </AutoRescaleContainer>
      </HardwoodBackgroundWrapper>
    </PosterPreviewContainer>
  );
});

// TODO remove once PR get's merged
export const fakeTrackLevels1 = [
  0.01, 0.01, 0, 0.04, 0.36, 0.36, 0.36, 0.37, 0.29, 0.29, 0.4, 0.4, 0.25, 0.15,
  0.15, 0.15, 0.3, 0.3, 0.23, 0.23, 0.23, 0.23, 0.23, 0.36, 0.36, 0.36, 0.36,
  0.36, 0.36, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43,
  0.14, 0.14, 0.42, 0.42, 0.23, 0.23, 0.23, 0.2, 0.41, 0.41, 0.26, 0.26, 0.35,
  0.28, 0.28, 0.38, 0.38, 0.38, 0.28, 0.28, 0.2, 0.32, 0.41, 0.41, 0.41, 0.27,
  0.27, 0.27, 0.29, 0.19, 0.19, 0.22, 0.22, 0.22, 0.22, 0.37, 0.31, 0.31, 0.26,
  0.26, 0.26, 0.43, 0.43, 0.3, 0.3, 0.3, 0.3, 0.49, 0.49, 0.42, 0.42, 0.51,
  0.51, 0.51, 0.44, 0.44, 0.21, 0.21, 0.17, 0.17, 0.49, 0.49, 0.49, 0.37, 0.58,
  0.58, 0.51, 0.51, 0.51, 0.56, 0.56, 0.56, 0.56, 0.57, 0.5, 0.5, 0.5, 0.45,
  0.45, 0.48, 0.48, 0.35, 0.26, 0.27, 0.27, 0.16, 0.43, 0.31, 0.33, 0.39, 0.5,
  0.65, 0.65, 0.65, 0.49, 0.49, 0.49, 0.41, 0.42, 0.42, 0.38, 0.37, 0.36, 0.4,
  0.4, 0.42, 0.42, 0.42, 0.25, 0.25, 0.25, 0.49, 0.58, 0.58, 0.58, 0.58, 0.55,
  0.49, 0.56, 0.51, 0.52, 0.52, 0.51, 0.65, 0.53, 0.53, 0.6, 0.6, 0.6, 0.47,
  0.47, 0.61, 0.61, 0.61, 0.61, 0.52, 0.52, 0.54, 0.49, 0.49, 0.53, 0.53, 0.51,
  0.56, 0.56, 0.56, 0.41, 0.41, 0.37, 0.36, 0.55, 0.55, 0.5, 0.5, 0.39, 0.32,
  0.32, 0.41, 0.41, 0.43, 0.49, 0.49, 0.49, 0.57, 0.57, 0.54, 0.54, 0.54, 0.54,
  0.39, 0.39, 0.51, 0.51, 0.57, 0.57, 0.57, 0.53, 0.53, 0.41, 0.41, 0.49, 0.59,
  0.66, 0.66, 0.67, 0.54, 0.54, 0.54, 0.36, 0.44, 0.44, 0.44, 0.44, 0.56, 0.63,
  0.63, 0.48, 0.48, 0.56, 0.56, 0.56, 0.39, 0.61, 0.53, 0.51, 0.49, 0.7, 0.7,
  0.7, 0.7, 0.7, 0.7, 0.53, 0.52, 0.54, 0.65, 0.51, 0.51, 0.48, 0.48, 0.36,
  0.52, 0.52, 0.38, 0.4, 0.53, 0.53, 0.48, 0.55, 0.55, 0.55, 0.47, 0.51, 0.51,
  0.52, 0.52, 0.52, 0.52, 0.37, 0.32, 0.52, 0.52, 0.59, 0.59, 0.59, 0.43, 0.52,
  0.53, 0.53, 0.53, 0.47, 0.47, 0.4, 0.5, 0.58, 0.54, 0.54, 0.63, 0.63, 0.63,
  0.58, 0.66, 0.59, 0.59, 0.59, 0.59, 0.59, 0.36, 0.36, 0.36, 0.36, 0.41, 0.37,
  0.5, 0.63, 0.65, 0.57, 0.54, 0.6, 0.57, 0.58, 0.61, 0.6, 0.64, 0.63, 0.63,
  0.48, 0.48, 0.41, 0.41, 0.4, 0.41, 0.45, 0.44, 0.44, 0.38, 0.38, 0.4, 0.37,
  0.46, 0.58, 0.58, 0.6, 0.6, 0.47, 0.49, 0.41, 0.41, 0.35, 0.35, 0.35, 0.4,
  0.4, 0.4, 0.4, 0.44, 0.34, 0.34, 0.33, 0.28, 0.31, 0.31, 0.31, 0.5, 0.7, 0.7,
  0.7, 0.53, 0.48, 0.62, 0.62, 0.66, 0.66, 0.62, 0.62, 0.62, 0.62, 0.5, 0.45,
  0.5, 0.5, 0.48, 0.49, 0.49, 0.49, 0.47, 0.43, 0.47, 0.47, 0.46, 0.6, 0.64,
  0.64, 0.64, 0.64, 0.64, 0.53, 0.53, 0.43, 0.45, 0.42, 0.42, 0.39, 0.42, 0.5,
  0.51, 0.51, 0.51, 0.45, 0.4, 0.4, 0.4, 0.4, 0.38, 0.38, 0.45, 0.67, 0.67,
  0.67, 0.63, 0.63, 0.75, 0.75, 0.59, 0.61, 0.65, 0.64, 0.64, 0.64, 0.6, 0.6,
  0.7, 0.7, 0.59, 0.59, 0.61, 0.64, 0.64, 0.59, 0.59, 0.55, 0.66, 0.66, 0.62,
  0.62, 0.62, 0.59, 0.59, 0.64, 0.64, 0.64, 0.72, 0.72, 0.67, 0.67, 0.67, 0.67,
  0.74, 0.74, 0.76, 0.73, 0.73, 0.82, 0.82, 0.71, 0.71, 0.74, 0.77, 0.79, 0.67,
  0.65, 0.77, 0.83, 0.83, 0.74, 0.74, 0.75, 0.76, 0.76, 0.76, 0.76, 0.76, 0.76,
  0.77, 0.73, 0.73, 0.74, 0.74, 0.76, 0.72, 0.75, 0.76, 0.76, 0.76, 0.76, 0.73,
  0.69, 0.72, 0.82, 0.8, 0.85, 0.85, 0.84, 0.78, 0.77, 0.77, 0.77, 0.89, 0.89,
  0.85, 0.82, 0.82, 0.83, 0.83, 0.88, 0.88, 0.88, 0.78, 0.72, 0.73, 0.66, 0.66,
  0.66, 0.72, 0.8, 0.8, 0.7, 0.75, 0.76, 0.81, 0.81, 0.82, 0.88, 0.88, 0.9,
  0.91, 0.91, 0.91, 0.91, 0.93, 0.93, 0.93, 0.93, 0.92, 0.96, 0.95, 0.95, 0.95,
  0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.93, 0.93, 0.93, 0.93,
  0.93, 0.95, 0.95, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.86, 0.86,
  0.86, 0.9, 0.9, 0.83, 0.83, 0.85, 0.89, 0.89, 0.89, 0.89, 0.89, 0.97, 0.97,
  0.95, 0.97, 0.95, 0.95, 0.93, 0.93, 0.96, 0.96, 0.98, 1, 1, 0.93, 0.94, 0.94,
  0.94, 0.94, 0.93, 0.93, 0.95, 0.95, 0.95, 0.93, 0.92, 0.95, 0.94, 0.96, 0.96,
  0.93, 0.93, 0.94, 0.92, 0.92, 0.92, 0.91, 0.91, 0.91, 0.91, 0.78, 0.78, 0.78,
  0.78, 0.78, 0.74, 0.74, 0.74, 0.74, 0.67, 0.69, 0.69, 0.69, 0.65, 0.65, 0.67,
  0.65, 0.77, 0.81, 0.81, 0.81, 0.81, 0.7, 0.77, 0.77, 0.77, 0.77, 0.71, 0.71,
  0.71, 0.71, 0.66, 0.66, 0.66, 0.68, 0.67, 0.68, 0.68, 0.73, 0.87, 0.81, 0.81,
  0.81, 0.79, 0.87, 0.87, 0.87, 0.81, 0.79, 0.85, 0.85, 0.85, 0.85, 0.85, 0.78,
  0.81, 0.8, 0.82, 0.83, 0.82, 0.82, 0.82, 0.82, 0.78, 0.79, 0.79, 0.79, 0.77,
  0.77, 0.77, 0.92, 0.9, 0.94, 0.94, 0.94, 0.94, 0.92, 0.92, 0.9, 0.92, 0.92,
  0.92, 0.89, 0.89, 0.89, 0.91, 0.91, 0.91, 0.88, 0.89, 0.91, 0.91, 0.91, 0.91,
  0.91, 0.93, 0.93, 0.9, 0.9, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.94,
  0.94, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.92, 0.92, 0.94, 0.94,
  0.94, 0.94, 0.88, 0.88, 0.88, 0.89, 0.91, 0.91, 0.91, 0.91, 0.91, 0.9, 0.9,
  0.91, 0.91, 0.91, 0.91, 0.91, 0.88, 0.88, 0.87, 0.87, 0.9, 0.91, 0.91, 0.89,
  0.89, 0.91, 0.91, 0.91, 0.91, 0.91, 0.88, 0.89, 0.89, 0.89, 0.89, 0.9, 0.9,
  0.89, 0.89, 0.88, 0.88, 0.87, 0.87, 0.87, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89,
  0.89, 0.89, 0.89, 0.88, 0.88, 0.88, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.9,
  0.9, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.89, 0.89, 0.89, 0.89, 0.89,
  0.89, 0.84, 0.84, 0.84, 0.84, 0.87, 0.87, 0.87, 0.88, 0.88, 0.88, 0.88, 0.88,
  0.88, 0.84, 0.84, 0.85, 0.9, 0.9, 0.9, 0.83, 0.83, 0.84, 0.84, 0.84, 0.84,
  0.86, 0.86, 0.89, 0.89, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.83, 0.83,
  0.85, 0.85, 0.84, 0.84, 0.84, 0.85, 0.85, 0.85, 0.85, 0.85, 0.82, 0.82, 0.85,
  0.85, 0.85, 0.85, 0.85, 0.85, 0.84, 0.81, 0.83, 0.79, 0.79, 0.8, 0.8, 0.76,
  0.79, 0.79, 0.8, 0.82, 0.84, 0.84, 0.83, 0.83, 0.83, 0.83, 0.83, 0.79, 0.76,
  0.76, 0.8, 0.85, 0.85, 0.85, 0.8, 0.8, 0.83, 0.83, 0.83, 0.83, 0.81, 0.84,
  0.84, 0.84, 0.82, 0.82, 0.78, 0.79, 0.84, 0.84, 0.81, 0.81, 0.77, 0.77, 0.78,
  0.81, 0.85, 0.81, 0.8, 0.8, 0.81, 0.77, 0.82, 0.77, 0.79, 0.79, 0.79, 0.77,
  0.77, 0.77, 0.71, 0.78, 0.78, 0.78, 0.76, 0.76, 0.72, 0.77, 0.67, 0.67, 0.52,
  0.43, 0.44, 0.44, 0.4, 0.4, 0.37, 0.31, 0.33, 0.28, 0.28, 0.28, 0.31, 0.31,
  0.31, 0.31, 0.17, 0.13, 0.13, 0.13, 0.13, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];
