import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useExtractColor } from "react-extract-colors";
import ColorThief from "colorthief";
import { BlockPicker } from "react-color";
import ReactGPicker from "react-gcolor-picker";
import { observer } from "mobx-react-lite";

import { Label } from "../../../../../components/v2/Text/Label";
import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../../../stores/appStore";
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside";
import { ColorPickerContainer, ColorPickerContent, ColorPickerText } from "../../../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { generateTestId } from "../../../../../util_code/testUtil";

const rgbToHex = (r, g, b) => `#${[r, g, b].map(x => {
  const hex = x.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}).join("")}`;

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const PosterColorBoxPickerMulti = observer(() => {
  const {
    currentPoster,
    logAnalyticsEvent
  } = appStore;

  const {
    setPosterColor,
    album,
    posterColor
  } = currentPoster;

  const [albumDominantColor, setAlbumDominantColor] = useState("red");

  useEffect(() => {
    if (!album.albumUrl) return;
    const colorThief = new ColorThief();
    const img = new Image();

    img.addEventListener("load", () => {
      setAlbumDominantColor(rgbToHex(...colorThief.getColor(img)));
    });

    img.crossOrigin = "Anonymous";
    img.src = album.albumUrl;
  }, [album.albumUrl]);

  const [customColorSelection, setCustomColorSelection] = useState("#d9c0bc");
  const [displayColorPickerGradient, setDisplayColorPickerGradient] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const customColorSelectorRef = useRef();
  const customColorGradientSelectorRef = useRef();

  useOnClickOutside(customColorSelectorRef, () => setDisplayColorPicker(false));
  useOnClickOutside(customColorGradientSelectorRef, () => setDisplayColorPickerGradient(false));

  const colorSelectionOnClick = (colorCode) => {
    setPosterColor(colorCode);

    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "switch_poster_color",
      label: colorCode
    });
  };

  const colorSelectionBoxOnClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const customColorOnSelect = (toColor) => {
    setPosterColor(toColor.hex);
    setCustomColorSelection(toColor.hex);
  };

  const customColorOnSelectGradient = (toColor) => {
    setPosterColor(toColor);
    setCustomColorSelection(toColor);
  };

  const colorGradientSelectionBoxOnClick = () => {
    setDisplayColorPickerGradient(!displayColorPickerGradient);
  };

  const getTextColorForColorPicker = (backgroundColor) => {
    // R*0.299 + G*0.587 + B*0.114
    const rgbValue = hexToRgb(backgroundColor);
    if (!rgbValue) return "white";
    const brightnessValue = rgbValue.r * 0.299 + rgbValue.g * 0.587 + rgbValue.b * 0.114;
    return brightnessValue > 186 ? "black" : "white";
  };

  const customBackgroundColor = !customColorSelection.includes("gradient") ? customColorSelection : "#DADADA";
  const gradientBackgroundColor = customColorSelection.includes("gradient") ? customColorSelection : "#DADADA";

  // TODO default color is none of the options. Is it okay none are selected when they get to this screen? Should it be the neutral color?
  return (
    <>
      <Label>Poster Box Color</Label>
      <ColorPickersContainer>
        <ColorPickerContainer $active={albumDominantColor === posterColor} onClick={() => colorSelectionOnClick(albumDominantColor)}>
          <ColorPickerContent $backgroundColor={albumDominantColor}>
            <ColorPickerText $color={getTextColorForColorPicker(albumDominantColor)}>Album</ColorPickerText>
          </ColorPickerContent>
        </ColorPickerContainer>

        <ColorPickerContainer $active={!customColorSelection.includes("gradient") && posterColor === customColorSelection} onClick={colorSelectionBoxOnClick}>
          <ColorPickerContent $backgroundColor={customBackgroundColor}>
            <ColorPickerText $color={getTextColorForColorPicker(customBackgroundColor)}>Custom</ColorPickerText>
          </ColorPickerContent>
        </ColorPickerContainer>

        <ColorPickerContainer $active={customColorSelection.includes("gradient") && posterColor === customColorSelection} onClick={colorGradientSelectionBoxOnClick}>
          <ColorPickerContent $backgroundColor={gradientBackgroundColor}>
            <ColorPickerTint>
              <ColorPickerText $color="white">Gradient</ColorPickerText>
            </ColorPickerTint>
          </ColorPickerContent>
        </ColorPickerContainer>
        <ColorPickerContainer data-explorator_test_id={generateTestId("multi-track-wizard", "poster-color-neutral-button")} $active={posterColor === "#DADADA"} onClick={() => colorSelectionOnClick("#DADADA")}>
          <ColorPickerContent $backgroundColor="#DADADA">
            <ColorPickerText $color={getTextColorForColorPicker("#DADADA")}>Neutral</ColorPickerText>
          </ColorPickerContent>
        </ColorPickerContainer>
        {
          displayColorPicker
          && (
            <CustomColorSelectionContainer ref={customColorSelectorRef}>
              <BlockPicker triangle="hide" onChange={customColorOnSelect} />
            </CustomColorSelectionContainer>
          )
        }
        {
          displayColorPickerGradient
          && (
            <CustomColorSelectionContainer ref={customColorGradientSelectorRef}>
              <ReactGPicker
                gradient
                solid={false}
                format="hex"
                showAlpha={false}
                showInputs={false}
                debounceMS={150}
                onChange={customColorOnSelectGradient}
              />
            </CustomColorSelectionContainer>
          )
        }
      </ColorPickersContainer>
    </>
  );
});

const ColorPickersContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CustomColorSelectionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
`;

const ColorPickerTint = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  border-radius: 5px;
`;
