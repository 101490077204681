import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: AvenirNextLTPro-Regular;
  }

  // Below is only a temporary fix for the Flatpickr date picker
  // The root cause is the global font-size has set to 62.5% from the selector above
  .flatpickr-calendar * {
    font-size: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: #e5e4e2;
  }
`;

export const darkTheme = {
  body: "#222",
  text: "#fff",
};

export const lightTheme = {
  body: "#FFF",
  text: "#222",
};

export const centerAligned = {
  text: "center",
};

export const leftAligned = {
  text: "start",
};

export default GlobalStyle;
