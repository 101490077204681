import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ANALYTICS_SINGLE_TRACK_LABEL, appStore } from "../../../stores/appStore";
import { wizardStore } from "../../../stores/wizardStore";
import { SingleSearchWizardV2 } from "./SingleTrackWizardStepper/SingleWizardStepOne/SingleWizardStepOne";
import { StepTitleDisplayV2 } from "../../../components/v2/EditorSharedComponents/StepTitleDisplay";
import { StepperContainer, WizardStepperContainer } from "../../../components/v2/EditorSharedComponents/EditorSharedStyle";
import { SingleWizardLocationStep2 } from "./SingleTrackWizardStepper/SingleWizardLocationStep2";
import { SingleWizardStep3 } from "./SingleTrackWizardStepper/SingleWizardStep3";
import { SingleWizardStep4 } from "./SingleTrackWizardStepper/SingleWizardStep4/SingleWizardStep4";
import { StepNavigationBarV2 } from "../../../components/v2/EditorSharedComponents/StepNavigationBar";

export const SingleSongWizardStepperV2 = observer(({ editorStepsSectionRef }) => {
  const {
    logAnalyticsEvent,
  } = appStore;

  const {
    step,
    nextStepOnClick,
    previousStepOnClick
  } = wizardStore;

  const stepActions = {
    nextStep: nextStepOnClick,
    prevStep: previousStepOnClick
  };

  const renderWizard = useCallback(() => {
    switch (step) {
    case 1:
      return <SingleSearchWizardV2 {...stepActions} />;
    case 2:
      logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep2",
      });

      return (
        <SingleWizardLocationStep2 />
      );
    case 3:
      logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep3",
      });

      return (
        <SingleWizardStep3 />
      );
    case 4:
      logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
        category: "pageLanding",
        action: "reachedStep4",
      });

      return <SingleWizardStep4 />;
    default:
      return null;
    }
  }, [step]);

  return (
    <WizardStepperContainer ref={editorStepsSectionRef || undefined}>
      <StepTitleDisplayV2 />
      <StepperContainer>
        <StepperContent>
          {renderWizard()}
        </StepperContent>
      </StepperContainer>
      <StepNavigationBarV2 />
    </WizardStepperContainer>
  );
});

const StepperContent = styled.div`
`;
