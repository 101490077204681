import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const StyledBaseText = styled(Typography)`
    && {
        font-family: "Plus Jakarta Sans", sans-serif;
        color: ${({ theme }) => theme.color.black};
    }
`;

export const BaseText = (props) => {
  const { children } = props;
  return (
    <StyledBaseText {...props}>
      {children}
    </StyledBaseText>
  );
};
