import React from "react";
import { generateTestId } from "../../util_code/testUtil";
import { appStore } from "../../stores/appStore";

export const HiddenTestingInput = () => {
  const { currentPoster } = appStore;

  return (
    <input
      style={{ opacity: 0, position: "absolute", width: "1px", height: "1px" }}
      data-explorator_test_id={generateTestId("testing", "hidden-testing-input")}
      onChange={() => {
        currentPoster.setIsTesting(true);
      }}
    />
  );
};
