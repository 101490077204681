import { makeAutoObservable, runInAction } from "mobx";
import LogRocket from "logrocket";
import SpotifyWebApi from "spotify-web-api-node";
import axios from "axios";
import posthog from "posthog-js";
import queryString from "query-string";

import { PosterStore } from "./posterStore";
import { wizardStore } from "./wizardStore";
import { notificationStore } from "./notficationStore";

// KEEP THIS AND THE VARIABLE IN functions/index.js IN SYNC!
// TODO have better solution then strongly worded comment
// The setter represents a setter function in PosterStore (MAKE SURE TO ADD SETTER AND VARIABLE OF COURSE)
export const POSTER_PARAMETERS = [
  { key: "id" },
  { key: "isSingleTrackPoster", setter: "setterIsSingleTrackPoster" },
  { key: "isTesting", setter: "setterIsTesting" },
  { key: "trackId", setter: "setterTrackId", isSingleTrack: true },
  {
    key: "songTrackLevel",
    setter: "setterSongTrackLevel",
    isSingleTrack: true,
  }, // TODO add other only single track fields
  { key: "songDuration", setter: "setterSongDuration", isSingleTrack: true },
  { key: "songMessage", setter: "setterSongMessage" },
  { key: "songName", setter: "setterSongName", isSingleTrack: true },
  { key: "artist", setter: "setterArtist" },
  { key: "posterTitle", setter: "setterAlbumTitle" },
  { key: "description", setter: "setterDescription" },
  { key: "descriptionType", setter: "setterDescriptionType" },
  { key: "customDescription", setter: "setterCustomDescription" },
  { key: "posterColor", setter: "setterPosterColor" },
  { key: "alignment", setter: "setterAlbumAlignment" },
  { key: "theme", setter: "setterTheme" },
  { key: "posterSize", setter: "setterPosterSize" },
  { key: "frameChoice", setter: "setterFrameChoice" },
  { key: "soundWaveTheme", setter: "setterSoundWaveTheme" },
  { key: "trackTheme", setter: "setterTrackTheme" },
  { key: "customLocation", setter: "setterCustomLocation" },
  { key: "customLatLng", setter: "setterCustomLatLng" },
  { key: "customDatetime", setter: "setterCustomDatetime" },
  { key: "artistFontColor", setter: "setterArtistFontColor" },
  { key: "titleFontSize", setter: "setterTitleFontSize" },
  { key: "artistFontSize", setter: "setterArtistFontSize" },
  { key: "albumTitleFontSize", setter: "setterAlbumTitleFontSize" },
  { key: "hideLocation", setter: "setterHideLocation" },
  { key: "hideDescription", setter: "setterHideDescription" },
  { key: "hideDate", setter: "setterHideDate" },
  { key: "hideTime", setter: "setterHideTime" },
];

export const ANALYTICS_SINGLE_TRACK_LABEL = "single_track";
export const ANALYTICS_MULTI_TRACK_LABEL = "multi_track";
export const ANALYTICS_EXCEPTION = "single_track";

export class AppStore {
  constructor() {
    // Flip this toggle when single poster is ready
    this.disableSingleTrackPoster = false;
    this.showPreviewModal = false;

    this.isProduction = false;
    this.isStaging = false;
    this.isDev = false;
    this.spotifyClientId = null;
    this.frontEndUrl = "";
    this.backendFunctionUrl = "";
    this.spotifyApi = "";
    this.sidebarOpened = false;
    this.analytics = null;
    this.headlessMode = false;
    this.isSidebarOpen = false;

    this.cart = [];
    this.currentPoster = new PosterStore();

    if (window.location.host === "custom.thewavroom.com") {
      this.analytics = posthog.init("phc_Lxin59z9kpdlCVSihig3XikWsx4cGMHMXlz6VHs1lQ0", { api_host: "https://us.i.posthog.com" });
    }

    makeAutoObservable(this, {}, { autoBind: true });
  }

  initialize() {
    this.identifyBackendUrl();
    this.authenticateSpotifyApi();
  }

  setShowPreviewModal(newValue) {
    this.showPreviewModal = newValue;
  }

  clearPosterSelections() {
    this.currentPoster = new PosterStore();
    wizardStore.resetStepCount();
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  identifyBackendUrl() {
    LogRocket.init("gxnqpq/wavroom");

    switch (window.location.host) {
    case "localhost:3000":
      this.isDev = true;
      this.spotifyClientId = "23306b2aeafd40148586ecd1234a434a";
      this.frontEndUrl = "http://localhost:3000";
      this.backendFunctionUrl = "http://localhost:5001/wavroom-staging/us-central1";
      break;

    case "wavroom-staging.web.app":
      this.isStaging = true;
      this.spotifyClientId = "23306b2aeafd40148586ecd1234a434a";
      this.frontEndUrl = "https://wavroom-staging.web.app";
      this.backendFunctionUrl = "https://us-central1-wavroom-staging.cloudfunctions.net";
      break;

    case "custom.thewavroom.com":
      this.isProduction = true;
      this.spotifyClientId = "23306b2aeafd40148586ecd1234a434a";
      this.frontEndUrl = "https://custom.thewavroom.com";
      this.backendFunctionUrl = "https://us-central1-wavroom-286ee.cloudfunctions.net";
      break;

    default:
      this.isDev = true;
      this.spotifyClientId = "23306b2aeafd40148586ecd1234a434a";
      this.frontEndUrl = "http://localhost:3000";
      this.backendFunctionUrl = "http://localhost:5001/wavroom-staging/us-central1";
      break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  throwErrorMessage(errorMessage) {
    notificationStore.showError(errorMessage);
    this.logAnalyticsEvent(ANALYTICS_EXCEPTION, {
      description: errorMessage,
      fatal: false,
    });
  }

  logAnalyticsEvent(label, object) {
    if (!this.analytics) return;
    this.analytics.capture(label, object);
  }

  async authenticateSpotifyApi() {
    const spotifyAPI = new SpotifyWebApi();

    const response = await axios.get(`${this.backendFunctionUrl}/get_token`);
    spotifyAPI.setAccessToken(response.data.access_token);

    runInAction(() => {
      this.spotifyApi = spotifyAPI;
    });
  }

  enableHeadlessMode() {
    this.headlessMode = true;
  }

  // Private helper functions
  async populateHeadlessPosterConfigs(spotifyInstance) {
    const parsedParams = queryString.parse(window.location.search);
    const { posterImageId } = parsedParams;

    const posterConfigs = await this.fetchPosterConfigs(posterImageId);

    const {
      isSingleTrackPoster,
      id,
    } = posterConfigs.data;

    const posterData = posterConfigs.data;

    const poster = this.currentPoster;

    POSTER_PARAMETERS.forEach((posterParameter) => {
      if (!poster.isSingleTrackPoster && posterParameter.isSingleTrack) return;
      if (posterData[posterParameter.key]) {
        if (!poster.isSingleTrackPoster && posterParameter.isSingleTrack) return;
        poster[posterParameter.setter] = posterData[posterParameter.key];
      }
    });

    // getting data from shopify and setting nesscary values
    if (!isSingleTrackPoster) {
      poster.setIsSingleTrackPoster(false);
      const searchResult = await spotifyInstance.getAlbum(id);
      const album = searchResult.body;

      // Update album object structure to match the response from search API
      album.title = album.name;
      poster.setAlbum(album);
      await poster.fetchAudioAnalysisMultiTrack();
    }
  }

  async fetchPosterConfigs(posterImageId) {
    return axios.post(`${this.backendFunctionUrl}/get_poster_parameters`, {
      poster_image_id: posterImageId,
    });
  }
}

export const appStore = new AppStore();
