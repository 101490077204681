import React from "react";
import styled from "styled-components";
import { InputText } from "../Text/InputText";
import { generateTestId } from "../../../util_code/testUtil";

const Container = styled.div`
    border: black solid thin;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 44px;
    width: 100%;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
`;

const SideOfBooleanInputContainer = styled.div`
    background-color: ${({ $active, theme }) => $active ? theme.color.black : theme.color.gray};
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: 0.15s linear;
    ${({ $side, theme }) => $side === "left"
    ? `
        border-top-left-radius: ${theme.defaultBorderRadius};
        border-bottom-left-radius: ${theme.defaultBorderRadius};
      `
    : `
        border-top-right-radius: ${theme.defaultBorderRadius};
        border-bottom-right-radius: ${theme.defaultBorderRadius};
      `
};
${({ $bothInputsFalse }) => $bothInputsFalse && "border-right: solid thin black"};
`;

const SideOfBooleanInputText = styled(InputText)`
    && {
      color: ${({ $active, theme }) => $active ? theme.color.white : theme.color.black};
      transition: 0.15s linear;
      height: fit-content;
      text-align: center;
      margin: auto;
      font-weight: 500;
    }
`;

/*
    optionA: { label: string, value: any }
    optionB: { label: string, value: any }
    onChange: (new_value) => {}
    value: any
*/
export const BooleanInput = ({ className, optionA, optionB, value, onChange }) => {
  return (
    <Container className={className}>
      <SideOfBooleanInputContainer data-explorator_test_id={optionB.testId} $bothInputsFalse $side="left" onClick={() => onChange(optionB.value)} $active={optionB.value === value}>
        <SideOfBooleanInputText $active={optionB.value === value}>{optionB.label}</SideOfBooleanInputText>
      </SideOfBooleanInputContainer>
      <SideOfBooleanInputContainer data-explorator_test_id={optionA.testId} $side="right" onClick={() => onChange(optionA.value)} $active={optionA.value === value}>
        <SideOfBooleanInputText $active={optionA.value === value}>{optionA.label}</SideOfBooleanInputText>
      </SideOfBooleanInputContainer>
    </Container>
  );
};
