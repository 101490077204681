/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../../../stores/appStore";
import { PosterColorBoxPickerMulti } from "./PosterColorBoxPickerMulti";
import { Label } from "../../../../../components/v2/Text/Label";
import { Switch } from "../../../../../components/v2/Inputs/Switch";
import { generateTestId } from "../../../../../util_code/testUtil";
import { BooleanInput } from "../../../../../components/v2/Inputs/BooleanInput";
import { PlusMinusSelector } from "../../../../../components/v2/Inputs/PlusMinusSelector";

export const ColorAndTextOptionsWizardV2 = observer(() => {
  const {
    currentPoster,
    logAnalyticsEvent
  } = appStore;

  const {
    theme, setTheme,
    alignment, setAlbumAlignment,
    soundWaveTheme, setSoundWaveTheme,
    trackTheme, setTrackTheme,
    albumTitleFontSize,
    setAlbumTitleFontSize,
  } = currentPoster;

  const onBgChange = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "switch_theme",
      label: theme === "dark" ? "light" : "dark",
    });
  };

  const onSoundWaveThemeChange = () => {
    if (soundWaveTheme === "dark") {
      setSoundWaveTheme("light");
    } else {
      setSoundWaveTheme("dark");
    }

    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "switch_sound_wave_theme",
      label: soundWaveTheme === "dark" ? "light" : "dark",
    });
  };

  const onSoundTitleThemeChange = () => {
    if (trackTheme === "dark") {
      setTrackTheme("light");
    } else {
      setTrackTheme("dark");
    }

    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "switch_song_title_theme",
      label: trackTheme === "dark" ? "light" : "dark",
    });
  };

  const onCenterChange = () => {
    if (alignment === "start") {
      setAlbumAlignment("center");
    } else {
      setAlbumAlignment("start");
    }
  };

  return (
    <div>
      <PosterColorBoxPickerMulti />
      <AlignCenterContainer>
        <StyledLabel>Align Center</StyledLabel>
        <Switch defaultChecked={false} checked={alignment === "center"} onChange={onCenterChange} testId={generateTestId("multi-track-wizard", "alignment-switch")} />
      </AlignCenterContainer>
      <OptionRow>
        <OptionAndLabelContainer>
          <Label>Background Color</Label>
          <StyledBooleanInput value={theme !== "dark"} onChange={onBgChange} optionB={{ label: "Light", value: true }} optionA={{ label: "Dark", value: false, testId: generateTestId("multi-track-wizard", "background-color-dark") }} />
        </OptionAndLabelContainer>
        <OptionAndLabelContainer>
          <Label>Sound Wave Color</Label>
          <StyledBooleanInput value={soundWaveTheme !== "dark"} onChange={onSoundWaveThemeChange} optionB={{ label: "Light", value: true }} optionA={{ label: "Dark", value: false, testId: generateTestId("multi-track-wizard", "sound-wave-color-dark") }} />
        </OptionAndLabelContainer>
      </OptionRow>
      <OptionRow>
        <OptionAndLabelContainer>
          <Label>Song Title Color</Label>
          <StyledBooleanInput value={trackTheme !== "dark"} onChange={onSoundTitleThemeChange} optionB={{ label: "Light", value: true }} optionA={{ label: "Dark", value: false, testId: generateTestId("multi-track-wizard", "sound-title-color-dark") }} />
        </OptionAndLabelContainer>
        <OptionAndLabelContainer>
          <Label>Album Title Size</Label>
          <StyledPlusMinusSelector increaseTestId={generateTestId("multi-track-wizard", "album-title-size-increase")} decreaseTestId={generateTestId("multi-track-wizard", "album-title-size-decrease")} value={albumTitleFontSize} onChange={(value) => setAlbumTitleFontSize(value)} options={[{ label: "Small", value: "small", key: "small" }, { label: "Medium", value: "medium", key: "medium" }, { label: "Large", value: "large", key: "large" }]} />
        </OptionAndLabelContainer>
      </OptionRow>
    </div>
  );
});

const AlignCenterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
`;

const StyledLabel = styled(Label)`
  && {
    margin: auto 0;
  }
`;

const StyledBooleanInput = styled(BooleanInput)`
  && {
    margin-bottom: 1.5rem;
    @media ${({ theme }) => theme.devicesMax.md} { 
      width: 95%;
    }
  }
`;

const StyledPlusMinusSelector = styled(PlusMinusSelector)`
  && {
    @media ${({ theme }) => theme.devicesMax.md} { 
      width: 95%;
    }
  }  
`;

const OptionRow = styled.div`
  @media ${({ theme }) => theme.devicesMax.md} { 
    display: flex;
  }
`;

const OptionAndLabelContainer = styled.div`
  @media ${({ theme }) => theme.devicesMax.md} { 
    width: 50%;
  }
`;
