import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ResizableBox } from "react-resizable";

import "./reactResizableNeeded.css";
import { MultiWizardStepperV2 } from "../../../pages/v2/MultiTrackEditor/MultiWizardStepper";
import { SingleSongWizardStepperV2 } from "../../../pages/v2/SingleTrackEditor/SingleWizardStepper";
import { wizardStore } from "../../../stores/wizardStore";
import { useIsMobile } from "../../../hooks/isMobile";

export const EditorStepsSection = observer(({ singleTrackEditor }) => {
  const { mobileStepsHeight, setMobileStepsHeight, step } = wizardStore;
  const [drawerHeight, setDrawerHeight] = useState(window.innerHeight * 0.3);
  const [drawerContentHeight, setDrawerContentHeight] = useState(100);
  const isMobile = useIsMobile();

  const editorStepsSectionRef = useRef();

  const onResize = (event, { size }) => {
    if (Math.abs(mobileStepsHeight - size.height) > 1) setMobileStepsHeight(size.height);
    setDrawerHeight(size.height);
  };

  useEffect(() => {
    if (editorStepsSectionRef.current) {
      const editorStepsSectionRefHeight = editorStepsSectionRef.current.scrollHeight;
      let heightToUse;
      if (editorStepsSectionRefHeight > window.screen.availHeight * 0.7) heightToUse = window.screen.availHeight * 0.7;
      heightToUse = editorStepsSectionRefHeight;
      if (drawerHeight > heightToUse) setDrawerHeight(heightToUse);
      setDrawerContentHeight(heightToUse);
    }
  }, [editorStepsSectionRef.current, step]);

  if (isMobile) {
    return (
      <MobileStepsSection maxConstraints={[window.screen.availWidth, drawerContentHeight]} resizeHandles={["n"]} height={drawerHeight} axis="y" width={window.screen.availWidth} onResize={onResize}>
        {singleTrackEditor ? (
          <SingleSongWizardStepperV2 editorStepsSectionRef={editorStepsSectionRef} />
        ) : (
          <MultiWizardStepperV2 editorStepsSectionRef={editorStepsSectionRef} />
        )}
      </MobileStepsSection>
    );
  }
  return (
    <StepsSection style={isMobile ? { height: `${mobileStepsHeight}px` } : {}}>
      {singleTrackEditor ? (
        <SingleSongWizardStepperV2 />
      ) : (
        <MultiWizardStepperV2 />
      )}
    </StepsSection>
  );
});

const MobileStepsSection = styled(ResizableBox)``;

export const StepsSection = styled.div`
  overflow-y: auto;
  height: 100%;
  width: ${({ theme }) => 100 - theme.ALBUM_POSTER_WRAPPER_WIDTH_PERCENTAGE}%;
  display: flex;
  justify-content: center;
  @media ${({ theme }) => theme.devicesMax.md} {
    overflow-y: auto;
    max-height: auto;
    width: 100%;
  }
  @media ${({ theme }) => theme.devicesMax.sm} {
    overflow-y: auto;
    padding: 0 0.5rem;
    max-height: auto;
  }
`;
