import React, { useContext, useMemo } from "react";
import styled, { ThemeContext, css } from "styled-components";
import { FormControl, Select, MenuItem, Box, SvgIcon } from "@mui/material";
import { InputText } from "../Text/InputText";
import downIcon from "./down.svg";

const StyledDropdown = styled(FormControl)`
  display: flex;
  border: none;
  background-color: ${({ theme }) => theme.color.gray};
  && {
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    background-color: ${({ theme }) => theme.color.gray};
    display: flex;
    column-gap: 1.5rem;
  }
`;

const StyledSelect = styled(Select)`
  && {
    background-color: transparent;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    & .MuiSelect-select {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2rem;
    }
  }
`;

const StyledInputText = styled(InputText)`
    && {
      margin: auto 0;
      display: block;
      height: fit-content;
    }
`;

const DropdownIcon = styled.img`
  color: ${({ theme }) => theme.color.gray};
  user-select: none;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 0.75rem;
  top: calc(52% - 1.5rem);
  pointer-events: none;
`;

const DropDownItemImage = styled.img`
  height: 4rem;
  width: 4rem;
`;

const StyledBox = styled(Box)`
  && {
    display: flex;
    column-gap: 1.5rem;
  }
`;

/*
    options[] TODO look into if it's bad I use the order it's given and not a index system
        label: text
        value: any
        key: text
        image?: src
*/
export const Dropdown = ({
  className,
  options = [],
  value,
  onChange = () => {},
}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <StyledDropdown label={undefined} color="gray" variant="filled" className={className}>
      <StyledSelect
        color="gray"
        value={value}
        onChange={onChange}
        label={undefined}
        disableUnderline
        MenuProps={{
          sx: { "& ul": { backgroundColor: themeContext.color.gray } },
        }}
        IconComponent={() => (
          <DropdownIcon src={downIcon} />
        )}
        renderValue={(selectedValue) => {
          let selectedOption = options.find(option => option.value === selectedValue);
          if (!selectedOption) selectedOption = { label: "", image: "" };
          return (
            <StyledBox>
              {selectedOption.image && <DropDownItemImage src={selectedOption.image} />}
              <StyledInputText>{selectedOption.label}</StyledInputText>
            </StyledBox>
          );
        }}
      >
        {options.map((option) => (
          <StyledMenuItem value={option.value}>
            {option.image && <DropDownItemImage src={option.image} /> }
            <StyledInputText>{option.label}</StyledInputText>
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledDropdown>
  );
};
