import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import queryString from "query-string";

import { appStore } from "../stores/appStore";

export const getPosterImageId = (headlessMode) => {
  const [posterImageId, setPosterImageId] = useState();

  useEffect(() => {
    if (headlessMode) {
      const parsedParams = queryString.parse(window.location.search);
      if (!parsedParams.posterImageId) return; // TODO error handle some way?
      setPosterImageId(parsedParams.posterImageId);
    }
  }, [headlessMode]);

  return posterImageId;
};
