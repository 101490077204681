import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { appStore } from "../../../../../stores/appStore";
import { PlusMinusSelector } from "../../../../../components/v2/Inputs/PlusMinusSelector";
import { Label } from "../../../../../components/v2/Text/Label";
import { generateTestId } from "../../../../../util_code/testUtil";

export const SongTitleAndArtistFontSizeSelectorV2 = observer(() => {
  const { currentPoster } = appStore;
  const { artistFontSize, titleFontSize, setArtistFontSize, setTitleFontSize } = currentPoster;

  return (
    <Component>
      <SlidersContainer>
        <SliderContainer>
          <Label>Song Title Font Size</Label>
          <PlusMinusSelector decreaseTestId={generateTestId("single-track-wizard", "song-title-decrease")} increaseTestId={generateTestId("single-track-wizard", "song-title-increase")} value={titleFontSize} onChange={(value) => setTitleFontSize(value)} options={[{ label: "Small", value: "20px", key: "20px" }, { label: "Medium", value: "25px", key: "25px" }, { label: "Large", value: "30px", key: "30px" }]} />
        </SliderContainer>

        <SliderContainer>
          <Label>Artist Font Size</Label>
          <PlusMinusSelector decreaseTestId={generateTestId("single-track-wizard", "artist-title-decrease")} increaseTestId={generateTestId("single-track-wizard", "artist-title-increase")} value={artistFontSize} onChange={(value) => setArtistFontSize(value)} options={[{ label: "Small", value: "20px", key: "20px" }, { label: "Medium", value: "25px", key: "25px" }, { label: "Large", value: "30px", key: "30px" }]} />
        </SliderContainer>
      </SlidersContainer>
    </Component>
  );
});

const Component = styled.div`
  margin-top: 2rem;
`;

const SlidersContainer = styled.div`
`;

const SliderContainer = styled.div`
  margin-top: 2rem;
`;

const SliderLabel = styled.p`
  font-size: 2rem;
  text-align: center;
`;
