import React, { useMemo, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";
import { GrClose } from "react-icons/gr";
import SuperQuery from "@themgoncalves/super-query";

import { Modal } from "@mui/material";
import { appStore } from "../../stores/appStore";
import { useIsMobile } from "../../hooks/isMobile";
import waterMarkImageSrc from "../../images/WR-watermark-overlay.png";
import LogoLoadingSpinner from "../LogoLoadingSpinner/LogoLoadingSpinner";
import { SingleSongPosterEditor } from "../SingleSongPosterEditor/SingleSongPosterEditor";
import AlbumPosterEditor from "../AlbumPosterEditor/AlbumPosterEditor";

const STOP_BEING_ANNOYING_ESLINT = true;

export const PreviewModal = observer(() => {
  const cartItemAlbumContainerRef = useRef();
  const { currentPoster, showPreviewModal, setShowPreviewModal } = appStore;
  const {
    albumBase64PreviewImage,
    frameChoice,
    isSingleTrackPoster,
    theme,
    loadingAlbumBase65PreviewImage,
    trackLevels
  } = currentPoster;

  const isMobile = useIsMobile();

  const backgroundColor = useMemo(() => {
    if (isSingleTrackPoster) {
      if (frameChoice === "No Frame (Poster Only)") return "#2f2f2f";
      return "white";
    }
    if (theme === "light") return "#2f2f2f";
    return "white";
  }, [frameChoice, isSingleTrackPoster, theme]);

  const RenderProperImage = () => {
    if (isMobile && isSingleTrackPoster) {
      return (<PreviewImageMobileSingleTrack src={albumBase64PreviewImage} />);
    }
    if (isMobile && !isSingleTrackPoster) {
      return (<PreviewImageMobileMultiTrack src={albumBase64PreviewImage} />);
    }
    if (!isMobile && isSingleTrackPoster) {
      return (<PreviewImageDesktopSingleTrack src={albumBase64PreviewImage} />);
    }

    // is for !isMobile && !isSingleTrack
    return (<PreviewImageDesktopMultiTrack src={albumBase64PreviewImage} />);
  };

  if (!showPreviewModal) return <></>;

  return (
    <StyledModal open={showPreviewModal} onClose={() => setShowPreviewModal(false)}>
      <div>
        <CartItemModalContent onClick={() => setShowPreviewModal(false)} ref={cartItemAlbumContainerRef}>
          {isSingleTrackPoster ? <SingleSongPosterEditor addWatermark useLocalState currentPoster={currentPoster} albumContainerRef={cartItemAlbumContainerRef} /> : (
            <AlbumPosterEditor
              addWatermark
              albumContainerRef={cartItemAlbumContainerRef}
              useLocalState
              trackLevels={trackLevels}
              currentPoster={currentPoster}
            />
          )}
        </CartItemModalContent>
      </div>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)``;

const CartItemModalContent = styled.div`
    height: 93vh;
    margin: auto;
    margin-top: 3.5vh;
    margin-bottom: 3.5vh;
`;

const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 10000;
`;

const Content = styled.div`
  margin: auto;
  width: 80rem;
  max-width: 95%;
  max-height: 95vh;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 40rem;
  padding: 3rem 0;
  ${({ $isSingleTrack }) => !$isSingleTrack && `
    width: auto;
    height: 95vh;
    height: fit-content;
    max-height: 95vh;
  `}
  ${SuperQuery().maxWidth.sm.css`
    max-width: 100%;
    width: 98%;
    min-height: 0px;
    padding: 3rem 0;
    height: fit-content;
  `}
`;

const ImageContainer = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  position: relative;
`;

const LoadingContainer = styled.div`
  width: 50vw;
  height: 80vh;
  ${SuperQuery().maxWidth.sm.css`
    width: 90vw;
    height: 60vh;
  `}
`;

const ImageOverlay = styled.div`
  background-image: url(${waterMarkImageSrc});
  background-size: 100%;
  width: 95%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 2.5%;
  opacity: 0.05;
`;

const SharedPreviewImageCSS = css`
  display: block;
  background-color: transparent;
`;

const PreviewImageMobileSingleTrack = styled.img`
  ${SharedPreviewImageCSS};
  max-height: 100%;
  max-width: 96%;

  width: 96%;

  margin: auto;

  width: auto;
  height: auto;
`;

const PreviewImageMobileMultiTrack = styled.img`
  ${SharedPreviewImageCSS};
  max-height: 100%;
  max-width: 96%;

  margin: auto;

  width: auto;
  height: auto;
`;

const PreviewImageDesktopSingleTrack = styled.img`
  max-width: 95%;
  max-height: 95%;

  width: 92%;
  height: auto;

  display: block;
  margin: auto;

  background-color: transparent;
  width: auto;
  height: auto;
  margin: auto;
`;

const PreviewImageDesktopMultiTrack = styled.img`
  max-width: 95%;
  max-height: 95%;

  height: 90vh;

  display: block;
  margin: auto;

  background-color: transparent;
  width: auto;
`;

export const CloseIcon = styled(GrClose)`
  font-size: 1.5rem;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: ${({ $backgroundColor }) => $backgroundColor};
  cursor: pointer;
  .grIcon path {
    stroke: ${({ $backgroundColor }) => $backgroundColor};
  }
  path {
    stroke: ${({ $backgroundColor }) => $backgroundColor};
  }
`;

const LoadingText = styled.h2`
  margin: auto;
  text-align: center;
`;
