import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { observer } from "mobx-react-lite";

// eslint-disable-next-line import/no-self-import
import e from "cors";
import { useIsMobile } from "../../hooks/isMobile";
import { generateTestId } from "../../util_code/testUtil";
import { wizardStore } from "../../stores/wizardStore";
import waterMarkImageSrc from "../../images/WR-watermark-overlay.png";

const ASPECT_RATIO = {
  "8.5 X 11": 0.772,
  "11 X 14": 0.785,
  "16 X 20": 0.8,
  "20 X 16": 1.25,
  "18 X 24": 0.75,
  "24 X 36": 0.666,
}; // TODO get from firestore

const MAX_REDUCING_PERCENTAGE = 0.05;
const MAX_REDUCING_PERCENTAGE_MOBILE = 0.15;

const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

// const { shadowHeight, shadowWidth } = { shadowHeight: 10, shadowWidth: 10 };

export const AutoRescaleContainer = observer(({ addWatermark, useLocalState, containerRef, children, frameSize = "8.5 X 11", headlessMode, isSingleTrack }) => {
  const isMobile = useIsMobile();
  const { POSTER_WIDTH_RAW_V2, navBarHeightMobile } = useContext(ThemeContext);
  const [localScale, setLocalScale] = useState(1);
  const [localPosterBottomMargin, setLocalPosterBottomMargin] = useState(undefined);

  const refForOverlay = useRef();
  const [overlayDimensionsAndPosition, setOverlayDimensionsAndPosition] = useState({ width: 10, height: 10, top: 0, left: 0 });

  const { step, mobileStepsHeight, setPosterBottomMargin: setGlobalPosterBottomMargin, posterBottomMargin: globalPosterBottomMargin, setMaxHeight, scale: globalScale, setScale: globalSetScale, maxHeight: maxHeightWizardStore } = wizardStore;

  const setScale = useLocalState ? setLocalScale : globalSetScale;
  const scale = useLocalState ? localScale : globalScale;

  const setPosterBottomMargin = useLocalState ? setLocalPosterBottomMargin : setGlobalPosterBottomMargin;
  const posterBottomMargin = useLocalState ? localPosterBottomMargin : globalPosterBottomMargin;

  useEffect(() => {
    calculateScale();
  }, [frameSize, isMobile, containerRef, mobileStepsHeight, step]);

  useEffect(() => {
    const totalScreenHeightAvailableToEditor = window.innerHeight - convertRemToPixels(navBarHeightMobile);
    setMaxHeight(totalScreenHeightAvailableToEditor);
  }, []);

  useEffect(() => {
    if (!refForOverlay.current || !addWatermark) return;
    const refForOverlayCurrent = refForOverlay.current;
    setOverlayDimensionsAndPosition({
      width: refForOverlayCurrent.clientWidth * scale,
      height: refForOverlayCurrent.clientHeight * scale,
      top: refForOverlayCurrent.offsetTop - ((refForOverlayCurrent.clientHeight * scale - refForOverlayCurrent.clientHeight) / 2),
      left: refForOverlayCurrent.offsetLeft - ((refForOverlayCurrent.clientHeight * scale - refForOverlayCurrent.clientHeight) / 2)
    });
  }, [refForOverlay, scale]);

  const { shadowHeight, shadowWidth } = useMemo(() => {
    const aspectRatioToUse = ASPECT_RATIO[frameSize];
    const amountOfExtraWidthForShadow = isMobile ? 1.025 : 1.05;
    if (isSingleTrack) {
      const newShadowWidth = (POSTER_WIDTH_RAW_V2 / aspectRatioToUse) * amountOfExtraWidthForShadow;
      return {
        shadowHeight: newShadowWidth / 5,
        shadowWidth: newShadowWidth
      };
    }
    const newShadowWidth = POSTER_WIDTH_RAW_V2 * amountOfExtraWidthForShadow;
    return {
      shadowHeight: newShadowWidth / 5,
      shadowWidth: newShadowWidth
    };
  }, [scale]);

  const getRawWidthAndHeight = () => {
    const aspectRatioToUse = ASPECT_RATIO[frameSize];
    if (isSingleTrack) {
      return { rawWidth: POSTER_WIDTH_RAW_V2 / aspectRatioToUse, rawHeight: POSTER_WIDTH_RAW_V2 };
    }
    return { rawWidth: POSTER_WIDTH_RAW_V2, rawHeight: POSTER_WIDTH_RAW_V2 / aspectRatioToUse };
  };

  const calculateScale = () => {
    if (headlessMode || (!containerRef || !containerRef.current)) {
      setScale(1);
      return;
    }

    const { rawWidth, rawHeight } = getRawWidthAndHeight();

    const maxWidth = containerRef.current.offsetWidth;
    const maxHeight = containerRef.current.offsetHeight;

    const maxReducingPercentageToUse = isMobile ? MAX_REDUCING_PERCENTAGE_MOBILE : MAX_REDUCING_PERCENTAGE;

    const maxWidthToRenderAt = maxWidth - (maxWidth * maxReducingPercentageToUse);
    const maxHeightToRenderAt = maxHeight - (maxHeight * maxReducingPercentageToUse);

    const scaleToRenderWidthAsLargeAsPossible = maxWidthToRenderAt / rawWidth;
    const scaleToRenderHeightAsLargeAsPossible = maxHeightToRenderAt / rawHeight;

    const aspectRatioToUse = ASPECT_RATIO[frameSize === "16 X 20" && isSingleTrack ? "20 X 16" : frameSize];
    let scaleToUse;

    const canRenderAtWidthScale = scaleToRenderWidthAsLargeAsPossible * rawWidth <= maxWidthToRenderAt && scaleToRenderWidthAsLargeAsPossible * rawHeight <= maxHeightToRenderAt;
    const canRenderAtHeightScale = scaleToRenderHeightAsLargeAsPossible * rawHeight <= maxHeightToRenderAt && scaleToRenderHeightAsLargeAsPossible * rawWidth <= maxWidthToRenderAt;

    if ((scaleToRenderWidthAsLargeAsPossible > scaleToRenderHeightAsLargeAsPossible && canRenderAtWidthScale) || !canRenderAtHeightScale) {
      scaleToUse = scaleToRenderWidthAsLargeAsPossible;
      setScale(scaleToRenderWidthAsLargeAsPossible);
    } else {
      scaleToUse = scaleToRenderHeightAsLargeAsPossible;
      setScale(scaleToRenderHeightAsLargeAsPossible);
    }

    // TODO should have a better solution, note for future dev/Henry: I think it has something to do with the width/aspect ratio and the 400px hard value
    if (scaleToUse > 0.95) {
      setPosterBottomMargin(undefined);
      return;
    }
    console.log("HERE RUNNING", scaleToUse);
    const scaleValue = (scaleToUse * 400);
    if (isSingleTrack) {
      // width: 333, height: 269 SHOOT FOR 129, 260
      setPosterBottomMargin((maxHeight - scaleValue) / 2);
    } else {
      setPosterBottomMargin((maxHeight - (scaleValue / aspectRatioToUse)) / 2);
    }
  };

  return (
    <>
      <StyledAutoRescaleContainer ref={refForOverlay} $marginBottom={posterBottomMargin} $shadowHeight={shadowHeight} $shadowWidth={shadowWidth} scale={scale} data-explorator_test_id={generateTestId("shared", "auto-scale-container")} id="poster-container-to-load-for-image">{children}</StyledAutoRescaleContainer>
      {addWatermark && <ImageOverlay $dimensionsAndPosition={overlayDimensionsAndPosition} />}
    </>
  );
});

const ImageOverlay = styled.div`
  width: ${({ $dimensionsAndPosition }) => $dimensionsAndPosition.width}px;
  height: ${({ $dimensionsAndPosition }) => $dimensionsAndPosition.height}px;
  top: ${({ $dimensionsAndPosition }) => $dimensionsAndPosition.top}px;
  /* left: ${({ $dimensionsAndPosition }) => $dimensionsAndPosition.left}px; */
  position: absolute;
  background-image: url(${waterMarkImageSrc});
  background-size: 100%;
  opacity: 0.05;
  z-index: 2;
`;

export const StyledAutoRescaleContainer = styled.div`
  transition: background 0.3s ease-in-out;
  transform: scale(${props => props.scale});
  display: flex;
  justify-content: center;
  margin: auto;
  background-image: url(${waterMarkImageSrc})
  width: fit-content;
  ${({ $marginBottom }) => $marginBottom && `margin-top: ${$marginBottom}px`};
  ${({ $marginBottom }) => $marginBottom && `
    transform-origin: center top 0px;
  `};
  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: -${({ $shadowHeight }) => $shadowHeight * 0.4}px;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $shadowWidth }) => $shadowWidth}px;
    height: ${({ $shadowHeight }) => $shadowHeight}px;
    /* background: rgb(0,0,0); */
    background-color: rgb(196, 196, 196);
    filter: blur(15px);
    border-radius: 50%;
  }
`;
