import { makeAutoObservable, runInAction } from "mobx";
import { processAlbumRawData } from "./helpers/wizardHelper";
import { appStore } from "./appStore";

export const checkIfCanGoToNextStep = (step) => {
  const { currentPoster } = appStore;
  const { album, hideDate, customDatetime, hideTime, artistFontColor, descriptionType, isSingleTrackPoster, customLocation, hideLocation, hideDescription, customDescription, frameChoice, posterSize, posterColor } = currentPoster;
  switch (step) {
  case 1:
    if (!currentPoster || Object.keys(album).length === 0) {
      return "Please select an album before you continue";
    }
    break;
  case 2:
    if (isSingleTrackPoster && (!customLocation && !hideLocation)) return "Please select a location or choose to hide location.";
    if (!isSingleTrackPoster && !descriptionType) return "Please select a description type";
    break;
  case 3:
    if (isSingleTrackPoster && !customDescription && !hideDescription) return "Please select a description or choose to hide description.";
    if (isSingleTrackPoster && !hideDate && !customDatetime) return "Please hide or select a date";
    if (isSingleTrackPoster && !hideTime && !customDatetime) return "Please hide or select a time";
    if (!isSingleTrackPoster && !posterColor) return "Please select a poster color.";
    break;
  case 4:
    if (!frameChoice || !posterSize) return "Please select both a poster size and frame";
    if (isSingleTrackPoster && artistFontColor === undefined) return "Please select a color";
    break;
  default:
    return false;
  }
  return false;
};

const minHeight = 100;

export class WizardStore {
  constructor() {
    this.step = 1;
    this.maxStepReached = 1;

    this.search = "";
    this.artistId = "";
    this.artistAlbumResults = [];
    this.processedArtistAlbumResults = [];
    this.spotifyApi = null;
    this.mobileStepsHeight = 100;
    this.maxHeight = undefined;
    this.posterBottomMargin = undefined;
    this.scale = 1;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPosterBottomMargin(newValue) {
    this.posterBottomMargin = newValue;
  }

  setScale(newValue) {
    this.scale = newValue;
  }

  setMobileStepsHeight(newHeight) {
    this.mobileStepsHeight = newHeight;
  }

  setMaxHeight(newMaxHeight) {
    // Need to get full possible height of both to do this
    this.maxHeight = newMaxHeight;
    // this.mobileStepsHeight = newMaxHeight - 150;
    this.mobileStepsHeight = minHeight + 50;
  }

  updateSpotifyInstance(spotifyApi) {
    this.spotifyApi = spotifyApi;
  }

  setSearch(search) {
    this.search = search;
  }

  setArtistId(artistId) {
    this.artistId = artistId;
  }

  setProcessedArtistAlbumResults(processedArtistAlbumResults) {
    this.processedArtistAlbumResults = processedArtistAlbumResults;
  }

  nextStepOnClick() {
    const { throwErrorMessage } = appStore;
    const error = checkIfCanGoToNextStep(this.step);
    if (error) {
      throwErrorMessage(error);
      return;
    }
    if (!this.rendering) {
      this.setStep = this.step + 1;
    }
  }

  previousStepOnClick() {
    this.setStep = this.step - 1;
  }

  goToStep(newStep) {
    if (newStep > this.maxStepReached) {
      const { throwErrorMessage } = appStore;
      throwErrorMessage("Please finish steps in order.");
      return;
    }
    this.setStep = newStep;
  }

  set setStep(newValue) {
    if (this.step !== newValue) this.step = newValue;
    if (this.step > this.maxStepReached) this.maxStepReached = newValue;
  }

  resetStepCount() {
    this.setStep = 1;
    this.maxStepReached = 1;
    this.search = "";
    this.artistId = "";
    this.artistAlbumResults = [];
    this.processedArtistAlbumResults = [];
  }

  async searchOnUpdate() {
    if (!this.search) {
      this.artistAlbumResults = [];
      return;
      // this.processedArtistAlbumResults = [];
    }

    runInAction(() => {
      this.artistAlbumResults = [];
      this.processedArtistAlbumResults = [];
    });

    const searchResults = await Promise.all([
      this.spotifyApi.searchAlbums(this.search, { limit: 10, album_type: "album" }),
      // this.spotifyApi.searchArtists(this.search, { limit: 10 }), // turning off for now
    ]);

    const albumSearchResponse = searchResults[0].body.albums;
    // const artistSearchResponse = searchResults[1].body.artists; // turning off for now
    const artistSearchResponse = { items: [] };

    if (albumSearchResponse) {
      const albums = albumSearchResponse.items.map(processAlbumRawData);
      this.artistAlbumResults = [...this.artistAlbumResults, ...albums];
    }

    if (artistSearchResponse) {
      const artists = artistSearchResponse.items;
      await this.populateArtistAlbums(artists);
    }

    await this.cleanUpAlbumSearchResult();
  }

  async populateArtistAlbums(artistsSearchResponse) {
    const artistsAlbumSearchQueue = [];

    artistsSearchResponse.forEach((artist) => {
      artistsAlbumSearchQueue.push(
        this.spotifyApi.getArtistAlbums(artist.id, {
          album_type: "album",
          limit: 10,
        })
      );
    });

    const artistsAlbumSearchResponse = await Promise.all(
      artistsAlbumSearchQueue
    );

    artistsAlbumSearchResponse.forEach((artistAlbumsSearchResponse) => {
      const artistAlbums = artistAlbumsSearchResponse.body.items;

      if (artistAlbums.length >= 1) {
        runInAction(() => {
          this.artistAlbumResults = [
            ...this.artistAlbumResults,
            ...artistAlbums.map(processAlbumRawData),
          ];
        });
      }
    });
  }

  // Sort and filter by duplicated entries
  async cleanUpAlbumSearchResult() {
    const uniqueAlbums = [];

    // Filter out duplicates by album title
    this.artistAlbumResults.forEach((toCheckArtistAlbum) => {
      const albumDoesntExist = uniqueAlbums.find(
        (uniqueAlbum) => uniqueAlbum.title === toCheckArtistAlbum.title
      ) === undefined;
      if (albumDoesntExist) {
        uniqueAlbums.push(toCheckArtistAlbum);
      }
    });

    this.processedArtistAlbumResults = uniqueAlbums;
  }
}

export const wizardStore = new WizardStore();
