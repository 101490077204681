import React, { useContext, useState } from "react";
import LibrarySwitch from "react-switch";
import styled, { ThemeContext } from "styled-components";

const StyledLibrarySwitch = styled(LibrarySwitch)`
  && {
    /* width: 4rem; */
  }
`;

const SwitchSize = 40;

/*
    https://www.npmjs.com/package/react-switch for props
*/
export const Switch = (props) => {
  const { value, onChange, testId } = props;
  const themeContext = useContext(ThemeContext);

  const handleChange = () => {
    onChange();
  };

  // TODO add #E2E8F0 to theme if found elsewhere
  // return (
  //   <StyledLibrarySwitch onColor={themeContext.color.black} width={`${SwitchSize}px`} height={`${SwitchSize / 2}px`} offColor="#E2E8F0" uncheckedIcon={false} checkedIcon={false} {...props} />
  // );
  return (
    <SwitchContainer>
      <CheckboxInput data-explorator_test_id={testId} type="checkbox" checked={value} onChange={handleChange} />
      <Slider />
    </SwitchContainer>
  );
};

const SwitchHeight = 26; // 24
const SwitchWidth = 48; // 44

// Styled component for the switch container
const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: ${SwitchWidth}px; // 44
  height: ${SwitchHeight}px; // 24
`;

// Styled component for the actual slider
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E2E8F0;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50px;

  &:before {
    position: absolute;
    content: "";
    height: ${SwitchHeight - 5}px;
    width: ${SwitchHeight - 5}px;
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => theme.color.white};
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;

// Styled component for the checkbox input (hidden)
const CheckboxInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.color.black};
  }

  &:checked + ${Slider}:before {
    transform: translateX(${SwitchWidth - (SwitchHeight + 1)}px);
  }
`;
