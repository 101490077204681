import React from "react";
import styled from "styled-components";
import { BaseText } from "./BaseText";

const StyledInputText = styled(BaseText)`
  && {
    font-size: ${({ theme }) => theme.fontSize.input};
  }
`;

export const InputText = (props) => {
  const { children } = props;
  return (
    <StyledInputText level="p" {...props}>
      {children}
    </StyledInputText>
  );
};
