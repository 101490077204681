import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import Spinner from "react-bootstrap/Spinner";

import "./loader.css";
import { appStore } from "../../../stores/appStore";
import { cartStore } from "../../../stores/cartStore";
import { CartItemV2 } from "./CartItem";
import { generateTestId } from "../../../util_code/testUtil";
import { PageWrapper } from "../../../components/v2/OtherComponents/PageWrapper";
import { BaseText } from "../../../components/v2/Text/BaseText";
import { Button } from "../../../components/v2/OtherComponents/Button";
import { InputText } from "../../../components/v2/Text/InputText";

export const CartV2 = observer(() => {
  const {
    logAnalyticsEvent,
  } = appStore;

  const {
    loading, setLoading,
    submitOrder,
    cartItems,
    totalPrice,
  } = cartStore;

  useEffect(() => {
    logAnalyticsEvent("cart", {
      category: "pageLanding",
      action: "reachedCartPage",
    });
  }, []);

  const checkoutOnClick = async (e) => {
    if (loading) return;
    e.preventDefault();
    setLoading(true);

    logAnalyticsEvent("checkout", {
      category: "pageLanding",
      action: "reachedCartPage",
    });

    submitOrder();
  };

  return (
    <PageWrapper>
      <CartPageWrapper>
        <CartItemsAndTotalContainer>
          <CartItemsContainer>
            {
              cartItems.map(cartItem => (<CartItemV2 key={cartItem.poster.id} cartItem={cartItem} />))
            }
          </CartItemsContainer>
          <SubTotalPriceContainer>
            <SubTotalAndPriceText>Subtotal</SubTotalAndPriceText>
            <SubTotalAndPriceText>
              $
              {totalPrice.toFixed(2)}
            </SubTotalAndPriceText>
          </SubTotalPriceContainer>
          <PricingClarification>Shipping, taxes, and discount codes calculated at checkout</PricingClarification>
        </CartItemsAndTotalContainer>
        <CartItemsFooterV2>
          <ActionButtonContainer>
            <Link to="/editor">
              <CreateAnotherButton variant="outlined">
                <CreateAnotherButtonText>Create Another Custom Piece</CreateAnotherButtonText>
              </CreateAnotherButton>
            </Link>
            <CheckoutButton data-explorator_test_id={generateTestId("cart-page", "checkout-button")} onClick={(event) => checkoutOnClick(event)}>
              <CheckoutButtonText>
                {loading
                  ? (
                    <SpinnerContainer>
                      <Spinner animation="border" />
                    </SpinnerContainer>
                  )
                  : "Proceed To Checkout"}
              </CheckoutButtonText>
            </CheckoutButton>
          </ActionButtonContainer>
          <TermsAndConditionsText>
            By continuing to checkout, I agree to the
            {" "}
            <OrderTermsAndConditions href="https://thewavroom.com/pages/custom-order-terms-and-conditions" target="_blank" rel="noreferrer">
              Custom Order Terms & Conditions
            </OrderTermsAndConditions>
          </TermsAndConditionsText>
        </CartItemsFooterV2>
      </CartPageWrapper>
    </PageWrapper>
  );
});

const SpinnerContainer = styled.div`
  transform: skewX(10deg);
`;

const CartWhiteContainerSharedStyle = css`
    padding: 0 3%;
    border-radius: 10px;
    @media ${({ theme }) => theme.devicesMax.md} { 
      width: 100%;
      padding: 0 5%;
    }
`;

const CartItemsAndTotalContainer = styled.div`

`;

const CartItemsContainer = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: 2rem;
    margin-top: 11rem;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    ${CartWhiteContainerSharedStyle};
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media ${({ theme }) => theme.devicesMax.md} { 
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-top: 1.5rem;
      max-height: 40vh;
      overflow-y: auto;
    }
`;

const CartPageWrapper = styled.div`
  font-size: 2rem;
  padding: 2rem;
  min-height: calc(100vh - ${({ theme }) => theme.navBarHeight}rem);
  max-width: 120rem;
  width: 75vw;
  margin: auto;
  @media ${({ theme }) => theme.devicesMax.md} { 
    width: 95%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - ${({ theme }) => theme.navBarHeightMobile}rem);
    padding-bottom: 1rem;
  }
`;

const CartItemsFooterV2 = styled.div`
    width: 100%;
`;

const SubTotalPriceContainer = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${CartWhiteContainerSharedStyle};
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    @media ${({ theme }) => theme.devicesMax.md} { 
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
`;

const SubTotalAndPriceText = styled(BaseText)`
    && {
        font-weight: 600; // TODO maybe add weight to theme
        font-size: 1.6rem;
    }
`;

const PricingClarificationTermsAndConditionsText = css`
  font-size: 1rem;
  color: ${({ theme }) => theme.color.noticeText};
  text-align: right;
  margin-top: 0.75rem;
  font-weight: 500;
  @media ${({ theme }) => theme.devicesMax.md} {  
    text-align: center;
  }
`;

const TermsAndConditionsText = styled(BaseText)`
    && {
        ${PricingClarificationTermsAndConditionsText};
        @media ${({ theme }) => theme.devicesMax.md} {  
          width: 60%;
          margin: auto;
          margin-top: 1rem;
        }
    }
`;

const PricingClarification = styled(BaseText)`
  && {
    ${PricingClarificationTermsAndConditionsText};
  }
`;

const OrderTermsAndConditions = styled.a`
    && {
        color: ${({ theme }) => theme.color.noticeText};
        font-weight: inherit;
        font-size: inherit;
    }
`;

const ActionButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
    width: fit-content;
    margin-left: auto;
    margin-top: 7rem;
    @media ${({ theme }) => theme.devicesMax.md} {  
      flex-direction: column;
      margin-right: auto;
      margin-left: auto;
    }
`;

const SharedActionButtonTextStyle = css`
    font-size: 1.5rem;
`;

const SharedActionButtonStyle = css`
    padding: 1.5rem;
    width: 35rem;
`;

const CheckoutButton = styled(Button)`
    && {
        ${SharedActionButtonStyle};
        
    }
`;

const CreateAnotherButton = styled(Button)`
    && {
        background-color: ${({ theme }) => theme.color.white};
        ${SharedActionButtonStyle};
    }
`;

const CreateAnotherButtonText = styled(InputText)`
    && {
        ${SharedActionButtonTextStyle};
        font-weight: 500;
    }
`;

const CheckoutButtonText = styled(InputText)`
    && {
        color: ${({ theme }) => theme.color.white};
        font-weight: 700;
        ${SharedActionButtonTextStyle};
    }
`;
