import React, { useCallback, useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";

import { POSTER_PARAMETERS } from "../stores/appStore";

const POSTER_PARAMETERS_LOCAL = [...POSTER_PARAMETERS, { key: "trackLevels" }];

export const useGeneratedBase64Data = (appStore) => {
  const { currentPoster, headlessMode } = appStore;
  const {
    posterSize,
    setAlbumBase64PreviewImage,
    isSingleTrackPoster,
    setLoadingAlbumBase65PreviewImage,
    setErrorLoadingBase64PreviewImage,
  } = currentPoster;

  const [currentParams, setCurrentParams] = useState({});

  useEffect(() => {
    if (headlessMode) return;
    const newParams = getParameters();
    if (compareParameters(newParams, currentParams)) {
      return;
    }
    setCurrentParams(newParams);
    setLoadingAlbumBase65PreviewImage(true);
    const posterElement = document.getElementById(
      "poster-container-to-load-for-image"
    );
    generateBase64(posterElement);
  }, [POSTER_PARAMETERS_LOCAL.map((posterParam) => currentPoster[posterParam.key])]);

  const getCanvasDimensions = () => {
    if (posterSize === "16 X 20") { return { canvasWidth: 800, canvasHeight: 1000 }; }
    if (posterSize === "18 X 24") { return { canvasWidth: 750, canvasHeight: 1000 }; }
    if (posterSize === "24 X 36") { return { canvasWidth: 1200, canvasHeight: 1800 }; }
    return { canvasWidth: 800, canvasHeight: 1000 };
  };

  const getParameters = () => {
    const params = {};
    POSTER_PARAMETERS_LOCAL.forEach((posterParam) => {
      params[posterParam.key] = currentPoster[posterParam.key];
    });
    return params;
  };

  const compareParameters = (oldParams, newParams) => {
    let sameParams = true;
    POSTER_PARAMETERS_LOCAL.forEach((posterParam) => {
      if (oldParams[posterParam.key] !== newParams[posterParam.key]) {
        sameParams = false;
      }
    });
    return sameParams;
  };

  const calcCanvasSize = useCallback(() => {
    let canvasWidth = 0;
    let canvasHeight = 0;

    switch (posterSize) {
    case "8.5 X 11":
      canvasHeight = 772;
      canvasWidth = 1000;
      break;
    case "11 X 14":
      canvasHeight = 785;
      canvasWidth = 1000;
      break;
    case "16 X 20":
      canvasHeight = 800;
      canvasWidth = 1000;
      break;
    case "18 X 24":
      canvasHeight = 750;
      canvasWidth = 1000;
      break;
    default:
      break;
    }

    return {
      canvasWidth,
      canvasHeight,
    };
  }, [posterSize]);

  const generateBase64 = async (posterElement) => {
    try {
      const filter = (inputNode) => inputNode.tagName !== "i";
      let dimensionsObject = getCanvasDimensions();
      if (isSingleTrackPoster) dimensionsObject = calcCanvasSize();
      // TODO if someone makes a change and adds to cart too quickly the image wont update in time
      const dataUrl = await htmlToImage.toJpeg(posterElement, {
        filter,
        ...dimensionsObject,
        cacheBust: true,
        style: {
          transform: "scale(1)",
        }
      });
      setAlbumBase64PreviewImage(dataUrl);
      setLoadingAlbumBase65PreviewImage(false);
    } catch (err) {
      if (!err) setErrorLoadingBase64PreviewImage(true);
      else setErrorLoadingBase64PreviewImage(err);
      setLoadingAlbumBase65PreviewImage(false);
    }
  };

  return <></>;
};
