export const fakeTrackLevels1 = [
  0.01, 0.01, 0, 0.04, 0.36, 0.36, 0.36, 0.37, 0.29, 0.29, 0.4, 0.4, 0.25, 0.15,
  0.15, 0.15, 0.3, 0.3, 0.23, 0.23, 0.23, 0.23, 0.23, 0.36, 0.36, 0.36, 0.36,
  0.36, 0.36, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43, 0.43,
  0.14, 0.14, 0.42, 0.42, 0.23, 0.23, 0.23, 0.2, 0.41, 0.41, 0.26, 0.26, 0.35,
  0.28, 0.28, 0.38, 0.38, 0.38, 0.28, 0.28, 0.2, 0.32, 0.41, 0.41, 0.41, 0.27,
  0.27, 0.27, 0.29, 0.19, 0.19, 0.22, 0.22, 0.22, 0.22, 0.37, 0.31, 0.31, 0.26,
  0.26, 0.26, 0.43, 0.43, 0.3, 0.3, 0.3, 0.3, 0.49, 0.49, 0.42, 0.42, 0.51,
  0.51, 0.51, 0.44, 0.44, 0.21, 0.21, 0.17, 0.17, 0.49, 0.49, 0.49, 0.37, 0.58,
  0.58, 0.51, 0.51, 0.51, 0.56, 0.56, 0.56, 0.56, 0.57, 0.5, 0.5, 0.5, 0.45,
  0.45, 0.48, 0.48, 0.35, 0.26, 0.27, 0.27, 0.16, 0.43, 0.31, 0.33, 0.39, 0.5,
  0.65, 0.65, 0.65, 0.49, 0.49, 0.49, 0.41, 0.42, 0.42, 0.38, 0.37, 0.36, 0.4,
  0.4, 0.42, 0.42, 0.42, 0.25, 0.25, 0.25, 0.49, 0.58, 0.58, 0.58, 0.58, 0.55,
  0.49, 0.56, 0.51, 0.52, 0.52, 0.51, 0.65, 0.53, 0.53, 0.6, 0.6, 0.6, 0.47,
  0.47, 0.61, 0.61, 0.61, 0.61, 0.52, 0.52, 0.54, 0.49, 0.49, 0.53, 0.53, 0.51,
  0.56, 0.56, 0.56, 0.41, 0.41, 0.37, 0.36, 0.55, 0.55, 0.5, 0.5, 0.39, 0.32,
  0.32, 0.41, 0.41, 0.43, 0.49, 0.49, 0.49, 0.57, 0.57, 0.54, 0.54, 0.54, 0.54,
  0.39, 0.39, 0.51, 0.51, 0.57, 0.57, 0.57, 0.53, 0.53, 0.41, 0.41, 0.49, 0.59,
  0.66, 0.66, 0.67, 0.54, 0.54, 0.54, 0.36, 0.44, 0.44, 0.44, 0.44, 0.56, 0.63,
  0.63, 0.48, 0.48, 0.56, 0.56, 0.56, 0.39, 0.61, 0.53, 0.51, 0.49, 0.7, 0.7,
  0.7, 0.7, 0.7, 0.7, 0.53, 0.52, 0.54, 0.65, 0.51, 0.51, 0.48, 0.48, 0.36,
  0.52, 0.52, 0.38, 0.4, 0.53, 0.53, 0.48, 0.55, 0.55, 0.55, 0.47, 0.51, 0.51,
  0.52, 0.52, 0.52, 0.52, 0.37, 0.32, 0.52, 0.52, 0.59, 0.59, 0.59, 0.43, 0.52,
  0.53, 0.53, 0.53, 0.47, 0.47, 0.4, 0.5, 0.58, 0.54, 0.54, 0.63, 0.63, 0.63,
  0.58, 0.66, 0.59, 0.59, 0.59, 0.59, 0.59, 0.36, 0.36, 0.36, 0.36, 0.41, 0.37,
  0.5, 0.63, 0.65, 0.57, 0.54, 0.6, 0.57, 0.58, 0.61, 0.6, 0.64, 0.63, 0.63,
  0.48, 0.48, 0.41, 0.41, 0.4, 0.41, 0.45, 0.44, 0.44, 0.38, 0.38, 0.4, 0.37,
  0.46, 0.58, 0.58, 0.6, 0.6, 0.47, 0.49, 0.41, 0.41, 0.35, 0.35, 0.35, 0.4,
  0.4, 0.4, 0.4, 0.44, 0.34, 0.34, 0.33, 0.28, 0.31, 0.31, 0.31, 0.5, 0.7, 0.7,
  0.7, 0.53, 0.48, 0.62, 0.62, 0.66, 0.66, 0.62, 0.62, 0.62, 0.62, 0.5, 0.45,
  0.5, 0.5, 0.48, 0.49, 0.49, 0.49, 0.47, 0.43, 0.47, 0.47, 0.46, 0.6, 0.64,
  0.64, 0.64, 0.64, 0.64, 0.53, 0.53, 0.43, 0.45, 0.42, 0.42, 0.39, 0.42, 0.5,
  0.51, 0.51, 0.51, 0.45, 0.4, 0.4, 0.4, 0.4, 0.38, 0.38, 0.45, 0.67, 0.67,
  0.67, 0.63, 0.63, 0.75, 0.75, 0.59, 0.61, 0.65, 0.64, 0.64, 0.64, 0.6, 0.6,
  0.7, 0.7, 0.59, 0.59, 0.61, 0.64, 0.64, 0.59, 0.59, 0.55, 0.66, 0.66, 0.62,
  0.62, 0.62, 0.59, 0.59, 0.64, 0.64, 0.64, 0.72, 0.72, 0.67, 0.67, 0.67, 0.67,
  0.74, 0.74, 0.76, 0.73, 0.73, 0.82, 0.82, 0.71, 0.71, 0.74, 0.77, 0.79, 0.67,
  0.65, 0.77, 0.83, 0.83, 0.74, 0.74, 0.75, 0.76, 0.76, 0.76, 0.76, 0.76, 0.76,
  0.77, 0.73, 0.73, 0.74, 0.74, 0.76, 0.72, 0.75, 0.76, 0.76, 0.76, 0.76, 0.73,
  0.69, 0.72, 0.82, 0.8, 0.85, 0.85, 0.84, 0.78, 0.77, 0.77, 0.77, 0.89, 0.89,
  0.85, 0.82, 0.82, 0.83, 0.83, 0.88, 0.88, 0.88, 0.78, 0.72, 0.73, 0.66, 0.66,
  0.66, 0.72, 0.8, 0.8, 0.7, 0.75, 0.76, 0.81, 0.81, 0.82, 0.88, 0.88, 0.9,
  0.91, 0.91, 0.91, 0.91, 0.93, 0.93, 0.93, 0.93, 0.92, 0.96, 0.95, 0.95, 0.95,
  0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.93, 0.93, 0.93, 0.93,
  0.93, 0.95, 0.95, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.86, 0.86,
  0.86, 0.9, 0.9, 0.83, 0.83, 0.85, 0.89, 0.89, 0.89, 0.89, 0.89, 0.97, 0.97,
  0.95, 0.97, 0.95, 0.95, 0.93, 0.93, 0.96, 0.96, 0.98, 1, 1, 0.93, 0.94, 0.94,
  0.94, 0.94, 0.93, 0.93, 0.95, 0.95, 0.95, 0.93, 0.92, 0.95, 0.94, 0.96, 0.96,
  0.93, 0.93, 0.94, 0.92, 0.92, 0.92, 0.91, 0.91, 0.91, 0.91, 0.78, 0.78, 0.78,
  0.78, 0.78, 0.74, 0.74, 0.74, 0.74, 0.67, 0.69, 0.69, 0.69, 0.65, 0.65, 0.67,
  0.65, 0.77, 0.81, 0.81, 0.81, 0.81, 0.7, 0.77, 0.77, 0.77, 0.77, 0.71, 0.71,
  0.71, 0.71, 0.66, 0.66, 0.66, 0.68, 0.67, 0.68, 0.68, 0.73, 0.87, 0.81, 0.81,
  0.81, 0.79, 0.87, 0.87, 0.87, 0.81, 0.79, 0.85, 0.85, 0.85, 0.85, 0.85, 0.78,
  0.81, 0.8, 0.82, 0.83, 0.82, 0.82, 0.82, 0.82, 0.78, 0.79, 0.79, 0.79, 0.77,
  0.77, 0.77, 0.92, 0.9, 0.94, 0.94, 0.94, 0.94, 0.92, 0.92, 0.9, 0.92, 0.92,
  0.92, 0.89, 0.89, 0.89, 0.91, 0.91, 0.91, 0.88, 0.89, 0.91, 0.91, 0.91, 0.91,
  0.91, 0.93, 0.93, 0.9, 0.9, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.94,
  0.94, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.92, 0.92, 0.94, 0.94,
  0.94, 0.94, 0.88, 0.88, 0.88, 0.89, 0.91, 0.91, 0.91, 0.91, 0.91, 0.9, 0.9,
  0.91, 0.91, 0.91, 0.91, 0.91, 0.88, 0.88, 0.87, 0.87, 0.9, 0.91, 0.91, 0.89,
  0.89, 0.91, 0.91, 0.91, 0.91, 0.91, 0.88, 0.89, 0.89, 0.89, 0.89, 0.9, 0.9,
  0.89, 0.89, 0.88, 0.88, 0.87, 0.87, 0.87, 0.89, 0.89, 0.89, 0.89, 0.89, 0.89,
  0.89, 0.89, 0.89, 0.88, 0.88, 0.88, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.9,
  0.9, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.88, 0.89, 0.89, 0.89, 0.89, 0.89,
  0.89, 0.84, 0.84, 0.84, 0.84, 0.87, 0.87, 0.87, 0.88, 0.88, 0.88, 0.88, 0.88,
  0.88, 0.84, 0.84, 0.85, 0.9, 0.9, 0.9, 0.83, 0.83, 0.84, 0.84, 0.84, 0.84,
  0.86, 0.86, 0.89, 0.89, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.83, 0.83,
  0.85, 0.85, 0.84, 0.84, 0.84, 0.85, 0.85, 0.85, 0.85, 0.85, 0.82, 0.82, 0.85,
  0.85, 0.85, 0.85, 0.85, 0.85, 0.84, 0.81, 0.83, 0.79, 0.79, 0.8, 0.8, 0.76,
  0.79, 0.79, 0.8, 0.82, 0.84, 0.84, 0.83, 0.83, 0.83, 0.83, 0.83, 0.79, 0.76,
  0.76, 0.8, 0.85, 0.85, 0.85, 0.8, 0.8, 0.83, 0.83, 0.83, 0.83, 0.81, 0.84,
  0.84, 0.84, 0.82, 0.82, 0.78, 0.79, 0.84, 0.84, 0.81, 0.81, 0.77, 0.77, 0.78,
  0.81, 0.85, 0.81, 0.8, 0.8, 0.81, 0.77, 0.82, 0.77, 0.79, 0.79, 0.79, 0.77,
  0.77, 0.77, 0.71, 0.78, 0.78, 0.78, 0.76, 0.76, 0.72, 0.77, 0.67, 0.67, 0.52,
  0.43, 0.44, 0.44, 0.4, 0.4, 0.37, 0.31, 0.33, 0.28, 0.28, 0.28, 0.31, 0.31,
  0.31, 0.31, 0.17, 0.13, 0.13, 0.13, 0.13, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

export const fakeTrackLevels2 = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11,
  0.11, 0.11, 0.11, 0.11, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.31, 0.31,
  0.31, 0.04, 0.04, 0.32, 0.32, 0.32, 0.32, 0.32, 0.13, 0.13, 0.24, 0.24, 0.22,
  0.22, 0.22, 0.22, 0.24, 0.24, 0.24, 0.24, 0.24, 0.27, 0.25, 0.31, 0.38, 0.38,
  0.38, 0.38, 0.38, 0.21, 0.21, 0.21, 0.21, 0.21, 0, 0.2, 0.2, 0.2, 0.2, 0.06,
  0.06, 0.41, 0.41, 0.41, 0.4, 0.4, 0.4, 0.4, 0.2, 0.2, 0.33, 0.33, 0.21, 0.21,
  0.21, 0.05, 0.41, 0.41, 0.44, 0.44, 0.43, 0.43, 0.43, 0.43, 0.43, 0.23, 0.23,
  0.23, 0.23, 0.2, 0.2, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.19, 0.19,
  0.2, 0.22, 0.28, 0.41, 0.55, 0.61, 0.61, 0.57, 0.33, 0.62, 0.62, 0.62, 0.62,
  0.62, 0.41, 0.41, 0.41, 0.41, 0.41, 0.38, 0.38, 0.42, 0.42, 0.4, 0.48, 0.53,
  0.53, 0.44, 0.48, 0.48, 0.62, 0.62, 0.62, 0.62, 0.36, 0.47, 0.5, 0.5, 0.5,
  0.5, 0.47, 0.45, 0.45, 0.36, 0.36, 0.36, 0.29, 0.3, 0.36, 0.36, 0.36, 0.33,
  0.33, 0.37, 0.42, 0.42, 0.42, 0.4, 0.4, 0.4, 0.12, 0.12, 0.03, 0.03, 0, 0, 0,
  0.24, 0.24, 0.24, 0.24, 0.24, 0.24, 0.09, 0.15, 0.15, 0.15, 0.11, 0.11, 0.11,
  0.35, 0.58, 0.58, 0.39, 0.48, 0.48, 0.52, 0.52, 0.52, 0.56, 0.44, 0.44, 0.44,
  0.5, 0.5, 0.58, 0.58, 0.58, 0.45, 0.59, 0.59, 0.59, 0.59, 0.37, 0.37, 0.38,
  0.38, 0.38, 0.38, 0.38, 0.38, 0.24, 0.24, 0.24, 0.24, 0.21, 0.21, 0.2, 0.37,
  0.47, 0.47, 0.48, 0.48, 0.39, 0.39, 0.27, 0.32, 0.36, 0.36, 0.23, 0.23, 0.34,
  0.34, 0.25, 0.26, 0.26, 0.34, 0.34, 0.34, 0.34, 0.14, 0.14, 0.15, 0.15, 0.17,
  0.17, 0.17, 0.2, 0.2, 0.2, 0.15, 0.15, 0.15, 0.54, 0.54, 0.54, 0.59, 0.59,
  0.63, 0.84, 0.84, 0.84, 0.84, 0.84, 0.56, 0.54, 0.54, 0.54, 0.43, 0.43, 0.46,
  0.46, 0.58, 0.58, 0.67, 0.67, 0.67, 0.67, 0.72, 0.72, 0.6, 0.52, 0.61, 0.61,
  0.64, 0.64, 0.48, 0.48, 0.48, 0.53, 0.33, 0.47, 0.47, 0.47, 0.37, 0.37, 0.44,
  0.44, 0.44, 0.44, 0.35, 0.39, 0.39, 0.42, 0.4, 0.4, 0.3, 0.27, 0.13, 0.13,
  0.3, 0.3, 0.3, 0.3, 0.5, 0.59, 0.59, 0.59, 0.47, 0.55, 0.55, 0.55, 0.55, 0.59,
  0.59, 0.59, 0.31, 0.42, 0.42, 0.45, 0.45, 0.45, 0.45, 0.46, 0.51, 0.62, 0.69,
  0.69, 0.69, 0.69, 0.38, 0.36, 0.36, 0.26, 0.26, 0.26, 0.24, 0.24, 0.24, 0.29,
  0.29, 0.27, 0.27, 0.31, 0.31, 0.23, 0.23, 0.23, 0.35, 0.35, 0.35, 0.35, 0.35,
  0.35, 0.24, 0.24, 0.24, 0.24, 0.32, 0.32, 0.24, 0.24, 0.21, 0.21, 0.21, 0.21,
  0.25, 0.36, 0.36, 0.36, 0.36, 0.41, 0.41, 0.37, 0.37, 0.37, 0.37, 0.29, 0.29,
  0.29, 0.3, 0.42, 0.42, 0.42, 0.42, 0.42, 0.32, 0.32, 0.25, 0.25, 0.25, 0.25,
  0.67, 0.72, 0.76, 0.76, 0.8, 0.79, 0.79, 0.61, 0.75, 0.75, 0.78, 0.8, 0.8,
  0.73, 0.77, 0.77, 0.77, 0.72, 0.72, 0.78, 0.78, 0.73, 0.73, 0.53, 0.73, 0.73,
  0.76, 0.76, 0.76, 0.61, 0.63, 0.63, 0.63, 0.56, 0.62, 0.62, 0.62, 0.59, 0.63,
  0.63, 0.59, 0.69, 0.69, 0.69, 0.64, 0.64, 0.49, 0.46, 0.46, 0.48, 0.49, 0.49,
  0.43, 0.39, 0.54, 0.54, 0.52, 0.52, 0.52, 0.51, 0.51, 0.51, 0.51, 0.51, 0.51,
  0.51, 0.23, 0.23, 0.23, 0.23, 0.23, 0.23, 0.23, 0.23, 0.09, 0.09, 0.09, 0.14,
  0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.11, 0.11, 0.23, 0.23, 0.23, 0.23, 0.23,
  0.23, 0.23, 0.23, 0.23, 0.23, 0.23, 0.13, 0.13, 0.07, 0.07, 0.07, 0.07, 0.07,
  0.07, 0.07, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.07, 0.07, 0.07, 0.1, 0.1,
  0.1, 0.1, 0.29, 0.29, 0.36, 0.44, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52, 0.52,
  0.31, 0.42, 0.48, 0.48, 0.47, 0.47, 0.47, 0.79, 0.79, 0.72, 0.81, 0.81, 0.81,
  0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.76, 0.76, 0.76, 0.79, 0.79, 0.79, 0.82, 0.82,
  0.82, 0.79, 0.79, 0.79, 0.81, 0.81, 0.81, 0.82, 0.82, 0.82, 0.8, 0.8, 0.8,
  0.8, 0.8, 0.8, 0.81, 0.81, 0.81, 0.81, 0.8, 0.8, 0.83, 0.83, 0.81, 0.81, 0.81,
  0.81, 0.81, 0.8, 0.87, 0.91, 0.91, 0.91, 0.92, 0.92, 0.91, 0.88, 0.95, 0.95,
  0.95, 0.97, 0.97, 0.84, 0.96, 0.96, 0.96, 0.96, 0.96, 0.94, 0.94, 0.93, 0.93,
  0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93,
  0.93, 0.93, 0.92, 0.92, 0.92, 0.9, 0.9, 0.9, 0.93, 0.93, 0.93, 0.94, 0.94,
  0.94, 0.93, 0.93, 0.87, 0.91, 0.9, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97,
  0.89, 0.89, 0.89, 0.83, 0.83, 0.83, 0.86, 0.86, 0.86, 0.86, 0.86, 0.86, 0.92,
  0.92, 0.91, 0.91, 0.91, 0.91, 0.89, 0.89, 0.91, 0.91, 0.91, 0.91, 0.95, 0.95,
  0.97, 0.97, 0.97, 0.96, 0.96, 0.96, 0.96, 0.84, 0.84, 0.84, 0.94, 0.94, 0.95,
  0.95, 0.95, 1, 1, 0.96, 0.96, 0.96, 0.89, 0.95, 0.95, 0.95, 0.95, 0.9, 0.91,
  0.91, 0.91, 0.91, 0.91, 0.94, 0.94, 0.94, 0.95, 0.95, 0.95, 0.95, 0.95, 0.92,
  0.92, 0.92, 0.92, 0.92, 0.92, 0.9, 0.92, 0.92, 0.94, 0.94, 0.94, 0.94, 0.94,
  0.94, 0.94, 0.94, 0.86, 0.9, 0.95, 0.95, 0.95, 0.91, 0.91, 0.91, 0.86, 0.9,
  0.9, 0.9, 0.93, 0.93, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.85, 0.94, 0.94,
  0.94, 0.94, 0.94, 0.94, 0.97, 0.97, 0.96, 0.98, 0.98, 0.98, 0.96, 0.96, 0.96,
  0.96, 0.92, 0.92, 0.92, 0.92, 0.9, 0.9, 0.86, 0.86, 0.86, 0.86, 0.82, 0.82,
  0.9, 0.9, 0.92, 0.92, 0.89, 0.89, 0.89, 0.89, 0.89, 0.88, 0.88, 0.88, 0.92,
  0.92, 0.92, 0.89, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.82,
  0.82, 0.82, 0.82, 0.82, 0.81, 0.81, 0.81, 0.82, 0.82, 0.82, 0.83, 0.83, 0.83,
  0.8, 0.8, 0.8, 0.78, 0.78, 0.78, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81, 0.8, 0.8,
  0.8, 0.84, 0.84, 0.84, 0.79, 0.79, 0.79, 0.81, 0.81, 0.81, 0.81, 0.81, 0.81,
  0.83, 0.83, 0.83, 0.82, 0.82, 0.82, 0.82, 0.81, 0.81, 0.83, 0.83, 0.83, 0.83,
  0.83, 0.83, 0.83, 0.83, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.77, 0.63,
  0.63, 0.63, 0.62, 0.62, 0.62, 0.62, 0.59, 0.59, 0.59, 0.6, 0.51, 0.55, 0.55,
  0.55, 0.53, 0.58, 0.58, 0.52, 0.58, 0.58, 0.58, 0.58, 0.53, 0.45, 0.5, 0.5,
  0.48, 0.48, 0.48, 0.48, 0.46, 0.41, 0.41, 0.44, 0.44, 0.44, 0.41, 0.41, 0.41,
  0.4, 0.4, 0.4, 0.37, 0.37, 0.3, 0.34, 0.34, 0.25, 0.29, 0.29, 0.24, 0.24,
  0.22, 0.22, 0.15, 0.15, 0.1, 0.1, 0.1, 0, 0.06, 0.06, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

export const fakeTrackLevels3 = [
  0.13, 0.13, 0.13, 0, 0.55, 0.55, 0.54, 0.54, 0.54, 0.79, 0.73, 0.75, 0.81,
  0.81, 0.82, 0.74, 0.74, 0.74, 0.74, 0.83, 0.77, 0.79, 0.79, 0.87, 0.77, 0.81,
  0.77, 0.79, 0.75, 0.75, 0.75, 0.8, 0.89, 0.89, 0.85, 0.83, 0.89, 0.89, 0.84,
  0.84, 0.86, 0.91, 0.91, 0.91, 0.85, 0.88, 0.88, 0.88, 0.87, 0.87, 0.93, 0.93,
  0.93, 0.88, 0.79, 0.85, 0.85, 0.83, 0.8, 0.8, 0.74, 0.84, 0.81, 0.87, 0.87,
  0.89, 0.91, 0.91, 0.85, 0.87, 0.87, 0.83, 0.83, 0.83, 0.87, 0.89, 0.89, 0.89,
  0.89, 0.86, 0.86, 0.86, 0.85, 0.89, 0.89, 0.89, 0.85, 0.85, 0.86, 0.86, 0.86,
  0.93, 0.93, 0.93, 0.72, 0.83, 0.82, 0.83, 0.83, 0.92, 0.97, 0.97, 0.85, 0.89,
  0.89, 0.92, 0.92, 0.89, 0.99, 1, 0.89, 0.89, 0.89, 0.92, 0.93, 0.93, 0.9, 0.9,
  0.9, 0.89, 0.89, 0.9, 0.97, 1, 1, 0.91, 0.91, 0.86, 0.78, 0.83, 0.79, 0.79,
  0.82, 0.92, 0.9, 0.86, 0.93, 0.93, 0.93, 0.89, 0.91, 0.91, 0.92, 0.92, 0.89,
  0.95, 0.95, 0.95, 0.91, 0.86, 0.89, 0.89, 0.94, 0.94, 0.94, 0.92, 0.92, 0.91,
  0.91, 0.95, 0.95, 0.95, 0.89, 0.88, 0.9, 0.96, 0.96, 0.95, 0.95, 0.95, 0.95,
  0.89, 0.93, 0.93, 0.93, 0.93, 0.92, 0.92, 0.9, 0.94, 0.9, 0.89, 0.92, 0.97,
  0.97, 0.97, 0.87, 0.91, 0.9, 0.9, 0.89, 0.97, 0.97, 0.97, 0.87, 0.87, 0.87,
  0.87, 0.83, 0.94, 0.94, 0.94, 0.95, 0.95, 0.95, 0.94, 0.92, 0.9, 0.96, 0.96,
  0.96, 0.96, 0.96, 0.96, 0.94, 0.98, 0.98, 0.98, 0.98, 0.9, 0.9, 0.85, 0.9,
  0.9, 0.84, 0.84, 0.88, 0.9, 0.9, 0.87, 0.87, 0.94, 0.94, 0.94, 0.87, 0.94,
  0.93, 0.87, 0.94, 0.89, 0.9, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.89, 0.89,
  0.93, 0.92, 0.93, 0.93, 0.93, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.93, 0.93,
  0.9, 0.94, 0.94, 0.97, 0.97, 0.96, 0.96, 0.92, 0.96, 0.96, 0.91, 0.95, 0.96,
  0.95, 0.97, 0.97, 0.97, 0.95, 0.95, 0.87, 0.9, 0.91, 0.95, 0.95, 0.95, 0.9,
  0.9, 0.85, 0.9, 0.87, 0.86, 0.91, 0.91, 0.91, 0.91, 0.88, 0.88, 0.9, 0.9,
  0.87, 0.86, 0.9, 0.9, 0.9, 0.9, 0.94, 0.94, 0.94, 0.94, 0.83, 0.86, 0.78,
  0.89, 0.94, 0.94, 0.94, 0.94, 0.8, 0.82, 0.89, 0.89, 0.89, 0.89, 0.88, 0.88,
  0.88, 0.8, 0.8, 0.8, 0.8, 0.87, 0.87, 0.86, 0.9, 0.93, 0.93, 0.91, 0.9, 0.92,
  0.92, 0.91, 0.91, 0.88, 0.92, 0.85, 0.85, 0.9, 0.9, 0.93, 0.9, 0.89, 0.89,
  0.93, 0.93, 0.93, 0.93, 0.86, 0.88, 0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.8,
  0.8, 0.89, 0.96, 0.96, 0.94, 0.94, 0.91, 0.91, 0.91, 0.94, 0.94, 0.94, 0.96,
  0.96, 0.92, 0.92, 0.96, 0.96, 0.94, 0.95, 0.95, 0.91, 0.95, 0.95, 0.95, 0.91,
  1, 1, 1, 0.95, 0.95, 0.95, 0.95, 0.83, 0.83, 0.85, 0.86, 0.88, 0.92, 0.92,
  0.87, 0.87, 0.89, 0.94, 0.9, 0.9, 0.92, 0.92, 0.85, 0.91, 0.91, 0.97, 0.97,
  0.97, 0.84, 0.9, 0.95, 0.92, 0.87, 0.9, 0.9, 0.9, 0.9, 0.87, 0.87, 0.87, 0.87,
  0.87, 0.96, 0.93, 0.93, 0.93, 0.9, 0.9, 0.9, 0.88, 0.95, 0.95, 0.94, 0.94,
  0.94, 0.91, 0.91, 0.94, 0.94, 0.91, 0.91, 0.94, 0.94, 0.98, 0.98, 0.98, 0.94,
  0.94, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.94, 0.89, 0.89, 0.92, 0.97, 0.97,
  0.97, 0.97, 0.94, 0.94, 0.99, 0.99, 0.93, 0.93, 0.93, 0.93, 0.97, 0.97, 0.97,
  0.91, 0.92, 0.98, 0.98, 0.98, 0.98, 0.96, 0.96, 0.97, 0.97, 0.96, 0.96, 0.88,
  0.87, 0.87, 0.87, 0.88, 0.9, 0.9, 0.93, 0.93, 0.95, 0.97, 0.97, 0.97, 0.98,
  0.93, 0.98, 0.98, 0.95, 0.94, 0.94, 0.93, 0.93, 0.98, 0.98, 0.99, 0.99, 0.99,
  0.99, 0.99, 0.89, 0.89, 0.88, 0.79, 0.79, 0.89, 0.91, 0.91, 0.98, 0.98, 0.98,
  0.98, 0.92, 0.97, 0.97, 0.97, 0.97, 0.92, 0.92, 0.96, 0.93, 0.98, 0.99, 0.99,
  0.97, 0.97, 0.93, 0.99, 0.94, 0.94, 0.94, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98,
  0.98, 0.98, 0.92, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.95, 0.93, 0.93, 0.97,
  0.93, 0.98, 0.98, 1, 0.98, 0.98, 0.98, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
  0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95,
  0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.93, 0.93, 0.94, 0.94, 0.94, 0.94, 0.94,
  0.94, 0.92, 0.94, 0.94, 0.94, 0.94, 0.94, 0.92, 0.92, 0.94, 0.96, 0.91, 0.91,
  0.95, 0.95, 0.95, 0.9, 0.9, 0.94, 0.93, 0.93, 0.89, 0.93, 0.93, 0.9, 0.9,
  0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96,
  0.97, 0.97, 0.97, 0.97, 0.97, 0.95, 0.95, 0.91, 0.93, 0.93, 0.93, 0.93, 0.93,
  0.95, 0.96, 0.96, 0.96, 0.93, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94, 0.94,
  0.94, 0.94, 0.94, 0.93, 0.93, 0.95, 0.95, 0.95, 0.94, 0.93, 0.93, 0.93, 0.96,
  0.95, 0.93, 0.95, 0.96, 0.96, 0.96, 0.96, 0.96, 0.93, 0.97, 0.97, 0.94, 0.94,
  0.94, 0.94, 0.94, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.96, 0.95,
  0.95, 0.95, 0.95, 0.96, 0.96, 0.96, 0.96, 0.96, 0.99, 0.99, 0.99, 0.99, 0.99,
  0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.98, 0.98, 1, 1, 1, 1, 1, 1, 0.99,
  0.99, 0.96, 0.99, 0.99, 0.95, 0.95, 0.95, 0.95, 0.95, 0.9, 0.96, 0.99, 1, 1,
  1, 0.94, 0.97, 0.97, 1, 1, 1, 1, 0.94, 0.94, 0.94, 0.94, 1, 1, 1, 1, 1, 1,
  0.89, 0.92, 0.9, 0.9, 0.9, 0.92, 0.95, 0.92, 0.9, 0.9, 0.94, 0.97, 0.97, 0.93,
  1, 0.98, 0.96, 0.96, 0.96, 0.98, 1, 1, 1, 1, 1, 0.99, 0.99, 0.95, 0.99, 0.99,
  0.99, 1, 1, 0.98, 0.99, 1, 1, 1, 0.98, 0.98, 0.98, 0.96, 0.96, 0.96, 1, 1,
  0.96, 0.96, 0.96, 1, 0.96, 0.96, 1, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99, 0.99,
  0.99, 0.96, 0.98, 0.96, 0.96, 0.99, 1, 0.96, 0.96, 0.98, 0.98, 0.98, 0.98,
  0.94, 0.94, 0.94, 0.99, 0.93, 0.98, 0.98, 1, 1, 1, 1, 0.96, 0.96, 1, 1, 1, 1,
  1, 0.97, 0.98, 0.98, 0.98, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 0.97, 1,
  0.97, 0.97, 0.97, 0.95, 0.95, 0.95, 0.92, 0.96, 0.96, 0.98, 0.98, 0.98, 0.98,
  0.99, 0.99, 0.99, 1, 1, 0.99, 0.99, 0.99, 0.98, 0.98, 0.98, 0.99, 0.98, 0.98,
  0.99, 0.99, 0.99, 0.97, 0.97, 0.97, 0.97, 0.96, 0.97, 0.97, 0.98, 0.95, 0.95,
  0.95, 0.91, 0.93, 0.93, 0.94, 0.94, 0.92, 0.9, 0.94, 0.91, 0.91, 0.91, 0.91,
  0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91, 0.91,
  0.42, 0.42, 0.38, 0.38, 0.38, 0.38, 0.38, 0.28, 0.28, 0.28, 0.28, 0.14, 0.14,
  0.16, 0.16, 0.16, 0.07, 0.07, 0.25, 0.16, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.58,
  0.58, 0.58, 0.54, 0.51, 0.58, 0.58, 0.58, 0.58, 0.58, 0.58, 0.58, 0.58, 0.58,
  0.58, 0.58, 0.58, 0.58, 0.58, 0.58,
];
