import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { usePlacesWidget } from "react-google-autocomplete";
import getCountryISO3 from "country-iso-2-to-3";
import styled from "styled-components";

import { appStore } from "../../../../stores/appStore";
import { generateTestId } from "../../../../util_code/testUtil";
import { BooleanInput } from "../../../../components/v2/Inputs/BooleanInput";
import { TextField } from "../../../../components/v2/Inputs/TextField";
import { Label } from "../../../../components/v2/Text/Label";

const findType = (types, typeToFind) => {
  return types.find(type => type === typeToFind);
};

// TODO when location blank and show location disable next button
export const SingleWizardLocationStep2 = observer(() => {
  const { currentPoster } = appStore;

  const { setCustomLocation, setCustomLatLng, hideLocation } = currentPoster;

  const [keyword, setKeyword] = useState("");

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBzcdibJ4cUInI3Ctsz9z_yLApmGUIdh1I",
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat().toFixed(6);
      const lng = place.geometry.location.lng().toFixed(6);
      const address = place.formatted_address;
      let city = null;
      let province = null;
      let country = null;
      place.address_components.forEach((addressComponent) => {
        // city
        const locality = findType(addressComponent.types, "locality");
        const administrativeAreaLevel2 = findType(addressComponent.types, "administrative_area_level_2");
        // province
        const administrativeAreaLevel1 = findType(addressComponent.types, "administrative_area_level_1");
        // country
        const countryAddressComponent = findType(addressComponent.types, "country");

        if (locality || (administrativeAreaLevel2 && !city)) {
          if (addressComponent.long_name === "New York County") city = "New York";
          else city = addressComponent.long_name;
        }
        if (administrativeAreaLevel1) province = addressComponent.short_name;
        if (countryAddressComponent) country = getCountryISO3(addressComponent.short_name);
      });
      if (city && province && country) setCustomLocation(`${city}, ${province}, ${country}`);
      else setCustomLocation(address);
      setCustomLatLng({ lat, lng });
      setKeyword(address);
    },
    options: {
      types: ["address"]
    },
    debounce: 300,
  });

  const handleChange = useCallback((e) => {
    setKeyword(e.target.value);
  }, []);

  const onUpdateHideLocation = () => {
    currentPoster.setterHideLocation = !hideLocation;
  };

  return (
    <div>
      <Label>Location</Label>
      <LocationSearchWrapper>
        <LocationSearchInput
          value={keyword}
          onChange={handleChange}
          className="location-search"
          inputRef={ref}
          type="search"
          placeholder="Search Location"
          data-explorator_test_id={generateTestId("single-track", "step-2-location-input")}
        />
      </LocationSearchWrapper>
      <Label>Show Location</Label>
      <BooleanInput optionB={{ label: "Show", value: false, testId: generateTestId("single-track-wizard", "step-2-show-location") }} optionA={{ label: "Hide", value: true, testId: generateTestId("single-track-wizard", "step-2-hide-location") }} onChange={onUpdateHideLocation} value={hideLocation} testId={generateTestId("single-track", "step-2-hide-location-checkbox")} />
    </div>
  );
});

const LocationSearchWrapper = styled.div`
    margin-bottom: 2rem;
`;

const LocationSearchInput = styled(TextField)`
`;
