import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import SuperQuery from "@themgoncalves/super-query";

import { WavroomButton } from "../ReusableComponents/WavroomButton";
import PreviewIconImage from "../../images/preview-icon.png";
import ShowPreviewIcon from "../../images/expandArrow.svg";

import { appStore } from "../../stores/appStore";
import { generateTestId } from "../../util_code/testUtil";

export const PreviewButtonSize = "3.5rem";

export const PreviewPoster = observer(({ testId }) => {
  const { setShowPreviewModal } = appStore;

  return (
    <PreviewButton onClick={() => setShowPreviewModal(true)} data-explorator_test_id={testId || generateTestId("preview-poster-button", "container")}>
      <PreviewIcon src={ShowPreviewIcon} />
    </PreviewButton>
  );
});

const PreviewButton = styled(WavroomButton)`
  height: ${PreviewButtonSize};
  width: ${PreviewButtonSize};
  /* border: #c1c1c1 solid thin; */
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2); */
`;

const PreviewIcon = styled.img`
  height: 70%;
  width: auto;
  display: block;
  margin: auto;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
`;

export const PreviewButtonAbsoluteContainer = styled.div`
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  margin: 0;
  width: fit-content;
  ${SuperQuery().maxWidth.sm.css`
    bottom: 3rem;
    right: 2rem;
  `}
`;
